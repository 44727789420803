<template>
	<el-dialog title="系统通知" :visible="visible" :close-on-click-modal="false" append-to-body width="1000px"
		top="8vh" center @close="closeDialog">
        <div style="height: 38vh; overflow-y: scroll;" class="scrollElement" >
			<h3>{{ noticeInfo.title }}</h3>
			<h5>{{ noticeInfo.updateTime }}</h5>
			<div class="noticeBox">
        <div class="gundong">
          <quill-editor @focus="focus($event)" v-model="noticeInfo.detail" :options="editorOption"
            class="quillClass wid90" style="margin: 0 auto" />
        </div>
				<!-- <div class="noticeDetail scrollElement">
				<quill-editor @focus="focus($event)" @change="onChange($event)" v-model="noticeInfo.detail"
					:options="editorOption" class="quillClass" />
				</div> -->
			</div>
			<el-checkbox v-model="checked" @change="changeCheck">今日不再提示</el-checkbox>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button size="small" type="primary" @click="closeDialog">我知道了</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import { formatDate } from "@/util";
	export default {
		components: {
		},
		props: ['noticeInfo'],
		data() {
			return {
				adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
				loading: true,
				visible: false,
				checked: false,
				// 富文本编辑器
				editorOption: {
					modules: {
						toolbar: null
					}
				}
			}
		},
		created(){
			this.visible = localStorage.getItem('isShowNotice') != 'true' ? true : false
		},

		methods: {
			//获取焦点事件
			focus(event) {
				event.enable(false) //设置富文本编辑器不可编辑
			},
			onChange(event) {
				event.quill.enable(false) //设置富文本编辑器不可编辑
			},
			changeCheck(){
				const isShowNoticeInfo = JSON.parse(localStorage.getItem('isShowNotice')) ?? []
				const time = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime()
				// const noticeInfo = {
				// 	checked: this.checked,
				// 	time ,
				// 	userName: this.adminInfo.userName
				// }
				if (isShowNoticeInfo && isShowNoticeInfo.length > 0 && isShowNoticeInfo.find(item => item.userName == this.adminInfo.userName)) {
					isShowNoticeInfo.find(item => item.userName == this.adminInfo.userName).checked = this.checked
					isShowNoticeInfo.find(item => item.userName == this.adminInfo.userName).time = time
				} else {
					isShowNoticeInfo.push({
						checked: this.checked,
						time ,
						userName: this.adminInfo.userName
					})
				}
				localStorage.setItem('isShowNotice', JSON.stringify(isShowNoticeInfo))
			},
			closeDialog() {
				this.visible = false
                this.$emit('closeDialog')
			},
		},
	}
</script>
<style lang='scss' scoped>
.noticeBox{
	margin-bottom: 15px;
}
</style>
