<template>
  <el-dialog v-if="dialogVisible" :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible" width="950px"
    top="20vh" append-to-body @close="dialogClose">
    <div style="height: 30vh; overflow-y: scroll" class="scrollElement">
      <el-form :rules="rules" ref="ruleForm" label-position="right" label-width="120px" :model="fign">
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="所属区域:" prop="areaCodeArr">
              <el-cascader :props="prps" :options="$mapCode" v-model="fign.areaCodeArr" class="einp" collapse-tags
                clearable @change="handleActiveItemChange">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="小区名称:" prop="name">
              <el-input v-model="fign.name" placeholder="例如:幸福花园。请勿包含省市区内容" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="物业单位:" prop="propertyUnitName">
              <el-input disabled :placeholder="fign.propertyUnitName || '暂无内容'" v-model="fign.propertyUnitName"
                class="einp">
                <el-button slot="append" type="primary" @click="selectUnit()">选择单位</el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <ChangeUnit v-if="unitVisible" ref="changeUnit" :unitInfo="unitInfo" @getUnitInfo="getUnitInfo" />
          <el-col :span="12">
            <el-form-item label="电梯安全员:" prop="userIdList">
              <el-select class="einp" v-model="fign.userIdList" clearable :disabled="propertyListShow" multiple
                placeholder="请输入" @change="changePower">
                <el-option v-for="item in propertyList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="楼栋总数:" prop="towerNum">
              <el-input v-model="fign.towerNum" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详细地址:" prop="address">
              <el-input v-model="fign.address" placeholder="请输入" class="einp">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="状态:">
              <el-select class="einp" v-model="fign.isUse" placeholder="请输入" @change="changeLogin">
                <el-option :key="0" label="启用" :value="0" />
                <el-option :key="1" label="不启用" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!$store.state.isWBJB">
            <el-form-item label="广告合同有效期:">
              <el-date-picker class="einp" v-model="fign.allTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" @input="agreeChange" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="!$store.state.isWBJB">
            <el-form-item label="信息合同:">
              <el-select class="einp" v-model="fign.eletricFinanceId" clearable placeholder="请输入">
                <el-option v-for="item in contributorData" :key="item.value" :label="item.label"
                  :value="Number(item.value)">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!$store.state.isWBJB">
            <el-form-item label="关联物业品牌">
              <el-select v-model="fign.brand" filterable clearable placeholder="请选择" class="einp">
                <el-option v-for="item in brandList" :key="item.id" :label="item.brand" :value="item.uniqueCode">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="广告投放合同:">
              <FileUpload :md5s.sync="fign.imgUrl" :multiple="false" :limit="1" :url="url" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
      <el-button size="small" @click="dialogClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  var validateName = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]{3,30}$/
    // true：必填  false：非必填
    inpYz(
      rule,
      value,
      callback,
      true,
      operation,
      "可输入中文，字母，数值，长度为3-30"
    );
  };
  var validateaddress = (rule, value, callback) => {
    let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,25}$/;
    inpYz(
      rule,
      value,
      callback,
      false,
      operation,
      "可输入长度为2-25的中文，字母，数值"
    );
  };
  var validatephone = (rule, value, callback) => {
    let operation = /^([1-9][0-9]*|0)$/;
    inpYz(rule, value, callback, false, operation, "只能输入整数");
  };
  import FileUpload from '@/components/FileUploadPWP'
  import ChangeUnit from './changeUnit'
  export default {
    components: {
      FileUpload,
      ChangeUnit
    },
    props: ['tit', 'propForm', 'fignInit'],
    data() {
      return {
        url: '/api/file/web/uploadByOSS', // 上传地址
        dialogVisible: true,
        unitVisible: false,
        fign: {},
        propertyList: [],
        propertyListShow: true,
        contributorData: [],
        brandList: [],
        unitInfo: {}, // 物业信息
        prps: {
          label: "name",
          value: "areaCode",
          checkStrictly: true,
          children: 'children',
          disabled: 'disabled',
          // disabled: this.cascadeDisabled,
        },
        rules: {
          name: [{
            required: true,
            validator: validateName,
            trigger: "blur",
          }, ],
          propertyUnitName: [{
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('内容不能为空'));
              } else {
                callback()
              }
            },
            trigger: "blur",
          }, ],
          address: [{
            validator: validateaddress,
            trigger: "blur",
          }, ],
          towerNum: [{
            validator: validatephone,
            trigger: "blur",
          }, ],
          areaCodeArr: [{
            required: true,
            message: "请选择所属区域",
            trigger: "change",
          }, ],
          userIdList: [{
            required: true,
            message: "请选择电梯安全员",
            trigger: "blur",
          }, ],
        },
      }
    },
    created() {
      // this.onRefer()
      if (this.tit == "修改") {
        this.fign = this.propForm
      }
      this.getBrand()
      this.handleActiveItemChange(null)
      this.getNotice('CONTRIBUTOR_TYPE')
      this.getPropertyData(this.propForm.propertyUnitId)
      // const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      // const Arr = adminInfo.orgId.split(',')
      // Arr.forEach((item) => {
      //   this.oderArr.push(item.split('-')[1])
      // })
      // this.getNotice('ORG_BRAND')
    },
    methods: {
      getBrand() {
        const data = {
          current: 1,
          size: 9999,
        }
        this.$http.post(`/api/system/web/brand/getBrandPage`, data)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.brandList = data.records
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 获取字典
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.contributorData = data.CONTRIBUTOR_TYPE
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 改变启用
      changeLogin(val) {
        this.$set(this.fign, "isUse", val);
      },
      // 改变启用
      changePower(val) {
        this.$set(this.fign, "userIdList", val);
      },

      agreeChange(val) {
        console.log(val, 'val');
        this.$forceUpdate()
      },
      // 物业单位列表
      selectUnit() {
        this.unitVisible = true
        this.unitInfo.orgName = this.fign.propertyUnitName;
        this.$nextTick(() => {
          this.$refs.changeUnit.init()
        })
      },
      // 物业单位列表返回
      getUnitInfo(data) {
        this.fign.propertyUnitId = data.orgId;
        this.$set(this.fign, "propertyUnitName", data.orgName);
        if (this.fignInit && this.fignInit.propertyUnitName != data.orgName) {
          this.fign.userIdList = []
        }
        this.getPropertyData(data.orgId)
        this.unitVisible = false
      },

      getPropertyData(orgId) {
        const dataObj = {
          current: 1,
          size: 9999,
          orgId: orgId,
          type: "*TENEMENT_UNIT*"
        }
        this.$http.post("/api/system/web/appUser/list", dataObj).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              }
            } = res;
            this.propertyList = data.records
            this.propertyListShow = false
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      handleActiveItemChange() {
        // 在这里动态禁用级别1和级别2
        console.log(this.$mapCode, 'option11')
        this.$mapCode.forEach((option, index) => {
          //   // 禁用第一级别的选项
          if (option.children && option.children.length > 0) {
            option.disabled = true;
          } else {
            option.disabled = false;
          }
          //   // 禁用第二级别的选项
          if (option.children) {
            option.children.forEach((childOption, childIndex) => {
              if (childOption.children && childOption.children.length > 0) {
                childOption.disabled = true;
              } else {
                childOption.disabled = false;
              }
              // if (childIndex === 0) {
              // childOption.disabled = true;
              // }
            });
          }
        });
      },
      dialogClose() {
        this.dialogVisible = false
        this.$emit('close')
      },
      dioSub() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(this.fign));
            // if (this.fign.allTime) {
            data.contractStartTime = this.fign.allTime ? this.fign.allTime[0].getTime() : ''
            data.contractEndTime = this.fign.allTime ? this.fign.allTime[1].getTime() : ''
            // }
            data.eletricFinanceId = data.eletricFinanceId ? data.eletricFinanceId : ''
            data.towerNum = this.fign.towerNum ? this.fign.towerNum : 0
            let url = "/api/ele/web/plot/submit";
            if (data.areaCodeArr) {
              if (data.areaCodeArr.length > 0) {
                data.areaCodes = JSON.stringify(data.areaCodeArr);
                data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1];
              }
            }
            console.log(this.tit,'666')
            if (this.tit == '修改' && data && (data.name != this.propForm.name || data.areaCodes != this.propForm
                .areaCodes)) {
              this.$confirm('您正在修改小区所属区域或小区名称，修改成功后，系统将自动匹配修改该小区的电梯名称。是否继续？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确认',
                type: 'warning'
              }).then((res) => {
                this.$http.post(url, data).then((res) => {
                  if (res.data.success) {
                    this.$emit('update')
                    this.dialogVisible = false;
                    this.$message.success(res.data.msg);
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              })
            } else {
              this.$http.post(url, data).then((res) => {
                if (res.data.success) {
                  const data = {
                    xqglId: res.data.data.id
                  }
                  this.$emit('update', data)
                  this.dialogVisible = false;
                  this.$message.success(res.data.msg);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            }
          } else {
            this.$message.warning("请验证填写内容!");
            return false;
          }
        });
      },
    }
  }
</script>

<style scoped>
  .wid90 {
    width: 90%;
  }
</style>
