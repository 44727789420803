<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-press-escape="false" :close-on-click-modal="false"
    append-to-body width="950px" top="15vh" @close="closeDialog">
    <el-form ref="elevatorForm" :model="elevatorForm" :rules="elevatorFormRules" label-width="150px"
      label-position="right">
      <!-- :rules="elevatorFormRules" -->
      <el-tabs v-model="activeName">
        <el-tab-pane label="主要资料" name="first">
          <div class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;">
            <el-row>
              <el-col :span="12" v-if="roleId != 99">
                <el-form-item label="所属小区:" label-width="140px" prop="plotId">
                  <el-select v-model=" plotLoading ? ' ' : elevatorForm.plotId" v-loading="plotLoading"
                    :disabled="type === 'check'|| plotLoading" class="wid86" filterable clearable placeholder="请选择"
                    @change="changePlot">
                    <el-option v-for="item in plotArr" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('xqglAdd'))&&type != 'check' "
                    @click="changeAdd(7)">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="楼栋:" label-width="140px" prop="floorDong">
                  <el-input class="wid86" v-model="elevatorForm.floorDong" :disabled="type === 'check'"
                    placeholder="例如:1栋、A座、B区、文华楼、1期1栋... " @blur="addFloor" />
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <template slot="content" style="width: 227px;">
                      1栋、A座、XX楼、A栋、XX号楼、XX幢、XX区、<br />南楼、北楼、XX期... 没有时，请默认填写1栋
                    </template>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单元:" label-width="140px" prop="eleUnit">
                  <el-input class="wid86" v-model="elevatorForm.eleUnit" :disabled="type === 'check'"
                    placeholder="例如:1单元、A单元、A座..." @blur="changeEleunit()" />
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <template slot="content" style="width: 227px;">
                      1单元、A单元、XX号楼、XX座。没有单元时，填写1单元
                    </template>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯号:" label-width="140px" prop="eleCode">
                  <el-input class="wid86" v-model="elevatorForm.eleCode" :disabled="type === 'check'"
                    placeholder="例如:1#、1号、1#梯、1-左、1-右、货梯..." @blur="changeCode()" />
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <template slot="content" style="width: 227px;">
                      1#、2#梯、1号、1-左、1-右、货梯、1#客梯
                    </template>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="电梯名称:" label-width="140px" prop="name">
                  <el-input class="nameInfo wid94" v-model="loadUping ? '' : elevatorForm.name" v-loading="loadUping"
                    :disabled="type === 'check'" :placeholder="!loadUping ? '电梯名称生成规则：小区所属区域+小区名称+楼栋+单元+电梯号':''"
                    disabled>
                    <template slot="append">
                      <el-button :loading="loading" @click="Update()"
                        :disabled="plotLoading ||　(plotArr.find(item => item.id == elevatorForm.plotId) ? false :true)"
                        v-if="type === 'edit'&&this.plotArr">更新电梯名称</el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯注册代码:" label-width="140px" prop="code">
                  <el-input class="wid86" v-model="elevatorForm.code" :disabled="type === 'check'" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <el-col :span="12">
                <el-form-item label="出厂编号:" label-width="140px" prop="factoryNumber">
                  <el-input class="wid86" v-model="elevatorForm.factoryNumber" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="救援识别码:" label-width="140px" prop="rescueCode" :rules="{
                 required: codeInfo !== '' ? false : true,
                 message:
                   '救援识别码不能为空',
                 trigger: 'blur',
               }">
                  <el-input class="wid86" v-model="elevatorForm.rescueCode" :disabled="type === 'check'||looks"
                    :placeholder="looks ?'自动生成': '请输入'">
                    <template slot="append">
                      <el-button v-if="type !== 'check'&& codeInfo !== ''"
                        @click="getAuto()">{{looks ? '取消生成':'自动生成'}}</el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="!$store.state.isWBJB">
                <el-form-item label="AI摄像头ID:" label-width="140px" prop="deviceCode">
                  <el-input class="wid86" maxlength="16" v-model="elevatorForm.deviceCode" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <!-- <el-col :span="12" v-if="roleId != 99">
                <el-form-item label="所属小区:" label-width="140px" prop="plotId">
                  <el-select v-model="elevatorForm.plotId" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择" @change="changePlot">
                    <el-option v-for="item in plotArr" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="救援识别码:" label-width="140px" prop="rescueCode" :rules="{
                  required: codeInfo !== '' ? false : true,
                  message:
                    '救援识别码不能为空',
                  trigger: 'blur',
                }">
                  <el-input class="wid90" v-model="elevatorForm.rescueCode" :disabled="type === 'check'||looks"
                    :placeholder="looks ?'自动生成': '请输入'">
                    <template slot="append">
                      <el-button v-if="type !== 'check'&& codeInfo !== ''"
                        @click="getAuto()">{{looks ? '取消生成':'自动生成'}}</el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="12">
                <el-form-item
                  label="电梯数据终端ID:"
                  label-width="140px"
                  prop="terminalId"
                  class="terminalType"
                >
                  <el-input
                    class="wid90"
                    maxlength="16"
                    v-model="elevatorForm.terminalId"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  >
                    <el-select
                      v-model="elevatorForm.terminalType"
                      slot="prepend"
                      placeholder="请选择"
                    >
                      <el-option
                        label="R3+"
                        :value="1"
                      ></el-option>
                      <el-option
                        label="C2+"
                        :value="2"
                      ></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col> -->
              <!-- </el-row>
            <el-row> -->
              <!-- <el-col :span="12">
                <el-form-item
                  label="所属区域:"
                  label-width="140px"
                  prop="areaCodeArr"
                >
                  <el-cascader
                    :props="props"
                    :options="$mapCode"
                    v-model="elevatorForm.areaCodeArr"
                    :disabled="type === 'check'"
                    class="einp"
                    collapse-tags
                    clearable
                  >
                  </el-cascader>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="12">
                <el-form-item
                  label="所属小区:"
                  label-width="140px"
                  prop="plotId"
                >
                  <el-select
                    v-model="elevatorForm.plotId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changePlot"
                  >
                    <el-option
                      v-for="item in plotArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="12" v-if="!$store.state.isWBJB">
                <el-form-item label="报警短信推送:" label-width="140px" prop="smsSos">
                  <el-select v-model="elevatorForm.smsSos" :disabled="roleId!='3'||type == 'check'" class="wid86"
                    filterable placeholder="请选择">
                    <el-option label="不推送报警短信" :value="0" />
                    <el-option label="推送报警短信" :value="1" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="楼栋:" label-width="140px" prop="floorDong">
                  <el-input class="wid90" v-model="elevatorForm.floorDong" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col> -->
              <!-- </el-row>
            <el-row> -->
              <!-- <el-col :span="12">
                <el-form-item label="单元:" label-width="140px" prop="eleUnit">
                  <el-input class="wid90" v-model="elevatorForm.eleUnit" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯号:" label-width="140px" prop="eleCode">
                  <el-input class="wid90" v-model="elevatorForm.eleCode" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row>

              <el-col :span="24">
                <el-form-item label="电梯安装位置:" label-width="140px" prop="eleAddress">
                  <!-- <el-input
                    class="wid90"
                    v-model="elevatorForm.eleAddress"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  /> -->
                  <el-input :placeholder="elevatorForm.eleAddress || '暂无内容'" v-model="elevatorForm.eleAddress"
                    class="wid94" disabled>
                    <el-button slot="append" type="primary"
                      @click="chooseAddress">{{type == 'check' ? '查看安装位置' : '选择安装位置'}}</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="roleId != 99" label="相关单位" name="second">
          <div class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;">
            <el-row>
              <el-col :span="12">
                <el-form-item label="维保单位:" prop="maintainUnitId">
                  <el-select v-model="elevatorForm.maintainUnitId" :disabled="type === 'check'" class="wid86" filterable
                    clearable placeholder="请选择" @change="changeUnit('maintain')">
                    <el-option v-for="item in wbUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('wbdwAdd'))&&type != 'check' "
                    @click="changeAdd(1)">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="维保人员:" prop="maintainUserIds">
                  <el-input :placeholder="elevatorForm.maintainUserNames || '暂无内容'"
                    v-model="elevatorForm.maintainUserNames" class="wid86" disabled>
                    <el-button :disabled="!elevatorForm.maintainUnitId || type === 'check'" slot="append" type="primary"
                      @click="selectElevator('MAINTAIN_UNIT', elevatorForm.maintainUnitId)">选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="物业单位:" prop="propertyUnitId">
                  <el-input v-model="elevatorForm.propertyUnitName" class="wid86" :disabled="true"
                    placeholder="请选择"></el-input>
                  <!-- <el-select v-model="elevatorForm.propertyUnitId" :disabled="type === 'check' || propertyDisabled"
                    class="wid86" filterable clearable placeholder="请选择" @change="changeUnit('property')">
                    <el-option v-for="item in wyUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select> -->
                  <!-- <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="adminInfo.userName == 'admin' || adminInfo.buttons.includes('wydwAdd') "
                    @click="changeAdd(2)">新增</a> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯安全员:" prop="propertyUnitUserIds">
                  <el-input :placeholder="elevatorForm.propertyUnitUserNames || '暂无内容'"
                    v-model="elevatorForm.propertyUnitUserNames" class="wid86" disabled>
                    <el-button :disabled="!elevatorForm.propertyUnitId || type === 'check'" slot="append" type="primary"
                      @click="selectElevator('TENEMENT_UNIT', elevatorForm.propertyUnitId)">选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!$store.state.isWBJB">
              <el-col :span="12">
                <el-form-item label="物业单位内部编号:" prop="propertyUnitInternalNumber">
                  <el-input class="wid86" v-model="elevatorForm.propertyUnitInternalNumber" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检验单位:">
                  <el-select v-model="elevatorForm.inspectionUnitId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择">
                    <el-option v-for="item in jyanUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('qtdwAdd'))&&type != 'check' "
                    @click="changeAdd(6,'CHECK_UNIT')">新增</a>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!$store.state.isWBJB">
              <el-col :span="12">
                <el-form-item label="应急救援单位:" prop="emergencyRescueUnitId">
                  <el-select v-model="elevatorForm.emergencyRescueUnitId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择" @change="changeUnit('emergency')">
                    <el-option v-for="item in jyUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('jydwAdd'))&&type != 'check' "
                    @click="changeAdd(3)">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="救援人员:" prop="emergencyRescueUserIds">
                  <el-input :placeholder="elevatorForm.emergencyRescueUserNames || '暂无内容'"
                    v-model="elevatorForm.emergencyRescueUserNames" class="wid86" disabled>
                    <el-button :disabled="!elevatorForm.emergencyRescueUnitId || type === 'check'" slot="append"
                      type="primary"
                      @click="selectElevator('RESCUE_UNIT', elevatorForm.emergencyRescueUnitId)">选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="登记机关:" prop="registrationAuthorityId">
                  <el-select v-model="elevatorForm.registrationAuthorityId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择">
                    <el-option v-for="item in djUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('djjgAdd'))&&type != 'check' "
                    @click="changeAdd(4)">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="登记证编号:" prop="registeredCode">
                  <el-input class="wid86" v-model="elevatorForm.registeredCode" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!$store.state.isWBJB">
              <el-col :span="12">
                <el-form-item label="安装单位:">
                  <el-select v-model="elevatorForm.installUnitId" :disabled="type === 'check'" class="wid86" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in azUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('qtdwAdd'))&&type != 'check' "
                    @click="changeAdd(6,'INSTALL_UNIT')">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产权单位:">
                  <el-select v-model="elevatorForm.propertyRightUnitId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择">
                    <el-option v-for="item in cqUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('qtdwAdd'))&&type != 'check' "
                    @click="changeAdd(6,'PROPERTY_UNIT')">新增</a>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!$store.state.isWBJB">
              <el-col :span="12">
                <el-form-item label="保险单位:" prop="insuranceUnitId">
                  <el-select v-model="elevatorForm.insuranceUnitId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择" @change="changeUnit('insurance')">
                    <el-option v-for="item in bxUnit" :key="item.id"
                      :label="item.name == '无保险单位' ? item.name : item.name+'（'+item.unitCode+'）'" :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('bxdwAdd'))&&type != 'check' "
                    @click="changeAdd(5)">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="保险人员:" prop="insuranceUnitUserIds">
                  <el-input :placeholder="elevatorForm.insuranceUnitUserNames || '暂无内容'"
                    v-model="elevatorForm.insuranceUnitUserNames" class="wid86" disabled>
                    <el-button
                      :disabled="!elevatorForm.insuranceUnitId || elevatorForm.insuranceUnitId === 100 || type === 'check'"
                      slot="append" type="primary"
                      @click="selectElevator('INSURANCE_UNIT', elevatorForm.insuranceUnitId)">选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!$store.state.isWBJB">
              <el-col :span="12">
                <el-form-item label="制造单位:">
                  <el-select v-model="elevatorForm.manufacturingUnitId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择">
                    <el-option v-for="item in zzUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('qtdwAdd'))&&type != 'check' "
                    @click="changeAdd(6,'MAKE_UNIT')">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="制造许可证编号:" prop="manufacturingCode">
                  <el-input class="wid86" v-model="elevatorForm.manufacturingCode" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="!$store.state.isWBJB">
              <el-col :span="12">
                <el-form-item label="厂商单位:">
                  <el-select v-model="elevatorForm.manufacturerUnitId" :disabled="type === 'check'" class="wid86"
                    filterable clearable placeholder="请选择">
                    <el-option v-for="item in csUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('qtdwAdd'))&&type != 'check' "
                    @click="changeAdd(6,'VENDOR_UNIT')">新增</a>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="街道单位:">
                  <el-select v-model="elevatorForm.streetUnitId" :disabled="type === 'check'" class="wid86" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in jdUnit" :key="item.id" :label="item.name+'（'+item.unitCode+'）'"
                      :value="item.id" />
                  </el-select>
                  <a style="margin-left: 10px;color: royalblue;cursor: pointer;"
                    v-if="(adminInfo.userName == 'admin' || adminInfo.buttons.includes('qtdwAdd'))&&type != 'check' "
                    @click="changeAdd(6,'STREET_UNIT')">新增</a>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="电梯参数" name="third">
          <div class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;">
            <el-row>
              <el-col v-if="roleId != 99 && !$store.state.isWBJB" :span="12">
                <el-form-item label="电梯品牌:" prop="brandId">
                  <el-select v-model="elevatorForm.brandId" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in eleBrand" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯型号:" prop="eleModel">
                  <el-input class="wid90" v-model="elevatorForm.eleModel" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯类型:">
                  <el-select v-model="elevatorForm.eleType" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in eleType" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="生产方式:">
                  <el-select v-model="elevatorForm.productionMode" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in genneration" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="!$store.state.isWBJB">
                <el-form-item label="电梯停靠站数:" prop="floorHeight">
                  <el-input class="wid90" v-model="elevatorForm.floorHeight" :disabled="type === 'check'"
                    placeholder="请输入" />
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <template slot="content" style="width: 227px;">
                      地上楼层+地下楼层 - 不停靠楼层数 = <br />停靠站数
                    </template>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="!$store.state.isWBJB">
                <el-form-item label="地下楼层数:" prop="underFloor">
                  <el-input class="wid90" v-model="elevatorForm.underFloor" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="站数:" prop="standNumber">
                  <el-input class="wid90" v-model="elevatorForm.standNumber" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="门数:" prop="doorNumber">
                  <el-input class="wid90" v-model="elevatorForm.doorNumber" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="使用状态:">
                  <el-select v-model="elevatorForm.eleState" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in useState" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99 && !$store.state.isWBJB" :span="12">
                <el-form-item label="使用场所分类:" prop="elePlaceType">
                  <el-select v-model="elevatorForm.elePlaceType"
                    :disabled="type === 'check' || (type !== 'add' && elevatorForm.elePlaceType == 'GOVERNMENT_ORGAN' && adminInfo.userName != 'admin')"
                    class="wid90" filterable clearable placeholder="请选择">
                    <el-option v-for="item in usePlace" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯品种:">
                  <el-select v-model="elevatorForm.varieties" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择" @change="changeVar">
                    <el-option v-for="item in eleVarieties" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯控制方式:">
                  <el-select v-model="elevatorForm.controlMode" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择">
                    <el-option v-for="item in eleControlMode" :key="item.value" :label="item.label"
                      :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯注册日期:">
                  <el-date-picker v-model="elevatorForm.registrationDate" :disabled="type === 'check'"
                    style="width: 90%;" type="date" placeholder="请输入" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯大修/改造日期:">
                  <el-date-picker v-model="elevatorForm.installTransformDate" :disabled="type === 'check'"
                    style="width: 90%;" type="date" placeholder="请输入" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>
              </el-col>
              <!-- <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="额定负载:(kg)" prop="atedLoad">
                  <el-input class="wid90" v-model="elevatorForm.atedLoad" :disabled="type === 'check'"
                    placeholder="请输入">
                  </el-input>
                </el-form-item>
              </el-col> -->
              <el-col v-if="roleId != 99 && !$store.state.isWBJB" :span="12">
                <el-form-item label="额定负载:" prop="atedLoad">
                  <el-input class="wid90" v-model="elevatorForm.atedLoad" :disabled="type === 'check'"
                    placeholder="请输入">
                    <template slot="append">kg</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="roleId != 99" :span="12">
                <el-form-item label="电梯门宽度:" prop="doorWidth">
                  <el-input class="wid90" v-model="elevatorForm.doorWidth" :disabled="type === 'check'"
                    placeholder="请输入">
                    <template slot="append">米</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="type !== 'add' && roleId != 99">
                <el-form-item label="电梯风险等级:" prop="riskLevel">
                  <el-select v-model="elevatorForm.riskLevel" disabled class="wid90" filterable clearable
                    placeholder="请选择">
                    <el-option v-for="item in riskLevelList" :key="item.value" :label="item.label"
                      :value="Number(item.value)" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="roleId != 99" label="维保参数" name="fourth">
          <div class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;">
            <el-row>
              <el-col :span="12">
                <el-form-item label="维保等级:">
                  <el-select v-model="elevatorForm.maintainLevel" :disabled="type === 'check'" class="wid90" filterable
                    clearable placeholder="请选择">
                    <el-option label="1级" value="1" />
                    <el-option label="2级" value="2" />
                    <el-option label="3级" value="3" />
                    <el-option label="4级" value="4" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="年检时间:">
                  <el-date-picker v-model="elevatorForm.inspectionDate" :disabled="type === 'check'" style="width: 90%;"
                    type="date" placeholder="请输入" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="服务平台二维码内容:" prop="servicePlatformQrCode">
                  <el-input class="wid90" v-model="elevatorForm.servicePlatformQrCode" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="维保签到二维码:">
                  <FileUpload v-if="type !== 'check'" :md5s.sync="elevatorForm.qrCodeSignImage" :multiple="false"
                    :limit="1" url='/api/file/web/uploadByOSS' />
                  <template v-else>
                    <el-image v-if="elevatorForm.qrCodeSignImage" style="width: 100px; height: 100px"
                      :src="elevatorForm.qrCodeSignImage" :fit="'contain'" />
                  </template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯使用标志:" prop="elevatorUseSign">
                  <FileUpload v-if="type !== 'check'" :md5s.sync="elevatorForm.elevatorUseSign" :multiple="false"
                    :limit="1" url='/api/file/web/uploadByOSS' />
                  <template v-else>
                    <el-image v-if="elevatorForm.elevatorUseSign" style="width: 100px; height: 100px"
                      :src="elevatorForm.elevatorUseSign" :fit="'contain'" />
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="物联网设备相关设置" name="fifth" v-if="!$store.state.isWBJB">

          <div v-if="roleId != 99" class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;">
            <el-row>
              <el-col :span="12">
                <el-form-item label="超速报警阈值:" prop="overSpeed">
                  <el-input class="wid90" v-model="elevatorForm.overSpeed" :disabled="type === 'check'"
                    placeholder="请输入">
                    <template slot="append">m/s</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="最大速度:" prop="maxSpeed">
                  <el-input class="wid90" v-model="elevatorForm.maxSpeed" :disabled="type === 'check'" placeholder="请输入"
                    @change="changemaxSpeed">
                    <template slot="append">m/s</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="加速度阈值:" prop="maxSpeedA">
                  <el-input class="wid90" v-model="elevatorForm.maxSpeedA" :disabled="type === 'check'"
                    placeholder="请输入">
                    <template slot="append">m/s²</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <el-col :span="12">
                <el-form-item label="SIM卡开卡时间:">
                  <el-date-picker v-model="elevatorForm.simStartTime" :disabled="type === 'check'" style="width: 90%;"
                    type="date" placeholder="请输入" @change='changeTime' />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="SIM卡到期时间:">
                  <el-date-picker v-model="elevatorForm.simEndTime" :disabled="type === 'check'" style="width: 90%;"
                    type="date" placeholder="请输入" @change='changeTime' />
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <el-col :span="12">
                <el-form-item label="固定IP:" prop="deviceIp">
                  <el-input class="wid90" v-model="elevatorForm.deviceIp" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="子网掩码:" prop="subnetMask">
                  <el-input class="wid90" v-model="elevatorForm.subnetMask" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <el-col :span="12">
                <el-form-item label="网关:" prop="deviceGateway">
                  <el-input class="wid90" v-model="elevatorForm.deviceGateway" :disabled="type === 'check'"
                    placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="DNS:" prop="deviceDns">
                  <el-input class="wid90" v-model="elevatorForm.deviceDns" :disabled="type === 'check'"
                    placeholder="请输入" />
                  <!-- <el-select
                    v-model="elevatorForm.deviceDns"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in DNSList"
                      :key="item.value"
                      :label="item.label"
                      :value="Number(item.value)"
                    />
                  </el-select> -->
                </el-form-item>
              </el-col>
              <!-- </el-row>

            <el-row v-if="adminInfo.userName == 'admin'"> -->
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="人体阀值:" prop="headValue">
                  <el-input class="wid90" v-model="elevatorForm.headValue" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.headValue" :min="0" :max="1" :precision="2"
                    :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="开关门阀值:" prop="openValue">
                  <el-input class="wid90" v-model="elevatorForm.openValue" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.openValue" :min="0" :max="1" :precision="2"
                    :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <!-- v-if="adminInfo.userName == 'admin'" 电动车阈值参数取消仅admin超管可见 -->
              <el-col :span="12">
                <el-form-item label="电动车阀值:" prop="bikeValue">
                  <el-input class="wid90" v-model="elevatorForm.bikeValue" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.bikeValue" :min="0.5" :max="1" :precision="2"
                    :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                  <el-tooltip class="item" effect="dark" placement="top-end">
                    <template slot="content" style="width: 227px;">
                      参数值越低，灵敏度越高，电动车<br />识别更频繁，误报相对较多。
                    </template>
                    <i class="el-icon-warning-outline" style="margin-left: 10px;"></i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="静止阈值:" prop="staticHeadProb">
                  <el-input class="wid90" v-model="elevatorForm.staticHeadProb" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.staticHeadProb" :min="0.01" :max="0.5"
                    :precision="2" :step="0.01" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row v-if="adminInfo.userName == 'admin'"> -->
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="遮挡结构阈值:" prop="ssimScore">
                  <el-input class="wid90" v-model="elevatorForm.ssimScore" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.ssimScore" :min="0" :max="1" :precision="2"
                    :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="遮挡颜色阈值:" prop="colorScore">
                  <el-input class="wid90" v-model="elevatorForm.colorScore" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.colorScore" :min="0" :max="1" :precision="2"
                    :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row> -->
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="遮挡光线阈值:" prop="lightChange">
                  <el-input class="wid90" v-model="elevatorForm.lightChange" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.lightChange" :min="0" :precision="2" :step="0.1"
                    :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="煤气罐阈值:" prop="gasTankValue">
                  <el-input class="wid90" v-model="elevatorForm.gasTankValue" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.gasTankValue" :min="0" :max="1" :precision="2"
                    :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="视角偏移阈值:" prop="skewValue">
                  <el-input class="wid90" v-model="elevatorForm.skewValue" :disabled="type === 'check'"
                    placeholder="请输入"></el-input>
                  <!-- <el-input-number class="wid90" v-model="elevatorForm.gasTankValue" :min="0" :max="1" :precision="2"
                      :step="0.1" :disabled="type === 'check'" label="请输入" /> -->
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-col :span="12">
                <el-form-item label="最大速度:" prop="maxSpeed">
                  <el-input class="wid90" v-model="elevatorForm.maxSpeed" :disabled="type === 'check'" placeholder="请输入"
                    @change="changemaxSpeed">
                    <template slot="append">m/s</template>
                  </el-input>
                </el-form-item>
              </el-col> -->
            <!-- </el-row>
            <el-row v-if="adminInfo.userName == 'admin'"> -->
            <el-row>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="人像模糊:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.portraitBlur">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.portraitBlur === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="人体画框:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.headBorder">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.headBorder === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row v-if="adminInfo.userName == 'admin'"> -->
              <el-col :span="12">
                <el-form-item label="电瓶车框:" v-if="adminInfo.userName == 'admin'">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.bikeBorder">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.bikeBorder === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开关门框:" v-if="adminInfo.userName == 'admin'">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.doorBorder">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.doorBorder === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>

            </el-row>


            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="主动报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.sosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.sosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="AI识别:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.aiIdentifySwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.aiIdentifySwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="语音报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.audioSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.audioSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="安全回路报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.safetyCircuitSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template
                    v-else>{{elevatorForm.deviceSosSwitch.safetyCircuitSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="有人挡梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.manBlockSos">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.manBlockSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="有物挡梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.fenderBlockSos">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.fenderBlockSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="关门异常:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.doorOpenSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.doorOpenSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="反复开关门:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.closeDoorRepeatedly">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.closeDoorRepeatedly === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="反复开关门:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.closeDoorRepeatedly">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template
                    v-else>{{elevatorForm.deviceSosSwitch.closeDoorRepeatedly === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="非门区停梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.overFloorSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.overFloorSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch"> -->
              <el-col :span="12">
                <el-form-item label="蹲底报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.jtLowestSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.jtLowestSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="冲顶报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.jtHightestSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.jtHightestSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch"> -->
              <el-col :span="12">
                <el-form-item label="困人报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.stuckSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.stuckSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯超速:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.overSpeedSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.overSpeedSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch"> -->
              <el-col :span="12">
                <el-form-item label="上平层报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.upperLevel">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.upperLevel === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="下平层报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.underbed">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.underbed === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="非平层停梯报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.nonLevelStop">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.nonLevelStop === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="摄像头遮挡:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.cameraOcclusion">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.cameraOcclusion === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- </el-row>
						<el-row v-if="elevatorForm.deviceSosSwitch"> -->
              <el-col :span="12">
                <el-form-item label="电动车报警推送:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikePushSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikePushSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电动车报警识别:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikeSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikeSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- </el-row>
						<el-row v-if="elevatorForm.deviceSosSwitch"> -->
              <el-col :span="12">
                <el-form-item label="开门行梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.runningLadderSosSwitch" disabled>
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template
                    v-else>{{elevatorForm.deviceSosSwitch.runningLadderSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="断电报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.outageSos">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.outageSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="断电报警视频上传:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.outageSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.outageSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="elevatorForm.deviceSosSwitch">
                <el-form-item label="阻梯功能:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.haveBanClosed" @change="changeBanClosed">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>
                    {{elevatorForm.deviceSosSwitch.haveBanClosed === 1 ? '开启' : '关闭' }}
                  </template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="elevatorForm.deviceSosSwitch && adminInfo.userName == 'admin'">
                <el-form-item label="运行日志:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.txtSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.txtSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="微信扫码呼救:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.wechatCodeSos">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.wechatCodeSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName != 'admin' && type == 'check'">
                <el-form-item label="微信扫码呼救:">
                  <template>{{elevatorForm.deviceSosSwitch.wechatCodeSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="煤气罐入梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.gasTankSos">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.gasTankSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="视角偏移:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.viewShiftSos">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.viewShiftSos === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="可视频通话:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.videoCallsSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.videoCallsSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="每月自学楼层:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.autoFloorLearnSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.autoFloorLearnSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="false">
                <el-form-item label="急停报警:">
                  <el-radio-group v-model="elevatorForm.deviceSosSwitch.jtSosSwitch">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="0">关闭</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div v-else class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll;">
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="困人报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.stuckSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.stuckSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电动车报警推送:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikePushSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikePushSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="电动车报警识别:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikeSosSwitch">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikeSosSwitch === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="elevatorForm.deviceSosSwitch">
                <el-form-item label="阻梯功能:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.haveBanClosed" @change="changeBanClosed">
                      <el-radio :label="1">开启</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.haveBanClosed === 1 ? '开启' : '关闭' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>

      </el-tabs>
    </el-form>
    <SelectPerson v-if="selectPersonVisible" ref="selectElevator" :unitName="unitName" :initIds="initIds"
      :unitType="unitType" :orgId="orgId" @transferElevator="selectOtherResult" :personType="personType" />
    <!-- 物业单位新增 -->
    <wyAdd v-if="wydwVisible" :tit="Addtit" @close="AddClose()" :propForm="fign" @update="Addupdate" />
    <!-- 维保单位新增 -->
    <wbAdd v-if="wbdwVisible" :tit="Addtit" @close="AddClose()" :propForm="fign" @update="Addupdate" />
    <!-- 救援单位新增 -->
    <jyAdd v-if="jydwVisible" :tit="Addtit" @close="AddClose()" :propForm="fign" @update="Addupdate" />
    <!-- 登记机关新增 -->
    <djjgAdd v-if="djjgVisible" :tit="Addtit" @close="AddClose()" :propForm="fign" @update="Addupdate" />
    <!-- 保险单位新增 -->
    <bxdwAdd v-if="bxdwVisible" :tit="Addtit" @close="AddClose()" :propForm="fign" @update="Addupdate" />
    <!-- 其他单位新增 -->
    <qtdwAdd :qtdwType="qtdwType" v-if="qtdwVisible" :tit="Addtit" @close="AddClose" :propForm="fign"
      @update="Addupdate" />
    <!-- 小区管理新增 -->
    <xqglAdd v-if="xqglVisible" :tit="Addtit" @close="AddClose()" :propForm="fign" @update="Addupdate" />


    <el-dialog title="安装位置" :visible.sync="addressVisible" :close-on-click-modal="false" append-to-body width="960px"
      top="20vh" @close="closeAddress">
      <SelectAddress v-if="selectAddressVisible" ref="SelectAddress" :lnglat="elevatorForm.lnglat"
        @clickAddress="clickAddress" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="type != 'check'" @click="transfeRaddress">确定</el-button>
        <el-button type="indo" @click="closeAddress">取消</el-button>
      </div>
    </el-dialog>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" v-if="type !== 'check'" :loading="confirmStatus" @click="confirmForm">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
  import FileUpload from '@/components/FileUpload'
  import SelectPerson from './selectPerson'
  import SelectAddress from './selectAddress'
  import wyAdd from '../../../dagl/wydw/components/Add'
  import wbAdd from '../../../dagl/wbdw/components/Add'
  import jyAdd from '../../../dagl/jydw/components/Add'
  import djjgAdd from '../../../dagl/djjg/components/Add'
  import bxdwAdd from '../../../dagl/bxdw/components/Add'
  import qtdwAdd from '../../../dagl/qtdw/components/Add'
  import xqglAdd from '../../../dagl/xqgl/components/Add'

  export default {
    components: {
      FileUpload,
      SelectPerson,
      SelectAddress,
      wyAdd,
      wbAdd,
      jyAdd,
      djjgAdd,
      bxdwAdd,
      qtdwAdd,
      xqglAdd
    },
    props: {
      initData: {
        type: Object,
        default: () => {},
      },
      upd: {
        type: Number,
        default: 0,
      },
    },
    data() {
      var validateZMSZ = (rule, value, callback) => {
        inputApp(
          rule,
          value,
          false,
          /^[a-zA-Z0-9]{2,20}$/,
          '只可输入长度为2-20位的大小写字母',
          callback
        )
      }
      var validateZMSZ420 = (rule, value, callback) => {
        inputApp(
          rule,
          value,
          false,
          /^[a-zA-Z0-9]{4,20}$/,
          '只可输入长度为4-20位的大小写字母',
          callback
        )
      }
      var validateSZ216 = (rule, value, callback) => {
        inputApp(
          rule,
          value,
          false,
          /^[0-9]{2,16}$/,
          '只可输入长度为2-16位的整数',
          callback
        )
      }
      var validateZLCSZ14 = (rule, value, callback) => {
        inputFloor(
          rule,
          value,
          true,
          /^[0-9]{1,4}$/,
          '只可输入长度为1-4位的整数',
          callback
        )
      }
      var validateSZ14 = (rule, value, callback) => {
        inputApp(
          rule,
          value,
          false,
          /^[0-9]{1,4}$/,
          '只可输入长度为1-4位的整数',
          callback
        )
      }
      var validateSZ15 = (rule, value, callback) => {
        inputlc(rule, value, callback)
      }
      var validateSZ13 = (rule, value, callback) => {
        inputsd(rule, value, callback)
      }
      var validateMaxSpeed = (rule, value, callback) => {
        inputMaxSpeed(rule, value, callback)
      }
      var validateDoorWidth = (rule, value, callback) => {
        inputApp(
          rule,
          value,
          false,
          /^\d*\.{0,1}\d{0,1}$/,
          '只可输入整数或保留小数一位',
          callback
        )
      }
      var validateDoorMaxWidth = (rule, value, callback) => {
        if (value > 10) {
          callback(new Error('电梯门宽度输入不能超过10米!'))
        } else {
          callback()
        }
      }

      var atedLoadVolid = (rule, value, callback) => {

        const reg = /^-?\d+$/
        if (value >= 100 && reg.test(value) && value <= 5000) {
          callback()
        } else {
          callback(new Error('请输入100到5000之间的整数'))
        }
      }

      return {
        //新增
        wydwVisible: false, //物业单位
        wbdwVisible: false, //维保单位
        jydwVisible: false, //救援单位
        djjgVisible: false, //登记机关
        bxdwVisible: false, //保险单位
        qtdwVisible: false, //其它单位
        xqglVisible: false,
        Addtit: '',
        fign: {},
        qtdwType: '',
        plotLoading: false,
        roleId: '',
        loading: true,
        visible: false,
        addressVisible: false, // 安装位置弹框
        type: '', // 电梯新增/修改
        title: '', // 弹窗标题
        activeName: 'first', // 默认第一屏
        elevatorForm: {
          maxSpeed: 2,
          smsSos: 0,
          headValue: 0.70,
          openValue: 0.70,
          bikeValue: 0.70,
          staticHeadProb: 0.50,
          portraitBlur: 0,
          bikeBorder: 0,
          doorBorder: 0,
          headBorder: 0,
          ssimScore: 0.80,
          colorScore: 0.50,
          lightChange: 30.0,
          gasTankValue: 0.70,
          skewValue: 1,
          varieties: ''
        }, // 电梯表单信息
        // 地区选择器
        props: {
          label: 'name',
          value: 'areaCode',
          checkStrictly: true,
        },
        propertyDisabled: false,
        plotArr: [], // 所属小区
        eleBrand: [], // 电梯品牌
        riskLevelList: [], // 电梯风险等级
        wbUnit: [], // 维保单位,
        wyUnit: [], // 物业单位,
        jyUnit: [], // 救援单位
        azUnit: [], // 安装单位
        bxUnit: [], // 保险单位
        zzUnit: [], // 制造单位
        cqUnit: [], // 产权单位
        djUnit: [], // 登记机关
        jyanUnit: [], // 检验单位
        csUnit: [], // 厂商单位
        jdUnit: [], // 街道单位

        eleType: [], // 电梯类型
        genneration: [], // 生产方式
        useState: [], // 使用状态
        usePlace: [], // 使用产所分类
        eleVarieties: [], // 电梯品种
        eleControlMode: [], // 电梯控制方式
        maintenReminderTime: [], // 维保提醒时间
        maintenLevel: [], // 维保等级
        speedOptions: [], // 速度选项

        insuranceUnitList: [],
        selectAddressVisible: false, // 选择位置弹框
        selectPersonVisible: false, // 选择人员弹框
        orgId: '', // 单位id
        unitName: '', // 单位name
        personType: ' ', // 选择类型
        // initArr: [], // 选择人员集合
        unitType: '', // 选择人员类型
        maintainArr: [],
        propertyArr: [],
        emergencyArr: [],
        insuranceArr: [],
        initIds: [], // 选择人员id集合
        addressInfo: null, //地址信息
        //表单
        elevatorFormRules: {
          headValue: [{
            required: true,
            message: '人体阀值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || value > 1 || !log.test(value)) {
                callback(new Error('请输入小于等于1大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          openValue: [{
            required: true,
            message: '开关门阀值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || value > 1 || !log.test(value)) {
                callback(new Error('请输入小于等于1大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          bikeValue: [{
            required: true,
            message: '电动车阀值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0.5 || value > 1 || !log.test(value)) {
                callback(new Error('请输入大于等于0.5小于等于1.0两位小数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          staticHeadProb: [{
            required: true,
            message: '静止阈值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0.01 || value > 0.5 || !log.test(value)) {
                callback(new Error('请输入小于等于0.5大于等于0.01两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          ssimScore: [{
            required: true,
            message: '遮挡结构阈值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || value > 1 || !log.test(value)) {
                callback(new Error('请输入小于等于1大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur',
          }, ],
          gasTankValue: [{
            required: true,
            message: '煤气罐阈值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || value > 1 || !log.test(value)) {
                callback(new Error('请输入小于等于1大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          skewValue: [{
            required: true,
            message: '煤气罐阈值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || value > 90 || !log.test(value)) {
                callback(new Error('请输入小于等于90大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur',
          }, ],

          colorScore: [{
            required: true,
            message: '遮挡颜色阈值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || value > 1 || !log.test(value)) {
                callback(new Error('请输入小于等于1大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur',
          }, ],

          lightChange: [{
            required: true,
            message: '遮挡光线阈值不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: (rule, value, callback) => {
              const log = /^\d+(\.\d{1,2})?$/
              if (value < 0 || !log.test(value)) {
                callback(new Error('请输入大于等于0两位小数的数'));
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }],
          name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur',
          }, ], // rescueCode: [{
          //   required: looks ? false : true,
          //   message: '救援识别码不能为空',
          //   trigger: 'blur',
          // }, ],
          plotId: [{
            required: true,
            message: '所属小区不能为空',
            trigger: 'blur'
          }, ],
          code: [{
              required: true,
              message: '注册代码不能为空',
              trigger: 'blur'
            },
            {
              required: true,
              message: '注册代码长度不多于50个且少于2个',
              trigger: 'blur',
              min: 2,
              max: 50
            },
            {
              required: true,
              message: '注册代码长度不多于50个且少于2个',
              trigger: 'change',
              min: 2,
              max: 50
            },
          ],
          factoryNumber: [{
            required: true,
            message: '出厂编码不能为空',
            trigger: 'blur'
          }, ],

          floorDong: [{
              required: true,
              message: '楼栋不能为空',
              trigger: 'blur'
            },
            {
              required: true,
              message: '楼栋字符长度不多于20个',
              trigger: 'blur',
              max: 20
            },
            {
              required: true,
              message: '楼栋字符长度不多于20个',
              trigger: 'change',
              max: 20
            }
          ],
          eleUnit: [{
              required: true,
              message: '单元不能为空',
              trigger: 'blur'
            },
            {
              required: true,
              message: '单元字符长度不多于20个',
              trigger: 'blur',
              max: 20
            },
            {
              required: true,
              message: '单元字符长度不多于20个',
              trigger: 'change',
              max: 20
            }
          ],
          eleCode: [{
              required: true,
              message: '电梯号不能为空',
              trigger: 'blur'
            },
            {
              required: true,
              message: '电梯号字符长度不多于20个',
              trigger: 'blur',
              max: 20
            },
            {
              required: true,
              message: '电梯号字符长度不多于20个',
              trigger: 'change',
              max: 20
            },
          ],

          eleAddress: [{
            required: true,
            message: '安装位置不能为空',
            trigger: 'blur'
          }, ],
          maintainUnitId: [{
            required: true,
            message: '维保单位不能为空',
            trigger: 'blur'
          }, ],
          propertyUnitId: [{
            required: true,
            message: '物业单位不能为空',
            trigger: 'blur'
          }, ],
          emergencyRescueUnitId: [{
            required: true,
            message: '救援单位不能为空',
            trigger: 'blur'
          }, ],
          registrationAuthorityId: [{
            required: true,
            message: '登记机关不能为空',
            trigger: 'blur'
          }, ],
          insuranceUnitId: [{
            required: true,
            message: '保险单位不能为空',
            trigger: 'blur'
          }, ],
          maintainUserIds: [{
            required: true,
            message: '维保人员不能为空',
            trigger: 'blur'
          }, ],
          propertyUnitUserIds: [{
            required: true,
            message: '物业人员不能为空',
            trigger: 'blur'
          }, ],
          emergencyRescueUserIds: [{
            required: true,
            message: '救援人员不能为空',
            trigger: 'blur'
          }, ],
          insuranceUnitUserIds: [{
            required: true,
            message: '保险人员不能为空',
            trigger: 'blur'
          }, ],
          brandId: [{
            required: true,
            message: '电梯品牌不能为空',
            trigger: 'blur'
          }, ],
          floorHeight: [{
            required: true,
            message: '电梯停靠站数不能为空',
            trigger: 'blur'
          }, {
            required: true,
            validator: this.changefloorHeight,
            trigger: 'blur'
          }],
          underFloor: [{
              required: true,
              message: '地下楼层数不能为空',
              trigger: 'blur'
            },
            {
              required: true,
              validator: this.changeUnderFloor,
              trigger: 'blur'
            }
          ],
          standNumber: [{
            // required: false,
            validator: this.changestandNumber,
            trigger: 'blur'
          }],
          doorNumber: [{
            // required: false,
            validator: this.changedoorNumber,
            trigger: 'blur'
          }],
          elePlaceType: [{
            required: true,
            message: '使用场所不能为空',
            trigger: 'blur'
          }, ],
          overSpeed: [{
              required: true,
              message: '报警阈值不能为空',
              trigger: 'blur'
            },
            {
              validator: this.changeOverSpeed,
              trigger: 'blur'
            }
          ],
          maxSpeed: [{
            required: true,
            message: '最大速度不能为空',
            trigger: 'blur'
          }, {
            required: true,
            validator: this.changemaxSpeeds,
            trigger: 'blur'
          }],
          maxSpeedA: [{
            required: true,
            message: '报警阈值(加速度)不能为空',
            trigger: 'blur'
          }, {
            validator: this.changeMaxSpeedA,
            trigger: 'blur'
          }],
          atedLoad: [{
            required: true,
            message: '额定负载不能为空',
            trigger: 'blur',
          }, {
            required: true,
            validator: atedLoadVolid,
            trigger: 'blur'
          }],
          doorWidth: [{
            required: false,
            validator: validateDoorWidth,
            trigger: 'blur'
          }, {
            required: false,
            validator: validateDoorMaxWidth,
            trigger: 'blur'
          }]
        },
        deviceSosSwitch: {
          sosSwitch: 1, // 主动报警
          aiIdentifySwitch: 1, // AI识别
          audioSosSwitch: 1, // 语音报警
          safetyCircuitSosSwitch: 1, // 安全回路报警
          doorOpenSosSwitch: 0, // 关门异常
          manBlockSos: 0, //有人
          fenderBlockSos: 0, //有物
          closeDoorRepeatedly: 0, //反复开关门
          overFloorSosSwitch: 1, // 非门区停梯
          jtLowestSosSwitch: 1, // 蹲底报警
          jtHightestSosSwitch: 1, // 冲顶报警
          stuckSwitch: 1, // 困人报警
          upperLevel: 0, // 上平层报警
          underbed: 0, // 下平层报警
          nonLevelStop: 0, // 非平层报警
          cameraOcclusion: 0, // 摄像头遮挡报警
          overSpeedSosSwitch: 1, // 电梯超速
          bikePushSosSwitch: 1, // 电动车报警推送
          bikeSosSwitch: 1, // 电动车报警识别
          wechatCodeSos: 0, //微信呼救

          haveBanClosed: 0, // 阻梯功能
          txtSwitch: 0, // 运行日志
          jtSosSwitch: 1, // 急停报警
          runningLadderSosSwitch: 0, // 开门行梯
          outageSosSwitch: 1, //断电视频上传
          gasTankSos: 0, //煤气罐
          viewShiftSos: 0, //视角偏移
          videoCallsSwitch: 0, //可视频通话
          autoFloorLearnSwitch: 0, //每月自学楼层
          outageSos: 1, //断电
        },
        loading: false,
        addBrandCallback: null, // 弹窗回调
        trans: false,
        editSimTime: false, // sim卡时间
        initDeviceCode: null, // 初始物联网设备
        initFloorHeight: null, // 初始楼层
        initUnderFloor: null, // 初始地下楼层
        initSpeedOption: null, // 初始速度选项
        initTxtSwitch: null, // 初始运行日志状态
        DNSList: [], // dns
        confirmStatus: false, //提交按钮
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        InfoForms: JSON.parse(localStorage.getItem('elevatorInfoForm')),
        looks: false,
        codeInfo: '',
        codeName: '',
        loadUping: false,
        plotShow: true,
      }
    },
    watch: {
      elevatorForm: {
        deep: true,
        handler(val) {
          if (this.type == 'add') {
            localStorage.setItem('elevatorInfoForm', JSON.stringify(val))
          }
          // this.setOptions(val);
        },
        // immediate: true
      },
    },

    mounted() {
      const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      this.roleId = adminInfo.roleId
      // this.getUnit()
      // this.getBrand()
      this.getNotice('公告类型', [
        'ELEVATOR_TYPE',
        'GENNERATION',
        'USE_STATE',
        'USE_PLACE',
        'ELEVATOR_VARIETIES',
        'ELEVATOR_CONTROL_MODE',
        'MAINTEN_REMINDER_TIME',
        'MAINTENANCE_LEVEL',
        'SPEED_OPTIONS',
        'ELE_RISK_LEVEL',
        'DNS_SERVER'
      ])
      this.$nextTick(() => {
        if (this.type === 'add') {
          this.elevatorForm = JSON.parse(localStorage.getItem('elevatorInfoForm')) != null ? JSON.parse(
            localStorage
            .getItem(
              'elevatorInfoForm')) : this.elevatorForm;
          this.codeName = this.elevatorForm.name ? this.elevatorForm.name : ''
        }
      })
    },
    created() {},
    methods: {
      //单位新增
      changeAdd(type, data) {
        this.Addtit = '新增'
        this.fign = {
          jdType: 0,
          isActive: 1,
          // 后续需要删除 数据读取权限字段
          onlyReadType: 0
        }
        if (type == 1) {
          this.wbdwVisible = true
        } else if (type == 2) {

          this.wydwVisible = true
        } else if (type == 3) {
          this.jydwVisible = true
        } else if (type == 4) {
          this.djjgVisible = true
        } else if (type == 5) {
          this.bxdwVisible = true
        } else if (type == 6) {
          this.qtdwVisible = true
          this.qtdwType = data
        } else {
          this.xqglVisible = true
        }
      },
      AddClose() {
        this.wydwVisible = false
        this.wbdwVisible = false
        this.jydwVisible = false
        this.djjgVisible = false
        this.bxdwVisible = false
        this.qtdwVisible = false
        this.xqglVisible = false
      },
      Addupdate(data) {
        // changeAdd(6)
        if (data) {
          if (data.xqglId) { //小区
            this.elevatorForm.plotId = data.xqglId
            this.changePlot(data.xqglId)
            this.elevatorForm.propertyUnitUserNames = ''
          } else if (data.wydwId) { //物业
            this.elevatorForm.propertyUnitId = data.wydwId
          } else if (data.wbdwId) { //维保
            this.elevatorForm.maintainUnitId = data.wbdwId
            this.elevatorForm.maintainUserNames = ''
          } else if (data.jydwId) { //救援
            this.elevatorForm.emergencyRescueUnitId = data.jydwId
            this.elevatorForm.emergencyRescueUserNames = ''
          } else if (data.djjgId) { //登记机关
            this.elevatorForm.registrationAuthorityId = data.djjgId
          } else if (data.bxdwId) { //保险
            this.elevatorForm.insuranceUnitId = data.bxdwId
            this.elevatorForm.insuranceUnitUserNames = ''
          } else {
            if (data.type == 'STREET_UNIT') { //街道
              this.elevatorForm.streetUnitId = data.qtdwId
            } else if (data.type == 'VENDOR_UNIT') { //厂商
              this.elevatorForm.manufacturerUnitId = data.qtdwId
            } else if (data.type == 'MAKE_UNIT') { //制造
              this.elevatorForm.manufacturingUnitId = data.qtdwId
            } else if (data.type == 'PROPERTY_UNIT') {
              this.elevatorForm.propertyRightUnitId = data.qtdwId
            } else if (data.type == 'INSTALL_UNIT') {
              this.elevatorForm.installUnitId = data.qtdwId
            } else {
              this.elevatorForm.inspectionUnitId = data.qtdwId
            }
          }
        }
        this.wydwVisible = false
        this.wbdwVisible = false
        this.jydwVisible = false
        this.djjgVisible = false
        this.bxdwVisible = false
        this.qtdwVisible = false
        this.xqglVisible = false
        this.getUnit()
        this.getPlot()
      },
      //自动生成
      getAuto() {
        this.looks = !this.looks
        this.elevatorForm.rescueCode = ''
      },
      changeDNS(rule, value, callback) {
        if (value && !
          /^(\d{1,2}|1\d\d|2[0-3][0-3]|23[0-3])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
          .test(value)) {
          callback(new Error('请输入正确的DNS参数'))
        }
        callback()
      },
      changeOverSpeed(rule, value, callback) {
        if (value < Number(this.elevatorForm.maxSpeed) * 1.1) {
          callback(new Error('超速报警阀值最小不能低于最大速度的1.1倍'))
        }
        callback()
      },
      changemaxSpeeds(rule, value, callback) {
        if (/^\d+(\.\d{1,2})?$/.test(value) && Number(this.elevatorForm.maxSpeed > 0)){
          callback()
        }else{
          callback(new Error('最大速度只可输入大于0的整数'))
        }
      },
      changeMaxSpeedA(rule, value, callback) {
        if (/^\d+(\.\d{1,2})?$/.test(value) && Number(this.elevatorForm.maxSpeedA >= 0.5) && Number(this.elevatorForm
            .maxSpeedA) <= 50) {
          callback()
        } else {
          callback(new Error('加速度阈值输入范围在[0.5,50]'))
        }
      },
      changestandNumber(rule, value, callback) {
        if (value == '') {
          callback()
        } else if (/^[0-9]\d*$/.test(value)) {
          callback()
        } else {
          callback(new Error('只可输入大于0的整数'))
        }
      },
      changedoorNumber(rule, value, callback) {
        if (value == '') {
          callback()
        } else if (/^[0-9]\d*$/.test(value)) {
          callback()
        } else {
          callback(new Error('只可输入大于0的整数'))
        }
      },

      changeUnderFloor(rule, value, callback) {
        if (value !== '') {
          if (/^[0-9]{1,4}$/.test(value)) {
            if (value >= Number(this.elevatorForm.floorHeight)) {
              callback(new Error('地下楼层数必须小于电梯停靠站数'))
            } else {
              callback()
            }
          } else {
            callback(new Error('只可输入0-9999的整数'))
          }
        } else {
          callback(new Error('地下楼层数不能为空'))
        }
      },
      changefloorHeight(rule, value, callback) {
        if (value !== '') {
          if (/^[1-9]\d*$/.test(value)) {
            callback()
          } else {
            callback(new Error('只可输入大于0的整数'))
          }
        } else {
          callback(new Error('电梯停靠站数不能为空'))
        }
      },
      // 新增/修改，电梯信息，回调
      init(type, data, callback) {
        this.title = type == 'add' ? '新增' : (type == 'edit' ? '编辑' : '电梯详情')
        // this.title = '电梯详情'
        this.type = type
        this.addBrandCallback = callback
        this.propertyDisabled = false
        this.visible = true
        this.loading = false
        this.activeName = data.firstName ? data.firstName : 'first'
        this.propertyDisabled = false
        this.elevatorForm =
          type === 'add' ? {
            speedOption: 2,
            eleState: 'IN_USE',
            maxSpeed: 2,
            overSpeed: 2.20,
            maxSpeedA: 1.0,
            smsSos: 0,
            headValue: 0.70,
            openValue: 0.70,
            bikeValue: 0.70,
            staticHeadProb: 0.50,
            portraitBlur: 0,
            bikeBorder: 0,
            doorBorder: 0,
            headBorder: 0,
            ssimScore: 0.80,
            colorScore: 0.50,
            lightChange: 30.0,
            gasTankValue: 0.70,
            skewValue: 1,
          } :
          JSON.parse(JSON.stringify(data))
        if (type == 'add') {
          console.log('新增')
          this.elevatorForm.varieties = this.elevatorForm.varieties ? this.elevatorForm.varieties :
            "TRACTION_AND_FORCE_DRIVE_ELEVATORS"
        }
        this.elevatorForm.inspectionDate = this.elevatorForm.inspectionDate ? new Date(this.elevatorForm
          .inspectionDate) : ''
        this.elevatorForm.simStartTime = this.elevatorForm.simStartTime ? new Date(this.elevatorForm.simStartTime) : ''
        this.elevatorForm.simEndTime = this.elevatorForm.simEndTime ? new Date(this.elevatorForm.simEndTime) : ''
        this.elevatorForm.registrationDate = this.elevatorForm.registrationDate ? new Date(this.elevatorForm
          .registrationDate) : ''
        this.elevatorForm.installTransformDate = this.elevatorForm.installTransformDate ? new Date(this.elevatorForm
          .installTransformDate) : ''


        // 保险单位
        if (type !== 'add') {
          this.elevatorForm.insuranceUnitId = data.insuranceUnitId ?
            data.insuranceUnitId :
            100
          this.elevatorForm.insuranceUnitUserIds =
            data.insuranceUnitUserIds.length !== 0 ?
            data.insuranceUnitUserIds : [100]
          this.elevatorForm.insuranceUnitUserNames = data.insuranceUnitUserNames ?
            data.insuranceUnitUserNames :
            '暂无保险人员'


          this.$set(this.elevatorForm, 'headValue', data.headValue ? data.headValue : 0)
          this.$set(this.elevatorForm, 'openValue', data.openValue ? data.openValue : 0)
          this.$set(this.elevatorForm, 'bikeValue', data.bikeValue ? data.bikeValue : 0)
        }

        // 安装位置经纬度
        this.elevatorForm.lnglat =
          type === 'add' ? [] : [data.longitude, data.latitude]
        if (type === 'add') {
          // 球机报警开关
          this.$set(this.elevatorForm, 'deviceSosSwitch', this.deviceSosSwitch)
          // 盒子报警开关
          // this.$set(
          //   this.elevatorForm,
          //   'terminalSosSwitch',
          //   this.terminalSosSwitch
          // )
          // 盒子报警显隐
          // this.$set(this.elevatorForm, 'sosSwitchStatus', this.sosSwitchStatus)
          // 盒子型号
          // this.$set(this.elevatorForm, 'terminalType', 1)
          // 盒子动作
          // this.$set(this.elevatorForm, 'terminalSosType', 1)
        }
        if (this.elevatorForm.deviceSosSwitch.length === 0) {
          this.$set(this.elevatorForm, 'deviceSosSwitch', this.deviceSosSwitch)
        }
        // if (this.elevatorForm.terminalSosSwitch.length === 0) {
        //   this.$set(
        //     this.elevatorForm,
        //     'terminalSosSwitch',
        //     this.terminalSosSwitch
        //   )
        // }
        // if (this.elevatorForm.sosSwitchStatus.length === 0) {
        //   this.$set(this.elevatorForm, 'sosSwitchStatus', this.sosSwitchStatus)
        // }
        // // 盒子型号
        // if (!this.elevatorForm.terminalType) {
        //   this.$set(this.elevatorForm, 'terminalType', 1)
        //   // 盒子动作
        //   if (!this.elevatorForm.terminalSosName) {
        //     this.$set(this.elevatorForm, 'terminalSosType', 1)
        //     this.$set(
        //       this.elevatorForm,
        //       'terminalSosSwitch',
        //       this.terminalSosSwitch
        //     )
        //   }
        // }

        // 记录初始状态
        this.initDeviceCode =
          this.initData.deviceCode || this.initData.deviceCode === '' ?
          this.initData.deviceCode :
          null
        this.initFloorHeight = this.initData.floorHeight ?
          this.initData.floorHeight :
          null
        this.initUnderFloor =
          this.initData.underFloor || this.initData.underFloor === 0 ?
          this.initData.underFloor :
          null
        this.initSpeedOption = this.initData.speedOption ?
          this.initData.speedOption :
          null
        this.initTxtSwitch =
          this.initData.deviceSosSwitch && this.initData.deviceSosSwitch.txtSwitch ?
          this.initData.deviceSosSwitch.txtSwitch :
          0

        this.initHaveBanClosed =
          this.initData.deviceSosSwitch && this.initData.deviceSosSwitch.haveBanClosed ?
          this.initData.deviceSosSwitch.haveBanClosed :
          0

        this.$nextTick(() => {
          this.getUnit()
          this.getPlot()
          this.getBrand()
          if (data.plotId) {
            this.getPlotInfo(data.plotId)
          }
        })
      },
      changeVar(val) {
        this.elevatorForm.varieties = val
        this.$forceUpdate()
      },
      addFloor() {
        const reg = /^[0-9]+$/
        const log = this.plotArr.find(item => item.id == this.elevatorForm.plotId) ? this.plotArr.find(item => item
          .id == this.elevatorForm.plotId).name : ""
        this.codeName = this.elevatorForm.name && log ? this.elevatorForm.name.split(log)[0] + log : ""
        const floorDong = this.elevatorForm.floorDong != undefined ? (reg.test(this.elevatorForm.floorDong) ? JSON
          .parse(JSON.stringify(this.elevatorForm
            .floorDong)) + '栋' : JSON.parse(JSON.stringify(this.elevatorForm.floorDong))) : ''
        this.$set(this.elevatorForm, 'floorDong', floorDong)
        // this.elevatorForm.floorDong = floorDong
        this.elevatorForm.name = this.codeName + this.checkType(this.elevatorForm.floorDong) + this.checkType(this
          .elevatorForm.eleUnit) + this.checkType(this.elevatorForm.eleCode)
        // this.elevatorForm.name = data
        // this.$set(this.elevatorForm, 'name', data)
      },
      changeEleunit() {
        const reg = /^[0-9]+$/
        const log = this.plotArr.find(item => item.id == this.elevatorForm.plotId) ? this.plotArr.find(item => item
          .id == this.elevatorForm.plotId).name : ""
        this.codeName = this.elevatorForm.name && log ? this.elevatorForm.name.split(log)[0] + log : ""
        const eleUnit = this.elevatorForm.eleUnit != undefined ? (reg.test(this.elevatorForm.eleUnit) ? JSON.parse(JSON
            .stringify(this.elevatorForm.eleUnit)) +
          '单元' : JSON.parse(JSON.stringify(this.elevatorForm.eleUnit))) : ''
        this.$set(this.elevatorForm, 'eleUnit', eleUnit)
        this.elevatorForm.eleUnit = eleUnit

        this.elevatorForm.name = this.codeName + this.checkType(this.elevatorForm.floorDong) + this.checkType(this
          .elevatorForm.eleUnit) + this.checkType(this.elevatorForm.eleCode)
        // this.elevatorForm.name = JSON.parse(JSON.stringify(data))
        // this.$set(this.elevatorForm, 'name', data)
      },
      changeCode() {
        // this.elevatorForm.name=''
        const reg = /^[0-9]+$/
        const log = this.plotArr.find(item => item.id == this.elevatorForm.plotId) ? this.plotArr.find(item => item
          .id == this.elevatorForm.plotId).name : ""
        this.codeName = this.elevatorForm.name && log ? this.elevatorForm.name.split(log)[0] + log : ""
        const eleCode = this.elevatorForm.eleCode != undefined ? (reg.test(this.elevatorForm.eleCode) ? JSON.parse(JSON
            .stringify(this.elevatorForm.eleCode)) +
          '#' : JSON.parse(JSON.stringify(this.elevatorForm.eleCode))) : ''
        this.$set(this.elevatorForm, 'eleCode', eleCode)
        // this.elevatorForm.eleCode = eleCode

        this.elevatorForm.name = this.codeName + this.checkType(this.elevatorForm.floorDong) + this.checkType(this
          .elevatorForm.eleUnit) + this.checkType(this.elevatorForm.eleCode)

        // this.elevatorForm.name = data
        // this.$set(this.elevatorForm, 'name', data)
      },

      Update() {
        if (this.plotShow) {
          // this.getPlot()
          this.loadUping = true
          // this.getPlotInfo(this.elevatorForm.plotId,'change')
          // this.codeName = ''
          // const log=this.plotArr.find(item=>item.id == this.elevatorForm.plotId) ? this.plotArr.find(item=>item.id == this.elevatorForm.plotId).name : ''
          if (this.plotArr) {
            this.getPlotInfo(this.elevatorForm.plotId, 'change')
            // const reg = /^[0-9]+$/
            // const log = this.plotArr.find(item => item.id == this.elevatorForm.plotId) ? this.plotArr.find(item => item
            //   .id == this.elevatorForm.plotId) : ""
            // const plot = log.mergerName ? log.mergerName.split(',')[0]+'省'+log.mergerName.split(',')[1]+'市'+log.mergerName.split(',')[2]+'区' : ''
            // this.codeName = this.elevatorForm.name && log ? this.elevatorForm.name.split(log.name)[0] + log.name : this.elevatorForm.name
            // this.elevatorForm.name = ''
            // const data = this.codeName + this.checkType(this.elevatorForm.floorDong) + this.checkType(this
            //     .elevatorForm.eleUnit) +
            //   this.checkType(this.elevatorForm.eleCode)
            // this.elevatorForm.name = log ? data : this.codeName
            setTimeout(() => {
              this.loadUping = false
            }, 2000)
          }
        } else {
          this.$message({
            type: 'warning',
            message: '您选择的小区所属区域需补充区县，请先进行修改'
          })
          // this.getPlotInfo(this.elevatorForm.plotId, 'change')
        }
      },

      checkType(value) {
        return value ? value : ''
      },

      changePlot(val) {
        this.codeName = ''
        this.getPlotInfo(val, 'change')
        this.changeUnit('property')
      },
      getPlotInfo(id, type) {
        const dataObj = {
          current: 1,
          size: 9999,
          id: id
        }
        this.$http.post("/api/ele/web/plot/list", dataObj).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              }
            } = res;
            if (type == 'change') {
              this.$set(this.elevatorForm, 'propertyUnitId', data.records.length > 0 ? data.records[0]
                .propertyUnitId : '')
              this.elevatorForm.propertyUnitName = data.records.length > 0 ? data.records[0].propertyUnitName : ''
            }
            // this.$set(this.elevatorForm, 'propertyUnitName', data.records.length > 0 ? data.records[0]
            //   .propertyUnitName : '')
            // this.elevatorForm.propertyUnitName = data.records.length > 0 ? data.records[0].propertyUnitName : ''
            this.propertyDisabled = true
            if (res.data.data.records.length > 0) {
              this.CodeInfo(res.data.data.records[0].areaCodes, data.records[0].name, type)
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      CodeInfo(code, name, type) {
        if (JSON.parse(code).length < 3) {
          this.plotShow = false
          // this.elevatorForm.plotId = ''
          // this.elevatorForm.name = ''
          this.$message({
            type: 'warning',
            message: '您选择的小区所属区域需补充区县，请先进行修改'
          })
        } else {
          this.plotShow = true
          const data = {
            areaCodes: code
          }
          this.$http.post("/api/ele/web/elevatorInfo/getRescueCodeInfo", data).then((res) => {
            if (res.data.success) {
              this.codeInfo = res.data.data.autoCode ? res.data.data.autoCode : ''
              this.$forceUpdate()
              const num = this.$mapCode.filter(item => item.areaCode == JSON.parse(code)[0]) == [] ? '' : this
                .$mapCode.filter(item => item.areaCode == JSON.parse(code)[0])
              const num1 = num[0].children.filter(item => item.areaCode == JSON.parse(code)[1]) == [] ? '' :
                num[0]
                .children.filter(item => item.areaCode == JSON.parse(code)[1])
              const num2 = num1[0].children.filter(item => item.areaCode == JSON.parse(code)[2]) == [] ? '' :
                num1[
                  0]
                .children.filter(item => item.areaCode == JSON.parse(code)[2])
              this.codeName = this.elevatorForm.plotId ? num[0].name + num1[0].name + num2[0].name + name : []
              if (type == 'change') {
                this.elevatorForm.name = this.codeName + this.checkType(this.elevatorForm.floorDong) + this
                  .checkType(
                    this.elevatorForm.eleUnit) +
                  this.checkType(this.elevatorForm.eleCode)
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      },
      submit() {
        this.$refs.elevatorForm.validate(async (valid) => {
          if (valid) {
            const data = JSON.parse(JSON.stringify(this.elevatorForm))
            data.deviceSosSwitch = JSON.stringify(
              this.elevatorForm.deviceSosSwitch
            )
            data.sosSwitchStatus = JSON.stringify(
              this.elevatorForm.sosSwitchStatus
            )
            data.terminalSosSwitch = JSON.stringify(
              this.elevatorForm.terminalSosSwitch
            )
            // if (this.initHaveBanClosed != this.elevatorForm.deviceSosSwitch.haveBanClosed) {
            // 	this.restart()
            // }
            // data.areaCodeArr = data.areaCode
            // if (data.areaCodeArr) {
            //   if (data.areaCodeArr.length > 0) {
            //     data.areaCodes = JSON.stringify(data.areaCodeArr)
            //     data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
            //   }
            // }
            // sim开卡时间
            if (this.looks) {
              this.elevatorForm.rescueCode = ''
              data.autoCode = this.codeInfo
            } else {
              data.autoCode = ''
            }
            if (data.areaCode.includes('5326')) {
              this.elevatorForm.deviceSosSwitch.wechatCodeSos = 1
            }
            data.headValue = this.elevatorForm.headValue == 0 ? 0.50 : this.elevatorForm.headValue
            data.openValue = this.elevatorForm.openValue == 0 ? 0.50 : this.elevatorForm.openValue
            data.bikeValue = this.elevatorForm.bikeValue == 0 ? 0.50 : this.elevatorForm.bikeValue
            if (
              this.elevatorForm.simStartTime &&
              this.elevatorForm.simEndTime &&
              this.editSimTime &&
              this.elevatorForm.simStartTime.getTime() >= this.elevatorForm.simEndTime.getTime()
            ) {
              this.$message.error('sim到期时间必须晚与开卡时间')
              return
            }
            if (this.elevatorForm.insuranceUnitId == 100) {
              this.elevatorForm.insuranceUnitId = null
              this.elevatorForm.insuranceUnitUserIds = []
              data.insuranceUnitId = null
              data.insuranceUnitUserIds = []
            }
            data.simStartTime = this.elevatorForm.simStartTime ?
              this.elevatorForm.simStartTime.getTime() :
              ''
            data.simEndTime = this.elevatorForm.simEndTime ?
              this.elevatorForm.simEndTime.getTime() :
              ''
            const url =
              this.type === 'add' ?
              (this.roleId != '99' ? (this.$store.state.isWBJB ? '/api/ele/web/elevatorInfo/easySaveElevator' :
                  '/api/ele/web/elevatorInfo/submit') :
                '/api/ele/web/elevatorInfo/simplifySaveElevator') :
              (this.$store.state.isWBJB ? '/api/ele/web//elevatorInfo/easyUpdateElevator' :
                '/api/ele/web/elevatorInfo/update')
            // 楼层修改重启球机
            if (this.type === 'edit') {
              if (
                this.elevatorForm.deviceCode &&
                (this.initDeviceCode !== this.elevatorForm.deviceCode ||
                  this.initFloorHeight !== this.elevatorForm.floorHeight ||
                  this.initUnderFloor !== this.elevatorForm.underFloor ||
                  this.initSpeedOption !== this.elevatorForm.speedOption ||
                  this.initTxtSwitch !== this.elevatorForm.deviceSosSwitch.txtSwitch ||
                  this.initHaveBanClosed != this.elevatorForm.deviceSosSwitch.haveBanClosed
                )
              ) {
                const agentData = {
                  act: 'device_restart',
                  device_id: this.elevatorForm.deviceCode,
                }
                this.socketApi.sendSock(agentData, (e) => {
                  if (e.cmd === 'device_restart' && e.status) {
                    this.$message.success('稍后球机自动重启！')
                  } else {
                    this.$message.error(e.msg)
                  }
                })
              }
            }
            this.confirmStatus = true
            this.$http.post(url, data).then((res) => {
              if (res.data.success) {
                this.$message.success(res.data.msg)
                this.$refs.elevatorForm.resetFields()
                this.looks = false
                this.codeInfo = ''
                this.$emit('closeActione')
                localStorage.setItem('elevatorInfoForm', JSON.stringify(null))
                if (this.addBrandCallback) {
                  this.addBrandCallback(true)
                }
                this.visible = false
              } else {
                this.$message.warning(res.data.msg)
                if (this.elevatorForm.insuranceUnitId === null) {
                  this.elevatorForm.insuranceUnitId = 100
                  this.elevatorForm.insuranceUnitUserIds = [100]
                }
              }
              this.editSimTime = false
              this.confirmStatus = false
            })
          } else {
            if (this.roleId != 99) {
              if (
                !this.elevatorForm.name ||
                !this.elevatorForm.code ||
                !this.elevatorForm.factoryNumber ||
                !this.elevatorForm.plotId ||
                !this.elevatorForm.floorDong ||
                !this.elevatorForm.eleUnit ||
                !this.elevatorForm.eleCode ||
                this.elevatorForm.code.length < 2 ||
                this.elevatorForm.code.length > 50 ||
                this.elevatorForm.floorDong.length > 20 ||
                this.elevatorForm.eleUnit.length > 20 ||
                this.elevatorForm.eleCode.length > 20 ||
                !this.elevatorForm.eleAddress
              ) {
                this.$message.warning('主要资料表单信息异常，请检查')
              } else if (
                !this.elevatorForm.maintainUnitId ||
                this.elevatorForm.maintainUnitId.length === 0 ||
                !this.elevatorForm.propertyUnitId ||
                this.elevatorForm.propertyUnitId.length === 0 ||
                !this.elevatorForm.emergencyRescueUnitId ||
                this.elevatorForm.emergencyRescueUnitId.length === 0 ||
                !this.elevatorForm.insuranceUnitId ||
                this.elevatorForm.insuranceUnitId.length === 0 ||
                !this.elevatorForm.maintainUserIds ||
                this.elevatorForm.maintainUserIds.length === 0 ||
                !this.elevatorForm.propertyUnitUserIds ||
                this.elevatorForm.propertyUnitUserIds.length === 0 ||
                !this.elevatorForm.emergencyRescueUserIds ||
                this.elevatorForm.emergencyRescueUserIds.length === 0 ||
                !this.elevatorForm.insuranceUnitUserIds ||
                this.elevatorForm.insuranceUnitUserIds.length === 0 ||
                !this.elevatorForm.registrationAuthorityId
              ) {
                this.$message.warning('相关单位表单信息异常，请检查')
              } else if (
                !this.elevatorForm.maxSpeed ||
                this.elevatorForm.maxSpeed < 1 ||
                !this.elevatorForm.overSpeed ||
                !this.elevatorForm.maxSpeedA ||
                this.elevatorForm.maxSpeedA < 0.5 ||
                this.elevatorForm.maxSpeedA > 50 ||
                this.elevatorForm.gasTankValue <= 0 ||
                this.elevatorForm.gasTankValue > 1 ||
                this.elevatorForm.overSpeed < this.elevatorForm.maxSpeed * 1.1 ||
                (this.elevatorForm.bikeValue < 0.5 || this.elevatorForm.bikeValue > 1) ||
                (this.elevatorForm.staticHeadProb < 0.01 || this.elevatorForm.staticHeadProb > 0.5) ||
                (this.elevatorForm.headValue < 0 || this.elevatorForm.headValue > 1) ||
                (this.elevatorForm.openValue < 0 || this.elevatorForm.openValue > 1) ||
                (this.elevatorForm.ssimScore < 0 || this.elevatorForm.ssimScore > 1) ||
                (this.elevatorForm.colorScore < 0 || this.elevatorForm.colorScore > 1) ||
                (this.elevatorForm.skewValue < 0 || this.elevatorForm.skewValue > 90) ||
                this.elevatorForm.lightChange < 0 ||

                (this.elevatorForm.deviceDns && !
                  /^(\d{1,2}|1\d\d|2[0-3][0-3]|23[0-3])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
                  .test(this.elevatorForm.deviceDns)
                )
              ) {
                this.$message.warning('物联网设备相关设置表单信息异常，请检查')
              } else {
                this.$message.warning('电梯参数表单信息异常，请检查')
              }
            }
            if (this.roleId == 99) {
              if (
                !this.elevatorForm.name ||
                !this.elevatorForm.code ||
                !this.elevatorForm.factoryNumber ||
                !this.elevatorForm.floorDong ||
                !this.elevatorForm.eleUnit ||
                !this.elevatorForm.eleCode ||
                this.elevatorForm.code.length < 2 ||
                this.elevatorForm.code.length > 50 ||
                this.elevatorForm.floorDong.length > 20 ||
                this.elevatorForm.eleUnit.length > 20 ||
                this.elevatorForm.eleCode.length > 20 ||
                !this.elevatorForm.eleAddress
              ) {
                this.$message.warning('主要资料表单信息异常，请检查')
              } else {
                this.$message.warning('电梯参数表单信息异常，请检查')
              }
            }
          }
        })
      },
      changeTime(val) {},
      // 改变阻梯
      changeBanClosed(value) {
        if (value == 1) {
          this.$confirm("开启阻梯功能前，请先确认摄像头是否能够正常拍摄到电梯门下半部分及轿厢地板画面？如果可以，请点击确认！", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.elevatorForm.deviceSosSwitch.haveBanClosed = value
            }).catch(() => {
              this.elevatorForm.deviceSosSwitch.haveBanClosed = 0
            });
        }
      },
      // 确认弹框
      confirmForm() {
        this.submit()
      },
      // 关闭弹框
      closeDialog(event) {
        if (this.type == 'add') {
          this.$confirm('是否保存当前草稿内容，下次自动填入？', '提示', {
            cancelButtonText: '不保存',
            confirmButtonText: '保存',
            type: 'warning',
            // closeOnClickModal: false,
            distinguishCancelAndClose: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,

          }).then(() => {
            this.visible = false
            this.looks = false
            this.codeInfo = ''
            this.$emit('closeActione')
          }).catch((e) => {
            if (e == 'cancel') {
              // TODO 确认不通过执行逻辑   B按钮逻辑
              this.visible = false
              this.looks = false
              this.codeInfo = ''
              this.$emit('closeActione')
              localStorage.setItem('elevatorInfoForm', JSON.stringify(null))

            } else if (e == 'close') {
              // TODO 右上角X的执行逻辑
              // this.visible = false
              this.looks = false
              this.codeInfo = ''
              // this.$emit('closeActione')
            }
          })
        } else {
          this.visible = false
          this.looks = false
          this.codeInfo = ''
          this.$emit('closeActione')
        }
      },
      closeAddress() {
        this.selectAddressVisible = false
        this.addressVisible = false
      },
      changeTime() {
        this.editSimTime = true
      },

      // // 盒子自定义名称改变
      // terminalSosNameChange() {
      //   if (this.elevatorForm.terminalSosName === '') {
      //     // 盒子动作
      //     this.$set(this.elevatorForm, 'terminalSosType', 1)
      //     this.elevatorForm.terminalSosSwitch.customizeSosSwitch = 0
      //     this.elevatorForm.sosSwitchStatus.customizeSosSwitch = false
      //   }
      // },

      // // 关闭开启触发
      // changeSwitch(data, type) {
      //   if (!data) {
      //     this.elevatorForm.terminalSosSwitch[type] = 0
      //   }
      // },
      changeUnit(type) {
        if (type === 'maintain') {
          this.elevatorForm.maintainUserIds = []
          this.elevatorForm.maintainUserNames = ''
        }
        if (type === 'property') {
          this.elevatorForm.propertyUnitUserIds = []
          this.elevatorForm.propertyUnitUserNames = ''
        }
        if (type === 'emergency') {
          this.elevatorForm.emergencyRescueUserIds = []
          this.elevatorForm.emergencyRescueUserNames = ''
        }
        if (type === 'insurance') {
          this.elevatorForm.insuranceUnitUserIds = []
          this.elevatorForm.insuranceUnitUserNames = ''
          if (this.elevatorForm.insuranceUnitId === 100) {
            this.elevatorForm.insuranceUnitUserIds = [100]
            this.elevatorForm.insuranceUnitUserNames = '暂无保险人员'
          }
        }
      },

      // 选择安装位置
      chooseAddress() {
        this.addressVisible = true
        this.selectAddressVisible = true
      },
      // 点击位置
      clickAddress(data) {
        this.addressInfo = data
        // if (data.areaCode.includes('5326')) {
        //   this.elevatorForm.deviceSosSwitch.wechatCodeSos = 1
        // } else {
        //   this.elevatorForm.deviceSosSwitch.wechatCodeSos = 0
        // }
        // if (data.areaCode == "532601000000") {
        //   this.elevatorForm.deviceSosSwitch.wechatCodeSos = 1
        // }

        // this.elevatorForm.eleAddress = data.address
        // this.elevatorForm.areaCode = data.areaCode
        // this.elevatorForm.longitude = data.markersPosition[0]
        // this.elevatorForm.latitude = data.markersPosition[1]
        // this.elevatorForm.lnglat = [
        //   data.markersPosition[0],
        //   data.markersPosition[1],
        // ]
        localStorage.setItem('elevatorInfoForm', JSON.stringify(this.elevatorForm))
        if (this.trans) {
          this.addressVisible = false
          this.selectAddressVisible = false
          this.trans = false
        }
      },
      // 确定地址
      transfeRaddress() {
        if (this.addressInfo.areaCode.includes('5326')) {
          this.elevatorForm.deviceSosSwitch.wechatCodeSos = 1
        } else {
          this.elevatorForm.deviceSosSwitch.wechatCodeSos = 0
        }
        if (this.addressInfo.areaCode == "532601000000") {
          this.elevatorForm.deviceSosSwitch.wechatCodeSos = 1
        }

        this.elevatorForm.eleAddress = this.addressInfo.address
        this.elevatorForm.areaCode = this.addressInfo.areaCode
        this.elevatorForm.longitude = this.addressInfo.markersPosition[0]
        this.elevatorForm.latitude = this.addressInfo.markersPosition[1]
        this.elevatorForm.lnglat = [
          this.addressInfo.markersPosition[0],
          this.addressInfo.markersPosition[1],
        ]
        this.trans = true
        this.$nextTick(() => {
          this.$refs.SelectAddress.getInfo()
        })
      },
      // 自动算超速预警值
      changemaxSpeed() {
        this.elevatorForm.overSpeed = (this.elevatorForm.maxSpeed * 1.1).toFixed(2)
      },
      changeSosSwitch(value) {
        if (value != 0) {
          this.$confirm('开启阻梯功能前，请先确认摄像头是否能够正常拍摄到电梯门下半部分及轿厢地板画面？如果可以，请点击确认！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // this.restart()
          }).catch(() => {
            this.elevatorForm.deviceSosSwitch.haveBanClosed = 0
            this.$message({
              type: 'info',
              message: '已取消操作'
            });
          });
        }
      },

      restart() {
        const agentData = {
          act: 'device_restart',
          device_id: this.elevatorForm.deviceCode,
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.cmd === 'device_restart' && e.status) {
            this.$message.success('稍后球机自动重启！')
          } else {
            this.$message.error(e.msg)
          }
        })
      },

      // 选择人员
      selectElevator(type, id) {
        // 单位id
        this.orgId = id
        // 单位名称
        let unitArr = []
        // 已选人员集合
        let initArr = []
        this.personType = type
        this.unitType = type
        switch (type) {
          case 'MAINTAIN_UNIT':
            initArr = this.maintainArr
            this.initIds = this.elevatorForm.maintainUserIds ?? []
            unitArr = this.wbUnit

            break

          case 'TENEMENT_UNIT':
            initArr = this.propertyArr
            this.initIds = this.elevatorForm.propertyUnitUserIds ?? []
            unitArr = this.wyUnit
            break

          case 'RESCUE_UNIT':
            initArr = this.emergencyArr
            this.initIds = this.elevatorForm.emergencyRescueUserIds ?? []
            unitArr = this.jyUnit
            break

          case 'INSURANCE_UNIT':
            initArr = this.insurancArr
            this.initIds = this.elevatorForm.insuranceUnitUserIds ?? []
            unitArr = this.bxUnit
            break

          default:
            break
        }

        this.unitName = unitArr.find((item) => item.id === id) ?
          unitArr.find((item) => item.id === id).name :
          ''
        this.selectPersonVisible = true
        this.$nextTick(() => {
          this.$refs.selectElevator.init(initArr, (refresh) => {
            if (refresh) {}
          })
        })
      },
      // 选择人员返回
      selectOtherResult(data) {

        const nameArr = []
        const idArr = []
        data.map((item) => {
          nameArr.push(`${item.name}(${item.contactWay})`)
          idArr.push(item.id)
        })
        // this.initArr = data
        this.selectPersonVisible = false

        switch (this.personType) {
          case 'MAINTAIN_UNIT':
            this.maintainArr = data
            this.$set(this.elevatorForm, 'maintainUserNames', nameArr.join())
            this.elevatorForm.maintainUserIds = idArr
            break

          case 'TENEMENT_UNIT':
            this.propertyArr = data
            this.$set(this.elevatorForm, 'propertyUnitUserNames', nameArr.join())
            this.elevatorForm.propertyUnitUserIds = idArr
            break

          case 'RESCUE_UNIT':
            this.emergencyArr = data
            this.$set(
              this.elevatorForm,
              'emergencyRescueUserNames',
              nameArr.join()
            )
            this.elevatorForm.emergencyRescueUserIds = idArr
            break

          case 'INSURANCE_UNIT':
            this.insuranceArr = data
            this.$set(this.elevatorForm, 'insuranceUnitUserNames', nameArr.join())
            this.elevatorForm.insuranceUnitUserIds = idArr
            break

          default:
            break
        }
      },

      // 获取各单位数据
      getUnit() {
        //维保单位,物业单位,救援单位,安装单位,保险单位,制造单位,产权单位,登记机关,检验单位,厂商单位
        const arr = [
          'MAINTAIN_UNIT',
          'TENEMENT_UNIT',
          'RESCUE_UNIT',
          'INSTALL_UNIT',
          'INSURANCE_UNIT',
          'MAKE_UNIT',
          'PROPERTY_UNIT',
          'REGISTER_UNIT',
          'CHECK_UNIT',
          'VENDOR_UNIT',
          'STREET_UNIT'
        ]
        const data = {
          current: 1,
          size: 10000,
          user: this.adminInfo.userName != 'admin' ? (this.initData.createUser ? this.initData.createUser :
            '') : '',
        }
        arr.map((item) => {
          data.type = item
          if (item === 'RESCUE_UNIT') {
            data.type = 'RESCUE_UNIT,MAINTAIN_UNIT'
          }
          this.$http.post('/api/system/web/org/list', data).then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              switch (item) {
                case 'MAINTAIN_UNIT':
                  this.wbUnit = data.records ?? []
                  break
                case 'TENEMENT_UNIT':
                  this.wyUnit = data.records ?? []
                  break
                case 'RESCUE_UNIT':
                  this.jyUnit = data.records ?? []
                  break
                case 'INSTALL_UNIT':
                  this.azUnit = data.records ?? []
                  break
                case 'INSURANCE_UNIT':
                  this.bxUnit = data.records ?? []
                  this.bxUnit.unshift({
                    id: 100,
                    name: '无保险单位',
                  })
                  break
                case 'MAKE_UNIT':
                  this.zzUnit = data.records ?? []
                  break
                case 'PROPERTY_UNIT':
                  this.cqUnit = data.records ?? []
                  break
                case 'REGISTER_UNIT':
                  this.djUnit = data.records ?? []
                  break
                case 'CHECK_UNIT':
                  this.jyanUnit = data.records ?? []
                  break
                case 'VENDOR_UNIT':
                  this.csUnit = data.records ?? []
                  break
                case 'STREET_UNIT':
                  this.jdUnit = data.records ?? []
                  break
                default:
                  break
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
      },

      // 所属小区
      getPlot() {
        if (this.type != 'add') {
          this.plotLoading = true
        }
        const data = {
          current: 1,
          size: 10000,
          isUse: 0,
          user: this.initData.createUser && this.adminInfo.userName != 'admin' ? this.initData.createUser : '',
        }
        this.$http.post('/api/ele/web/plot/list', data).then((res) => {
          if (res.data.code === 200) {
            const {
              data: {
                data
              },
            } = res
            this.plotArr = data.records ?? []
            this.plotLoading = false
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 电梯品牌
      getBrand() {
        const data = {
          current: 1,
          size: 10000,
        }
        this.$http.post('/api/ele/web/brand/list', data).then((res) => {
          if (res.data.code === 200) {
            const {
              data: {
                data
              },
            } = res
            this.eleBrand = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 获取字典
      getNotice(type, code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.eleType = data.ELEVATOR_TYPE
              this.genneration = data.GENNERATION
              this.useState = data.USE_STATE
              this.usePlace = data.USE_PLACE
              this.eleVarieties = data.ELEVATOR_VARIETIES
              this.eleControlMode = data.ELEVATOR_CONTROL_MODE
              this.maintenReminderTime = data.MAINTEN_REMINDER_TIME
              this.maintenLevel = data.MAINTENANCE_LEVEL
              this.speedOptions = data.SPEED_OPTIONS
              this.riskLevelList = data.ELE_RISK_LEVEL
              this.DNSList = data.DNS_SERVER
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  // /deep/.el-loading-mask{
  //   background-color: #FFF;
  //   border-radius: 3px;
  // }
  /deep/.el-loading-spinner .circular {
    height: 25px;
    width: 25px;
    text-align: end;
    margin-right: 5px;
    margin-top: 9px;
  }

  /deep/.nameInfo .el-loading-spinner .circular {
    margin-right: 120px;
  }

  // .el-radio{
  //   color: #adb0b5 !important;
  // }

  .wid90 {
    width: 90%;
  }

  .wid86 {
    width: 86%;
  }

  .wid94 {
    width: 94%;
  }

  .textarea {
    /deep/.el-textarea__inner {
      color: white !important;
      border: 1px solid #04e3ef !important;
      background-color: #1282a5 !important;
    }
  }

  .terminalType /deep/.el-select .el-input {
    width: 80px;
  }

  .custom /deep/.el-input {
    width: 120px;
    margin: 0 20px;
  }

  .customItem {
    /deep/.el-form-item__content {
      margin-left: 50px !important;
    }
  }

  /deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #409EFF !important;
    background: #409EFF !important;
  }

  /deep/.is-checked .el-radio__label {
    color: #409EFF !important;
  }
</style>
