<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body width="1100px" top="8vh"
    :title="forms.name" @close="closeDialog">
    <div style="height: 70vh;">
      <div class="Newcontent scrollElement">
        <!-- {{forms.content}} -->
        <div v-html="forms.content" v-if="forms.content"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      subForm: Object,
    },
    data() {
      return {
        dialogVisible: true,
        forms: {},
        goodsDetail: '',
      }
    },
    created() {
      this.forms = this.subForm ? this.subForm : ''
      this.forms = {
        content: this.subForm.content.replace(/img/gi, 'img style="max-width: 900px"'),
        name: this.subForm.name
      }
    },
    methods: {
      closeDialog() {
        this.dialogVisible = false;
        this.$emit("closeCheck");
      }

    }
  }
</script>

<style scoped>
  .Newcontent {
    padding: 20px 10px;
    height: 55vh;
    overflow-y: scroll;
  }
</style>
