<template>
  <div style="padding-top: 10px;">
    <div style="display: flex;background: #1282a5;border: 1.5px solid #FFF;border-radius: 3px;padding: 10px;">
      <div style="flex:1; height: 389px;display: flex;align-items: center;">
        <div v-show="percentage != 100"
          style="height:395px; display: flex; justify-content: center; align-items: center;background: #000; width: 505px;margin-right: 27px;margin-top: 11px;">
          <div style="position: relative;">
            <span class="span-width">{{percentage}}%</span>
            <img :src="require(`@/assets/img/${percentage}.png`)" style="width: 75px; height: 75px;">
          </div>
        </div>
        <div v-show="percentage == 100" style="width: 95%;">
          <div>
            <video v-show="isHaveVideo" style="width: 515px;height: 410px;margin-top: 15px;" controls autoplay :muted='isMuted'
              id="onPlayJkSos" />

            <div v-if="!isHaveVideo" style="width: 100%;height: 100%; display:flex; align-items: center; justify-content: center;">
              <el-image
                :src="require(`@/assets/noJKVideo.png`)"
                style="height: 40px; width: 55px; cursor: pointer; margin-right: 10px" />
              <div style="font-size: 22px">该设备不支持查看视频</div>
            </div>
            <template v-for="item in meData">
              <video v-show="false" style="width: 100px;height: 100px;" muted controls autoplay :id="item.session_id"
                :key="item.session_id" />
            </template>
          </div>
        </div>
      </div>
      <div style="padding: 0px 10px;background: #025682; flex:1; height: 395px;margin-left: -15px;margin-top: 4px;margin-left: 20px;">
        <p style="margin-top:6px">
          报警类型：
          <el-button type="danger" size="mini" style="cursor: auto;"
            v-if="typeDate.find(item=>item.value==dioDetailsItem.type)">
            {{typeDate.find(item=>item.value==dioDetailsItem.type).label}}
          </el-button>
          <a style="float:right;text-decoration: underline;color: #7AE2EE;cursor: pointer;"
            v-if="adminInfo.userName == 'admin'" @click="changeValue(dioDetailsItem)">报警阈值修改</a>
        </p>
        <p :title="dioDetailsItem.name" @click="Copy(dioDetailsItem.name,1)"
          style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow-y: hidden;max-height: 40px;cursor: pointer;">
          电梯名称：{{dioDetailsItem.name}}
        </p>
        <p>报警时间：{{dioDetailsItem.time}}</p>
        <p>报警楼层：{{dioDetailsItem.floor}}</p>
        <p>摄像头ID：{{dioDetailsItem.code}}</p>
        <p :title="dioDetailsItem.plotName" @click="Copy(dioDetailsItem.plotName,1)"
          style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow-y: hidden;height: 22px;cursor: pointer;">
          小区名称：{{dioDetailsItem.plotName}}
        </p>
        <p>维保单位：{{dioDetailsItem.maintainUnitName}}</p>
        <div style="display: flex;margin-top: -15px;align-items: flex-start;max-height: 80px;">
          <p style="word-break: keep-all;">维保人员：</p>
          <div v-if="dioDetailsItem.maintainUser.split(';').length > 0">
            <!-- {{dioDetailsItem.maintainUser.split(';')}} -->
            <div v-for="(item,index) in dioDetailsItem.maintainUser.split(';')" style="max-width: 270px;">
              <p v-if="index < 2">
                {{item}}
                <i class="el-icon-document-copy copyInfo" @click="Copy(item,2)"></i>
              </p>
            </div>
          </div>
        </div>
        <div style="display: flex;margin-top: -15px;align-items: flex-start;max-height: 60px;">
          <p style="word-break: keep-all;">物业人员：</p>
          <div v-if="dioDetailsItem.propertyUser.split(';').length > 0">
            <div v-for="(item,index) in dioDetailsItem.propertyUser.split(';')" style="max-width: 270px;">
              <p v-if="index < 2">
                {{item}}
                <i class="el-icon-document-copy copyInfo" @click="Copy(item,2)"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Action v-if="dialogVisible" :initData="initData" ref="acitonDtzl" @closeActione="closeActione"
      @updata="closeActione" />
    <!-- {{dioDetailsItem}} -->
    <!-- getladder -->
    <!-- {{ladderDate}} -->
    <el-image fit="contain" v-if="ladderDate == 1" title="取消阻梯" :src="require(`@/assets/notzuti.png`)" @click="ladder"
      style="height: 40px; width: 40px; margin-left: 10px; cursor: pointer;float: left;margin-top: 20px;position:absolute" />
    <!-- {{dioDetailsItem}} -->
    <div class="conBox">
      <!-- <el-button></el-button> -->
      <!-- <el-button :disabled='isDial' @click="dialZlv" title="拨打" type="success" icon="el-icon-microphone">拨打</el-button> -->
      <!-- <el-button v-if="false" :title="isLadder ? '阻梯' : ''" type="warning" :loading="true"
        circle></el-button> -->

      <!-- <el-image v-if="isLadder != -1" fit="contain" :title="type == 1 ? '取消阻梯' : '阻梯'"
        :src="type == 1 ? require(`@/assets/notzuti.png`) : require(`@/assets/zuti.png`)" @click="ladder"
        style="height: 40px; width: 40px; margin-left: 10px; cursor: pointer;float: left;" /> -->
      <div style="margin-right: 10px;display: flex; flex-direction: column; align-items: center;">
        <el-button size="mini" @click="dropped" type="danger">
          <i class="el-icon-turn-off-microphone"></i>
          <p style="margin: 10px 0 0 0;">挂断</p>
        </el-button>
        <p v-if="callNum != '00:00'" style="color:#67c23a;">{{callNum}}</p>
      </div>
      <template v-if="
      	dioDetailsItem.type == 95 ||
      	dioDetailsItem.type == 0 ||
      	dioDetailsItem.type == 10 ||
      	dioDetailsItem.type == 7 ||
      	dioDetailsItem.type == 3 ||
      	dioDetailsItem.type == 333 ||
      	dioDetailsItem.type == 4 ||
      	dioDetailsItem.type == 5 ||
      	dioDetailsItem.type == 11 ||
      	dioDetailsItem.type == 12 ||
      	dioDetailsItem.type == 13 ||
        dioDetailsItem.type == 96 ||
        dioDetailsItem.type == 1 ||
        dioDetailsItem.type == 2 ||
        dioDetailsItem.type == 14 ||
        dioDetailsItem.type == 999 ||
        dioDetailsItem.type == 21 ||
        dioDetailsItem.type == 20 ||
        dioDetailsItem.type == 19 ||
        dioDetailsItem.type == 109 ||
        dioDetailsItem.type == 22 ||
        dioDetailsItem.type == 97 ||
        dioDetailsItem.type == 35
       ">
        <el-button v-if="dioDetailsItem.type != 0&&dioDetailsItem.type != 5" :disabled='sosStatus' :loading="btnLoading" size="mini"
          type="primary" @click="sosClick(6)">
          <span>确认</span>
          <p style="margin: 10px 0 0 0;">正常报警非误报</p>
        </el-button>
        <el-button type="primary" size="mini" v-if="dioDetailsItem.type == 5 " @click="sosClick(7)"
          :disabled='sosStatus' :loading="btnLoading">
          <span>长时逗留</span>
          <p style="margin: 10px 0 0 0;">无困人非误报</p>
        </el-button>
        <el-button type="primary" size="mini" v-if="dioDetailsItem.type == 0" @click="sosClick(8)" :disabled='sosStatus'
          :loading="btnLoading">
          <span>乘客误按</span>
          <p style="margin: 10px 0 0 0;">无困人非误报</p>
        </el-button>
        <el-button size="mini" type="info" v-if="dioDetailsItem.type != 13&&dioDetailsItem.type != 35&&dioDetailsItem.type != 22&& dioDetailsItem.type != 21 &&dioDetailsItem.type != 21
          " @click="sosClick(2)" :disabled='sosStatus' :loading="btnLoading">
          <span>电梯故障(无困人)</span>
          <p style="margin: 10px 0 0 0;">派发维修工单</p>
        </el-button>
        <el-button size="mini" type="warning"
          v-if="(dioDetailsItem.type == 0 || dioDetailsItem.type ==5||dioDetailsItem.type ==4||dioDetailsItem.type ==7||
          dioDetailsItem.type ==3 || dioDetailsItem.type == 333 || dioDetailsItem.type ==11||dioDetailsItem.type ==12) && is24Hours(dioDetailsItem)"
          @click="sosClick(3)" :disabled='sosStatus' :loading="btnLoading">
          <span>困人</span>
          <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
        </el-button>
        <el-button class="errorSos" size="mini" @click="sosClick(5)" :disabled='sosStatus' :loading="btnLoading">
          <span>误报</span>
          <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
        </el-button>
      </template>
      <!-- <el-button :disabled='sosStatus' size="mini" type="primary" @click="sosClick(1)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 109&&dioDetailsItem.type != 15&&dioDetailsItem.type != 13&&dioDetailsItem.type != 98">
        <span>人为触发(无故障)</span>
        <p style="margin: 10px 0 0 0;">确认关闭</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="primary" @click="sosClick(6)" :loading="btnLoading"
        v-if="dioDetailsItem.type == 13">
        <span>确认</span>
        <p style="margin: 10px 0 0 0;">正常报警非误报</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="info" @click="sosClick(2)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 13&&dioDetailsItem.type != 22&&dioDetailsItem.type != 98&&dioDetailsItem.type != 21">
        <span>电梯故障(无困人)</span>
        <p style="margin: 10px 0 0 0;">派发维修工单</p>
      </el-button>
      <el-button :disabled='sosStatus' size="mini" type="warning" @click="sosClick(3)" :loading="btnLoading"
        v-if="dioDetailsItem.type != 22&&dioDetailsItem.type != 109&& dioDetailsItem.type != 21 &&dioDetailsItem.type != 19 &&dioDetailsItem.type != 20 && dioDetailsItem.type != 15&&dioDetailsItem.type != 13&& dioDetailsItem.type != 96">
        <span>困人</span>
        <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
      </el-button>
      <el-button size="mini" class="errorSos" @click="sosClick(5)" :loading="btnLoading" :disabled="sosStatus"
        v-if="dioDetailsItem.type != 98">
        <span>误报</span>
        <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
      </el-button> -->
      <!-- <el-button  @click="dialZlv" title="关闭" type="info" icon="el-icon-microphone">关闭</el-button> -->
    </div>
  </div>
</template>

<script>
  import Action from '../views/dtsj/dtzl/components/action.vue'
  import {
    formatDate
  } from "@/util";
  let one = true
  export default {
    props: ['jkData', 'sosId', 'alarmTime', 'percentage', 'videoStatusId', 'ids', 'dioDetailsItem', 'isHaveVideo'],
    components: {
      Action
    },
    data() {
      return {
        timer: null,
        timeCount: null,
        ladderDate: 2,
        // 监控
        player: null,
        loading: true,
        timeWebRtc: null,
        // 通话
        isDial: false,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isMuted: true,
        meData: [],
        meObj: null,
        sosStatus: false,
        playTime: 0, // 通话时间
        playWebrtc: '',
        btnLoading: false,
        meplayer: null,
        timers: null,
        PUrl: [],
        typeDate: [],
        playNum: 0,
        dialogVisible: false,
        initData: {},
        timeOutType: null,


        CallTimer: null,
        callNum: '00:00',
      }
    },
    created() {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item) => {
              if (this.adminInfo.userName != 'admin') {
                return item.value != '21' && item.value != '35' && item.value != '22'
              } else {
                return item
              }
            })
            this.typeDate = Arr
          }
        })
    },
    mounted() {
      this.PUrl = []
      // if (this.dioDetailsItem.type == 98) {
      //   setTimeout(() => {
      //     this.checkList()
      //     this.vidieoList(1)
      //   }, 1000)
      // } else {
      let start = 30
      this.timeCount = setInterval(() => {
        start--
        if (start > -1) {
          if (this.timer == '失败' || this.timer == null) {
            if (start < 1) {
              this.getTimes(2)
            }
          } else {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        } else {
          if (this.timer == '成功') {
            this.getTimes(1)
            clearInterval(this.timeCount)
          }
        }
      }, 1000)
      setTimeout(() => {
        this.getladder()
        this.init()
        this.dialZlv()
      }, 1000)
      this.timeOutType = setInterval(() => {
        this.ladderDate = JSON.parse(sessionStorage.getItem('isLadder'))
      }, 1000)

      this.$bus.$on("hello", (data) => {
        console.log(data, 'data111111');
      })

    },
    beforeDestroy() {
      // if (this.dioDetailsItem.type != 98) {
      let agentData = {
        act: 'ma_stop_webrtc_call',
        sos_room_id: this.jkData.device_id,
      }
      this.socketApi.sendSock(agentData, (e) => {})
      one = true
      if (this.meplayer) {
        this.meplayer.close()
        this.meplayer = null
        var local = document.getElementById(this.meObj)
        if (local) {
          local.srcObject = null
          local.load()
        }
      }
      this.stop()
      clearTimeout(this.timeOutType)
      // } else {
      //   if (this.meplayer) {
      //     this.meplayer.close()
      //     this.meplayer = null
      //     var local = document.getElementById(this.meObj)
      //     if (local) {
      //       local.srcObject = null
      //       local.load()
      //     }
      //   }
      //   this.PUrl = []
      //   this.stop()
      //   this.dropped()
      // }
    },

    methods: {
      is24Hours(data) {
        let diff = ''
        const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
        console.log(data.lastAlarmTime,data.sosTime, '最后一次报警时间')
        if (data.lastAlarmTime) {
          const lastAlarmDate = new Date(data.lastAlarmTime); // 假设lastAlarmTime是字符串或者Date对象
          const now = new Date();
          diff = now - lastAlarmDate;
        } else if(data.sosTime) {
          const lastAlarmDate = new Date(data.sosTime); // 假设lastAlarmTime是字符串或者Date对象
          const now = new Date();
          diff = now - lastAlarmDate;
        }
        console.log('现在距离报警时间是否超过24小时',diff > oneDay)
        return diff < oneDay;
      },
      closeActione(value) {
        this.dialogVisible = false
      },
      changeValue(item) {
        this.dialogVisible = true
        this.$http
          .get(`/api/ele/web/elevatorInfo/detail?id=${item.eleId}`)
          .then((res) => {
            if (res.data.success) {
              const data = res.data.data
              data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
                JSON.parse(res.data.data.deviceSosSwitch) : []
              data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
                JSON.parse(res.data.data.sosSwitchStatus) : []
              data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
                JSON.parse(res.data.data.terminalSosSwitch) : []
              data.areaCodeArr = res.data.data.areaCodes ?
                JSON.parse(res.data.data.areaCodes) : []
              data.simStartTime = res.data.data.simStartTime ? new Date(res.data.data.simStartTime) : ''
              data.simEndTime = res.data.data.simEndTime ? new Date(res.data.data.simEndTime) : ''
              data.firstName = 'fifth'
              this.initData = data
              this.$nextTick(() => {
                this.$refs.acitonDtzl.init('edit', data, () => {
                  // this.onRefer()
                })
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      useCopyToClipboard(val) {
        let isSuccessRef = false;
        //创建input标签
        const input = document.createElement('input')
        //将input的值设置为需要复制的内容
        input.setAttribute('readonly', 'readonly')
        input.setAttribute('value', val)
        //添加input标签
        document.body.appendChild(input)
        //选中input标签
        input.select()
        //执行复制
        document.execCommand("copy");
        if (document.execCommand("copy")) {
          isSuccessRef = true;
        } else {
          isSuccessRef = false;
        }
        //移除input标签
        document.body.removeChild(input);

        return {
          isSuccessRef
        };
      },
      Copy(str, type) { //复制
        let data = ''
        if (type == 1) {
          data = str
        } else {
          const one = str.split('(')[1]
          data = one.split(')')[0]
        }
        let {
          isSuccessRef
        } = this.useCopyToClipboard(data)
        if (isSuccessRef) {
          this.$message({
            type: 'success',
            message: '复制成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: '复制失败'
          })
        }
      },
      // 详情操作按钮
      sosClick(type) {
        // console.log(this.dioDetailsItem.time, this.alarmTime, 'dioDetailsItem66')
        if (type != 4) {
          this.btnLoading = true
          this.sosStatus = true
        }
        let url = type == 4 ? '/api/ele/web/sos/callSos' : '/api/ele/app/sos/submit'
        this.$http
          .post(url, {
            id: this.sosId,
            submitType: type,
            sosTime: this.alarmTime ? this.alarmTime : formatDate(this.dioDetailsItem.sosTime, "yyyy-MM-dd HH:mm:ss")
          })
          .then((res) => {
            if (type != 4) {
              if (res.data.success) {
                if (type == 5) {
                  this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                    type: 'warning',
                  }).then(() => {
                    this.$http
                      .post("/api/ele/web/sos/removeSosInfo", {
                        id: this.sosId,
                        sosTime: this.alarmTime ? this.alarmTime : formatDate(this.dioDetailsItem.sosTime,
                          "yyyy-MM-dd HH:mm:ss")
                      }).then((res) => {
                        if (res.data.code == 200) {
                          this.$message.success(res.data.msg)
                        } else {
                          this.$message.error(res.data.msg)
                        }
                      })
                  }).catch(() => {})
                } else {
                  this.$message.success(res.data.msg)
                }
                // this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            }
            this.btnLoading = false
          })
      },
      // 拨打
      meInit(data) {
        if (this.meplayer) {
          this.meplayer.close()
          this.meplayer = null
        }
        var local = document.getElementById(data.session_id)
        if (local) {
          local.load()
          local.srcObject = null
        }
        const jswebrtc = document.getElementById(data.session_id)
        const that = this
        that.meplayer = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: data.url, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: true,
          videoEnable: false,
          recvOnly: data.recvType, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480,
          },
        })

        if (one) {
          one = false
          that.sosClick(4)
        }
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
          function(e) {
            // ICE 协商出错
            console.log('ICE 协商出错')
          }
        )
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
          function(e) {
            //获取到了远端流，可以播放
            that.timer = '成功'
            console.log('拨打成功', e.streams)
            that.loading = false

            // if (that.dioDetailsItem.type == 98) {
            //   that.timers = setInterval(() => {
            //     that.vidieoList()
            //   }, 3000)
            // }
          }
        )
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
          function(e) {
            that.timer = '失败'
            // offer anwser 交换失败
            console.log('offer anwser 交换失败', e)
          }
        )
        that.meplayer.on(
          ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,
          function(s) {
            // 获取到了本地流
            jswebrtc.srcObject = s
            jswebrtc.muted = true
            that.timer = '成功'
            console.log('获取本地流成功')
            console.log(that.dioDetailsItem.type, 'that.dioDetailsItem.type');
            that.updateClock()
            // that.updateClock()
            // that.playWebrtc = setInterval(() => {
            //   that.playTime = that.playTime + 1
            // }, 1000)
          }
        )
        that.meplayer.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
          // 获取本地流失败
          that.timer = '失败'
          console.log('获取本地流失败2')
        })

      },
      check() {
        var that = this
        console.log(that.PUrl, 'check')
        const jswebrtc = document.getElementById('onPlayJkSos')
        let playUrl = that.PUrl[0].url
        this.player = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: playUrl, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: false,
          videoEnable: false,
          recvOnly: true, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480,
          },
        })
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
          function(e) {
            // ICE 协商出错
            console.log('ICE 协商出错')
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
          function(e) {
            //获取到了远端流，可以播放
            that.timer = '成功'
            console.log('播放成功', e.streams)
            clearInterval(that.timeWebRtc)
            that.$emit('changePercentage', 100)
            that.loading = false
            if (that.dioDetailsItem.type == 98) {
              // that.checkList()
            }
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
          function(e) {
            that.timer = '失败'
            // offer anwser 交换失败
            console.log('offer anwser 交换失败', e)
          }
        )
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) {
          // 获取到了本地流
          jswebrtc.srcObject = s
          jswebrtc.muted = false
          this.timer = '成功'
          console.log('获取本地流成功1')
          if (this.dioDetailsItem.type == 98) {
            // this.checkList()
          }
        })
        this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
          // 获取本地流失败
          that.timer = '失败'
          console.log('获取本地流失败1')
        })
      },

      // 通话时长
      updateClock() {
        console.log('通话时长');
        let seconds = 0;
        this.CallTimer = setInterval(() => {
          seconds++;
          const minutes = Math.floor(seconds / 60);
          const secs = seconds % 60;
          if (secs == 59 && minutes == 59) {
            clearInterval(this.CallTimer)
          } else {
            this.callNum = `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
          }
        }, 1000);
      },
      dialZlv() {
        let agentData = {
          type: 2,
          act: 'ma_webrtc_call',
          sos_room_id: this.jkData.device_id,
          token: sessionStorage.getItem('AdminToken'),
        }
        if (sessionStorage.getItem('me_id')) {
          let se_id = JSON.parse(sessionStorage.getItem('me_id')).sessionId
          this.socketApi.sendSock(agentData, (e) => {
            // one 只执行一次
            if (e.cmd == 'ma_webrtc_call' && e.room_info != undefined && one) {
              this.isMuted = false
              e.room_info.members.forEach((qs, ind) => {
                if (ind != 0) {
                  //recvType 拉流为true 推流为false
                  let obj = {
                    url: qs.play,
                    recvType: true,
                    session_id: qs.session_id,
                  }
                  if (qs.session_id == se_id) {
                    obj.url = qs.push
                    obj.recvType = false
                    this.meData.push(obj)
                    this.meObj = qs.session_id
                  }
                  // this.meData.push(obj)
                }
              })
              this.isDial = true
              setTimeout(() => {
                this.meData.forEach((qs) => {
                  this.meInit(qs)
                })
              }, 1000)
            } else {
              clearInterval(this.timeWebRtc)
            }
          })

        } else {
          this.$message.error('您现在的网络情况不好，请刷新页面再尝试')
          return
        }
      },
      // 挂断
      dropped() {
        let webrtcList = JSON.parse(sessionStorage.getItem('webrtcCall'))
        webrtcList = webrtcList.length > 0 ? webrtcList.filter(item => item != this.jkData.device_id) : []
        sessionStorage.setItem('webrtcCall', JSON.stringify(webrtcList))
        const me_id = JSON.parse(sessionStorage.getItem('me_id'))
        // if (this.dioDetailsItem.type != 98) {
        clearInterval(this.playWebrtc)
        clearInterval(this.CallTimer)
        this.playTime = 0
        this.isMuted = true
        this.isDial = false

        let agentData = {
          act: 'ma_stop_webrtc_call',
          sos_room_id: this.jkData.device_id,
        }
        this.socketApi.sendSock(agentData, (e) => {})

        this.$emit('bclose')
        one = true
        if (this.meplayer) {
          this.meplayer.close()
          this.meplayer = null
          var local = document.getElementById(this.meObj)
          if (local) {
            local.srcObject = null
            local.load()
          }
        }
        // } else {
        //   this.$http.get(`/api/ele/web/pushWechatStopCall?sosId=${this.sosId}&sessionId=${me_id.token}`).then((
        //     res) => {
        //     if (res.data.code == 200) {
        //       clearInterval(this.playWebrtc)
        //       clearInterval(this.timers)
        //       this.playTime = 0
        //       this.isMuted = true
        //       this.isDial = false
        //       this.$emit('bclose')
        //       if (this.meplayer) {
        //         this.meplayer.close()
        //         this.meplayer = null
        //         var local = document.getElementById(this.meObj)
        //         if (local) {
        //           local.srcObject = null
        //           local.load()
        //         }
        //       }
        //     } else {
        //       this.$message(res.data.msg)
        //     }
        //   })
        // }
      },
      //获取阻梯详情
      getladder() {
        let agentData = {
          act: 'ma_get_ele_detail',
          id: this.dioDetailsItem.eleId,
        }
        this.socketApi.sendSock(agentData, (e) => {

          if (e.status) {
            if (e.cmd == 'ma_get_ele_detail') {
              const data = e.data ? JSON.parse(e.data) : {}
              this.ladderDate = data.DeviceData ? data.DeviceData.zuTiStatus : 2
            }
            console.log(this.ladderDate, '阻梯详情')

            //   if (e.hb) {
            //     this.$emit('changePercentage', 25)
            //   }
            //   if (e.cmd == 'ca_start_rtsp') {
            //     this.$emit('changePercentage', 50)
            //     this.getStreamStatus()

            //   }
            //   // this.init()
            // }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      // 阻梯
      ladder() {
        let agentData = {
          act: 'ma_change_ele_status',
          deviceId: this.jkData.device_id,
          type: 2
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.cmd == 'ma_change_ele_status' && e.status) {
            this.$message.success(e.msg)
            this.ladderDate = 2
            clearTimeout(this.timeOutType)
            // this.type = this.type == 1 ? 2 : 1
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      // 重连
      opJk() {
        this.stop()
        if (this.dioDetailsItem.type != 98) {
          // this.stop()
          let agentData = {
            act: 'ca_start_rtsp',
            device_id: this.jkData.device_id,
          }
          this.socketApi.sendSock(agentData, (e) => {
            console.log(e, '阻梯信息')
            if (e.status) {
              // if (e.cmd == 'ca_start_rtsp') {
              //   if (e.hb) {
              //     this.$emit('changePercentage', 25)
              //   }
              //   if (e.cmd == 'ca_start_rtsp') {
              //     this.$emit('changePercentage', 50)
              //     this.getStreamStatus()

              //   }
              //   // this.init()
              // }
            } else {
              this.$message.error(e.msg)
            }
          })
        } else {
          // this.timers = setInterval(() => {
          this.vidieoList()
          // }, 3000)
        }
      },

      getStreamStatus() {
        this.$http
          .get(`api/ele/web/getStreamStatus?id=${this.jkData.device_id}`)
          .then((res) => {
            if (res.data.success) {
              this.$emit('changePercentage', res.data.data ? 75 : 50)
              this.init()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      //关闭监控
      stop() {
        clearInterval(this.timeWebRtc)
        if (this.player) {
          this.player.close()
          this.player = null
          var local = document.getElementById('onPlayJkSos')
          if (local) {
            local.srcObject = null
            local.load()
          }
        }
      },
      //更新视频时间
      getTimes(type) {
        const eleId = this.videoStatusId ?? this.ids
        const time = new Date(new Date()).getTime()
        // if (this.videoStatusId) {
        this.$http.post(
          `/api/ele/web/eleVideo/updateElevatorVideoStatus?id=${eleId}&videoTime=${time}&videoStatus=${type}`
        ).then((res) => {
          this.$emit('update', time, type)
        })
        // } else {
        //   this.$http.post(
        //     `/api/ele/web/eleVideo/updateElevatorVideoStatus?elevatorId=${this.ids}&videoTime=${time}&videoStatus=${type}`
        //   ).then((res) => {
        //     this.$emit('update', time, type)
        //     console.log(res, 'res')
        //   })
        // }
      },
      init() {
        const that = this
        const jswebrtc = document.getElementById('onPlayJkSos')
        let openUrl = that.jkData.play_url
        let playUrl = openUrl[openUrl.length - 2]
        this.player = new ZLMRTCClient.Endpoint({
          element: jswebrtc, // video 标签
          debug: true, // 是否打印日志
          zlmsdpUrl: playUrl, //流地址
          simulcast: false,
          useCamera: false,
          audioEnable: false,
          videoEnable: false,
          recvOnly: true, //拉流为true 推流为false
          resolution: {
            w: 640,
            h: 480,
          },
        })
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
          function(e) {
            // ICE 协商出错
            console.log('ICE 协商出错')
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
          function(e) {
            //获取到了远端流，可以播放
            that.timer = '成功'
            console.log('播放成功', e.streams)
            clearInterval(that.timeWebRtc)
            that.$emit('changePercentage', 100)
            that.loading = false
          }
        )
        this.player.on(
          ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
          function(e) {
            that.timer = '失败'
            // offer anwser 交换失败
            console.log('offer anwser 交换失败', e)
          }
        )
        this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function(s) {
          // 获取到了本地流
          jswebrtc.srcObject = s
          jswebrtc.muted = false
          that.timer = '成功'
          console.log('获取本地流成功1')
        })
        this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function(s) {
          // 获取本地流失败
          that.timer = '失败'
          console.log('获取本地流失败1')
        })
      },
    },
  }
</script>

<style scoped>
  .copyInfo {
    margin-left: 3px;
    font-size: 18px;
    cursor: pointer;
  }

  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }

  .conBox {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* flex-direction: column; */
    /* width: 6%; */
    height: 100%;
    margin-top: 15px;
  }

  .span-width {
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 50px);
    font-size: 26px;
    font-weight: 600;
    color: #052e54;
    width: 100px;
    line-height: 32px;
    text-align: center;
  }

  /deep/.el-progress-bar__innerText {
    color: #fff !important;
  }
</style>
