<template>
	<div>
		<div style="height: 39vh;padding-right: 20px;display: flex;">
			<div class="TimeDetail">
				<div class="topBox">
					<div style="width: 70%;height: 100;display: flex;">
						<div v-show="tabBarTitBool==6" @click="seleTabBat(34)"
							:class="seleTab==34||seleTab==10?'seleBut':'offBut'">
							付款信息</div>
						<div v-show="tabBarTitBool==5||tabBarTitBool==6" @click="seleTabBat(5)"
							:class="seleTab==5||seleTab==30||seleTab==32?'seleBut':'offBut'">
							维修详情</div>
						<div v-show="tabBarTitBool == 4||tabBarTitBool==5||tabBarTitBool==6" @click="seleTabBat(26)"
							:class="(tabBarTitBool == 4||tabBarTitBool == 5||tabBarTitBool==6)&&seleTab!=18&&seleTab!=1&&seleTab!=5&&seleTab!=30&&seleTab!=32&&seleTab!=34&&seleTab!=10?'seleBut':'offBut'">
							{{tabBarTit1}}
						</div>
						<div v-show="tabBarTitBool==3||tabBarTitBool==2" @click="seleTabBat(3)"
							:class="(tabBarTitBool==3||tabBarTitBool==2	)&&seleTab!=18&&seleTab!=1?'seleBut':'offBut'">
							{{tabBarTit1}}
						</div>
						<div v-show="tabBarTitBool!=0" @click="seleTabBat(18)" :class="seleTab==18?'seleBut':'offBut'">
							现场勘查</div>
						<div @click="seleTabBat(1)" :class="seleTab==1?'seleBut':'offBut'">报警信息</div>
					</div>
					<!-- <div v-if="tabBarTitBool != 0&&tabBarTitBool != 1&&tabBarTitBool != 2" class="leftTit">
						{{tabLineTit}}
					</div> -->
				</div>
				<!-- 内容块 -->
				<div class="msgBox">
					<!-- <div :class="tabBarTitBool == 0||tabBarTitBool == 1||tabBarTitBool == 2?'':'leftBox2'" -->
					<div class="scrollElement leftBox1">
						<scenePsg v-if="seleTab!=1&&seleTimeline&&dioDetailsItem2!=null" :dioData="dioDetailsItem2"
							:seleTabOn="seleTab" />
						<alarmMessage v-if="seleTab==1&&dioDetailsItem1!=null" :dioData="dioDetailsItem1" />
					</div>
				</div>
			</div>
			<div class="TimelineBox scrollElement" style="overflow-y: scroll;">
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
						:type="activity.type" :color="activity.color" :size="activity.size"
						:timestamp="activity.timestamp" @click.native="setTimeLineItem(activity.id,index)">
						{{activity.content}}
					</el-timeline-item>
				</el-timeline>
			</div>
		</div>
	</div>
</template>
<script>
	import scenePsg from './components/scenePsg.vue'
	import alarmMessage from './components/alarmMessage'
	export default {
		props: ['dioDatas'],
		components: {
			scenePsg,
			alarmMessage
		},
		data() {
			return {
				staData: [],
				fromItem: {},
				activLine1: [],
				activLine2: [],
				activLine3: [],
				//
				seleTab: 1,
				tabBarTit1: '',
				tabLineTit: '',
				tabBarTitBool: 0,
				seleTimeline: false,
				//
				activeName: 'third',
				// 时间线
				actSeleDate: [],
				activities: [],
				dioDetailsItem1: null,
				dioDetailsItem2: null,
				// 合同类型
				contractType: [{
						label: '维修理赔',
						value: 'SAFETY_INSURANCE',
					},
					{
						label: '维保理赔',
						value: 'ACCIDENT_INSURANCE',
					},
				],
				tableData: [],
				//
				old_index: -1,
				old_color: ''
			}
		},
		methods: {
			check(item) {
				this.$http.get(`/api/ele/web/failureTask/taskDetail?id=${item.id}`).then((res) => {
					if (res.data.success) {
						this.taskDate = {
							taskStatus: res.data.data.taskStatus,
							taskId: item.id
						}
						this.$http.post('/api/ele/app/failureTask/detail', this.taskDate).then((res1) => {
							if (res1.data.success) {
								this.actSeleDate = res1.data.data
								res.data.data.status = 1
								res.data.data.taskId = item.id
								this.dioDetailsItem1 = res.data.data
								this.activities = [{
									id: 1,
									content: '发生故障',
									timestamp: '',
									color: '#3c93ed'
								}, {
									id: 18,
									content: '现场勘查',
									timestamp: '维保单位对故障现场进行勘查',
									color: '#e4e7ed'
								}, {
									id: 3,
									content: '制定维修方案',
									timestamp: '维保单位填写故障维修方案',
									color: '#e4e7ed'
								}, {
									id: 24,
									content: '维修会审',
									timestamp: '物业方和保险方会审方案',
									color: '#e4e7ed'
								}, {
									id: 26,
									content: '制定报价方案',
									timestamp: '维保单位填写维修报价方案',
									color: '#e4e7ed'
								}, {
									id: 28,
									content: '故障定损',
									timestamp: '保险方对维修进行定损',
									color: '#e4e7ed'
								}, {
									id: 4,
									content: '开始维修',
									timestamp: '维保方对设备进行维修',
									color: '#e4e7ed'
								}, {
									id: 5,
									content: '维修完成',
									timestamp: '维保方对设备完成维修',
									color: '#e4e7ed'
								}, {
									id: 5,
									content: '物业确认',
									timestamp: '物业方对维修结果确认',
									color: '#e4e7ed'
								// }, {
								// 	id: 30,
								// 	content: '平台确认',
								// 	timestamp: '平台方对维修结果确认',
								// 	color: '#e4e7ed'
								}, {
									id: 32,
									content: '结算审核',
									timestamp: '保险方对维修票据进行确认',
									color: '#e4e7ed'
								}, {
									id: 34,
									content: '付款确认',
									timestamp: '保险方完成付款后进行确认',
									color: '#e4e7ed'
								}, {
									id: 10,
									content: '归档',
									timestamp: '对故障单进行归档',
									color: '#e4e7ed'
								}]
								this.activities[0].timestamp = res.data.data.failureTime
								this.staData = []

								this.activLine1 = [{
									id: 22,
									content: '物业方',
									timestamp: [],
									color: '#e4e7ed'
								// }, {
								// 	id: 24,
								// 	content: '平台方',
								// 	timestamp: [],
								// 	color: '#e4e7ed'
								}, {
									id: 26,
									content: '保险方',
									timestamp: [],
									color: '#e4e7ed'
								}]

								this.activLine2 = [{
									id: 30,
									content: '物业方',
									timestamp: [],
									color: '#e4e7ed'
								}, {
									id: 34,
									content: '保险方',
									timestamp: [],
									color: '#e4e7ed'
								}]

								this.activLine3 = [{
									id: 30,
									content: '物业方',
									timestamp: [],
									color: '#e4e7ed'
								// }, {
								// 	id: 32,
								// 	content: '平台方',
								// 	timestamp: [],
								// 	color: '#e4e7ed'
								}, {
									id: 34,
									content: '保险方',
									timestamp: [],
									color: '#e4e7ed'
								}]

								res1.data.data.forEach((qs) => {
									this.staData.push(qs.status)
									if (qs.status == 18) {
										this.dioDetailsItem2 = qs
									} else if (qs.status == 22) {
										this.activLine1[0].timestamp = qs
										this.activLine1[0].color = '#3c93ed'
									} else if (qs.status == 24) {
										this.activLine1[1].timestamp = qs
										this.activLine1[1].color = '#3c93ed'
									} else if (qs.status == 26) {
										this.activLine1[1].timestamp = qs
										this.activLine1[1].color = '#3c93ed'
									} else if (qs.status == 30) {
										this.activLine2[0].timestamp = qs
										this.activLine2[0].color = '#3c93ed'

										this.activLine3[0].timestamp = qs
										this.activLine3[0].color = '#3c93ed'
									} else if (qs.status == 34) {
										this.activLine2[1].timestamp = qs
										this.activLine2[1].color = '#3c93ed'


										this.activLine3[1].timestamp = qs
										this.activLine3[1].color = '#3c93ed'

									} else if (qs.status == 32) {
										this.activLine3[1].timestamp = qs
										this.activLine3[1].color = '#3c93ed'
									}
								})
								this.activities.forEach((qs) => {
									if (this.staData.indexOf(qs.id) > -1) {
										qs.color = '#3c93ed'
									}
								})
								this.$http
									.post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
									.then((res) => {
										if (res.data.success) {
											res.data.data.SOS_TYPE.forEach((qs) => {
												if (Number(qs.value) == this.dioDetailsItem1.sosType) {
													this.dioDetailsItem1.sosTypeName = this.dioDetailsItem1.sosId ? qs.label : '自主申报'
													this.seleTab = 1
													this.fromItem = {}
													this.tabBarTit1 = '报价方案'
													this.tabLineTit = '报价审核意见'
													this.tabBarTitBool = 0
													this.seleTimeline = false
													this.dialogVisible = true
												}
											})
										}
									})
							} else {
								this.$message.error(res1.data.msg)
							}
						})
					} else {
						this.$message.error(res.data.msg)
					}
				})

			},
			operates(type) {
				let data = {
					taskId: this.dioDetailsItem1.taskId,
					updateTime: this.dioDetailsItem1.updateTime,
					data: {
						desc: type == 23 || type == 22 ? this.fromItem.desc1 : this.fromItem.desc2
					},
					status: type
				}
				this.$http
					.post("/api/ele/app/failureTask/submit", data)
					.then((res) => {
						if (res.data.code === 200) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			setTab(type) {
				if (type == 24) {
					type = 3
				} else if (type == 26 || type == 28 || type == 4) {
					type = 27
				} else if (type == 30 || type == 32) {
					type = 5
				} else if (type == 34) {
					type = 10
				}
				this.seleTimeline = false
				let data = {
					status: type,
					taskId: this.dioDetailsItem1.taskId,
				}
				this.$http.post('/api/ele/app/failureTask/detail', data).then((res) => {
					if (res.data.success) {
						this.dioDetailsItem2 = {
							data: {}
						}
						if (res.data.data.length > 0) {
							this.dioDetailsItem2 = res.data.data.pop()
              // res.data.data[0]
						}
						this.$nextTick(() => {
							this.seleTimeline = true
						})
					}
				})
			},
			seleTabBat(type) {
				this.seleTab = type
				this.setTab(type)
			},
			// 获取时间节点
			setTimeLineItem(activity, index) {

				if (this.old_index != -1) {
					this.activities[this.old_index].color = this.old_color
				}
				this.old_index = index
				this.old_color = this.activities[index].color
				this.activities[index].color = '#c5121c'

				this.seleTab = activity
				if (activity == 1) {
					this.tabBarTitBool = 0
					return
				} else if (activity == 18) {
					this.tabBarTitBool = 1
				} else if (activity == 3) {
					this.tabBarTitBool = 2
					this.tabBarTit1 = '维修方案'
				} else if (activity == 24) {
					this.tabBarTitBool = 3
					this.tabBarTit1 = '维修方案'
					this.tabLineTit = '会审意见'
				} else if (activity == 26 || activity == 27 || activity == 28 || activity == 4) {
					this.tabBarTitBool = 4
					this.tabBarTit1 = '报价方案'
					this.tabLineTit = '报价审核意见'
				} else if (activity == 5 || activity == 30 || activity == 32) {
					this.tabBarTitBool = 5
					this.tabLineTit = '报价审核意见'
				} else if (activity == 34 || activity == 10) {
					this.tabBarTitBool = 6
					this.tabLineTit = '报价审核意见'
				}
				this.setTab(activity)
			},
		},
		created() {
			// this.check(this.dioDatas)
		},
		mounted() {
			this.$nextTick(() => {
				this.check(this.dioDatas)
			})
		},
	}
</script>
<style lang="scss" scoped>
	.descSty {
		height: 35%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.botBox {
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.leftTit {
		width: 30%;
		height: 100;
		color: white;
		display: flex;
		align-items: center;
	}

	.committee {
		width: 100%;
		height: 90%;
	}

	.msgBox {
		width: 100%;
		display: flex;
		height: 100%;
	}

	.leftBox1 {
		width: 100%;
		height: 95%;
		overflow-y: scroll;
	}

	.leftBox2 {
		width: 70%;
		height: 95%;
	}

	.rightBox {
		width: 30%;
		height: 100%;
	}

	.topBox {
		width: 100%;
		height: 50px;
		display: flex;
	}

	.seleBut {
		width: 60px;
		height: 80%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #00F6FF;
		margin-right: 20px;
		cursor: pointer;
	}

	// .offBut {
	// 	width: 60px;
	// 	height: 80%;
	// 	color: white;
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	margin-right: 20px;
	// 	cursor: pointer;
	// }

	.TimeDetail {
		width: 70%;
		height: 100%;
		margin-right: 2%;
	}

	.TimelineBox {
		width: 28%;
		height: 100%;
	}

	.searchBtn {
		margin-left: 15px;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.txtColor1 {
		color: #409eff;
	}

	.txtColor2 {
		color: #67c23a;
	}

	.txtColor3 {
		color: #f56c6c;
	}
</style>
