// 安全帽
var isheart = false;
var websockCaps = null;
var sosCapsPrompt = [];
var onlineDevice = [];
var global_callback = {}

let cuIP = window.location.host
let curIP = window.location.hostname

function initCapsSocket() { //初始化weosocket
	//ws地址
	var wsuri
	if (curIP == 'localhost' || curIP.indexOf('192.168.') !== -1) {
		// wsuri = "wss://tlw-test.runde.pro/caps"; //测试服，第二版
		// wsuri = "wss://tlwtest.zyzntech.com/cap"; //正式测试服，第二版

		// wsuri = "wss://tlw-dev.runde.pro/cap"; //开发测试服，第二版
		wsuri = "wss://tlw-test.runde.pro/caps"; //测试测试服，第二版
		// wsuri = "wss://tt.zhongzexin.pro/caps"; //测试测试服，第二版
		// wsuri = "wss://zxtlw.zyzntech.com/caps"; //中信测试服，第二版
	} else {
		wsuri = "wss://" + cuIP + "/caps"; //freeswitch通话
	}

	websockCaps = new WebSocket(wsuri);
	websockCaps.onmessage = function(e) {
		websocketonmessage(e);
	}
	websockCaps.onclose = function(e) {
		console.log("capsSocket死掉了");
		websocketclose(e);
	}
	websockCaps.onopen = function() {
		websocketOpen();
	}
	//连接发生错误的回调方法
	websockCaps.onerror = function() {
		// stopCapsHeartBeat()
		// reconnect()
		console.log("capsSocket连接发生错误");
	}
}

function reconnect() {
	setTimeout(function() { //没连接上会一直重连，设置延迟避免请求过多
		initCapsSocket()
	}, 4000);
}

// 心跳
function heartBeat() {
	var agentData = {
		"act": "1"
	};
	if (!isheart) {
		return;
	}
	setTimeout(function() {
		sendCapsSock(agentData)
	}, 30 * 1000);
}

// 清空push_sos
function clearCapsSos(e) {
	sosCapsPrompt = []
}

//报警数据接收
function websocketonmessage(e) {
	let data = JSON.parse(e.data)
	if (data.cmd == 'cap_push_sos') {
		//sos报警
		sosCapsPrompt.push(data)
		console.log('sosCapsPrompt', sosCapsPrompt)
	} else if (data.cmd == '1') {
		// 获取在线设备
		// onlineDevice = e.data ? e.data : []

		if (isheart) {
			heartBeat();
		}
	} else if (data.hb == 'ma_set_sip_info') {
		global_callback[data.hb](data)
	} else if (data.cmd != undefined) {
		global_callback[data.cmd](data)
	}

}
//数据发送
function sendCapsSock(agentData, callback) {
	if (navigator.onLine && isheart || agentData.act == "ma_login") {
		if (agentData.act != '1') {
			global_callback[agentData.act] = callback
			if (agentData.act == 'ma_set_sip_info') {
				global_callback['ma_set_sip_info'] = callback
			}
		}
		if (websockCaps.readyState === websockCaps.OPEN) {
			//若是ws开启状态
			websockCaps.send(JSON.stringify(agentData));
		} else {
			// 若未开启 ，则等待1s后重新调用
			setTimeout(function() {
				websockCaps.send(JSON.stringify(agentData));
			}, 2 * 1000);
		}
	} else {
		console.log('网络或长连接之类的出现问题了', agentData)
	}



}

function websocketclose(e) {
	stopCapsHeartBeat()
	console.log("connection closed (" + e.code + ")");
}

function websocketOpen(e) {
	isheart = true
	heartBeat();
	console.log("连接成功");
}

//页面实际调用的方法
// 结束心跳
function stopCapsHeartBeat() {
	isheart = false;
	// sessionStorage.removeItem('me_id')
	websockCaps.close()
	console.log('socket结束心跳', websockCaps)
}

export {
	isheart,
	websockCaps,
	clearCapsSos,
	sendCapsSock,
	onlineDevice,
	sosCapsPrompt,
	// 结束心跳
	stopCapsHeartBeat,
	initCapsSocket,
}
