<template>
  <div :class="className" :style="{width:width, height:height,margin:'0 auto'}" />
</template>

<script>
  import echarts from 'echarts'

  export default {
    props: {
      className: {
        type: String,
        default: 'chart',
      },
      width: {
        type: String,
        default: '100%',
      },
      height: {
        type: String,
        // default: 'calc(100% - 30px)'
        default: '650px',
      },
      autoResize: {
        type: Boolean,
        default: true,
      },
      sosLineData: {
        type: Object,
        default: () => {
          return {
            // activeAlarm: [0, 0, 0, 0, 0, 0, 0], // 主动报警
            // voiceAlarm: [0, 0, 0, 0, 0, 0, 0], // 语音报警
            // peopleAlarm: [0, 0, 0, 0, 0, 0, 0], // 困人报警
            // date: [0, 0, 0, 0, 0, 0, 0], // 日期

            rescueNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0
            ], // 救援数据
            failureNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0
            ], // 故障数据
            maintainNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0
            ], // 维保数据
            claimNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0
            ], // 理赔数据
            sosNumList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0
            ], // 报警数据
            dateList: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0
            ], // 日期
          }
        },
      },
    },

    data() {
      return {
        chart: null,
      }
    },

    // 监听data里面对应的
    watch: {
      data: {
        deep: true,
        handler(val) {
          this.setOptions(val)
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart()
      })
    },

    beforeDestroy() {
      if (!this.chart) return
      // 释放图表实例
      this.chart.dispose()
      this.chart = null
    },

    methods: {
      initChart() {
        this.chart = this.$echarts.init(this.$el)
        this.setOptions(this.sosLineData)
      },
      setOptions(data) {
        const {
          dateList,
          rescueNumList,
          failureNumList,
          maintainNumList,
          claimNumList,
          sosNumList
        } = data

        const options = {
          tooltip: {
            trigger: 'item',
            // formatter: (params) => {
            //   let value = ``
            //   let type = ''
            //   params.map((item) => {
            //     switch (item.seriesName) {
            //       case '主动报警':
            //         type = '次'
            //         break
            //       case '语音报警':
            //         type = '次'
            //         break
            //       case '困人报警':
            //         type = '次'
            //         break

            //       default:
            //         break
            //     }
            //     value += `${item.seriesName}：${item.value + type} <br>`
            //   })
            //   return value
            // },
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              // 自定义提示框内容
              var result = params.name + '<br/>';
              result += params.marker + params.seriesName + ': ' + params.value + '次' + '<br/>';
              // });
              return result;
            },
            position: function(point, params, dom, rect, size) {
              // 提示框跟随鼠标的位置显示
              // point: 鼠标位置，如 [20, 40]
              if(point[0]>660){
                if(point[1] > 480){
                  return [point[0]-100, point[1]-50];
                }else{
                  return [point[0]-100, point[1]];
                }
              }else{
                if(point[1] > 480){
                  return [point[0]+20, point[1]-50];
                }else{
                  return [point[0]+20, point[1]];
                }
              }
              // else if(){
              //   return [point[0]-100, point[1]];
              // }
              // return [point[0]+20, point[1]];

            }

            // position: function(point, params, dom, rect, size) {
            //   var x = 0; // x坐标位置
            //   var y = 0; // y坐标位置
            //   // 当前鼠标位置
            //   var pointX = point[0];
            //   var pointY = point[1];
            //   // 提示框大小
            //   var boxWidth = size.contentSize[0];
            //   var boxHeight = size.contentSize[1];
            //   // boxWidth > pointX 说明鼠标左边放不下提示框
            //   if (boxWidth > pointX) {
            //     x = pointX + 10;
            //   } else { // 左边放的下
            //     x = pointX - boxWidth - 10;
            //   }
            //   // boxHeight > pointY 说明鼠标上边放不下提示框
            //   if (boxHeight > pointY) {
            //     y = 5;
            //   } else {
            //     y = pointY - boxHeight;
            //   }
            //   return [x, y];
            // }
          },
          grid: {
            left: '2%',
            right: '4%',
            bottom: '14%',
            top: '16%',
            containLabel: true
          },
          legend: {
            data: ['救援', '故障', '维保', '理赔', '报警'],
            left: 'center',
            top: '5%',
            textStyle: {
              color: "#4dc1ed",
              text: 'center'
            },
            formatter: function(params) {
              // 自定义提示框内容
              // var result = params.name + '<br/>';
              // result += params.marker + params.seriesName + ': ' + params.value + '次' + '<br/>';
              // // });
              return params + '(次)';
            },
            itemWidth: 15,
            itemHeight: 10,
            itemGap: 25
          },
          xAxis: {
            type: 'category',
            data: dateList,
            axisLine: {
              lineStyle: {
                color: '#4dc1ed'

              }
            },
            axisLabel: {
              textStyle: {
                color: "#4dc1ed"
              }
            },
          },

          yAxis: [{
              type: 'value',
              axisLine: {
                show: false,
                lineStyle: {
                  color: '#4dc1ed'
                }
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                textStyle: {
                  color: "#4dc1ed"
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#4dc1ed'
                }
              },
            },
            {
              type: "value",
              // name: "百分比",
              nameTextStyle: {
                color: "#4dc1ed"
              },
              position: "right",
              axisLine: {
                lineStyle: {
                  color: '#4dc1ed'
                }
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: true,
                // formatter: "{value} %", //右侧Y轴文字显示
                textStyle: {
                  color: "#4dc1ed"
                }
              }
            }
          ],
          series: [{
              name: '救援',
              type: 'bar',
              // barWidth: '12px',
              itemStyle: {
                normal: {
                  color: '#4b9845',
                },
              },
              data: rescueNumList,
              barGap: '20%',
              barWidth: '25%',
            }, {
              name: '故障',
              type: 'bar',
              // barWidth: '12px',
              itemStyle: {
                normal: {
                  color: '#7ae2ee',
                },
              },
              data: failureNumList,
              barGap: '20%',
              barWidth: '25%',
            },
            {
              name: '维保',
              type: 'bar',
              // barWidth: '12px',
              itemStyle: {
                normal: {
                  color: '#eb696a',
                }

              },
              data: maintainNumList,
              barGap: '20%',
              barWidth: '25%',
            },
            {
              name: '理赔',
              type: 'bar',
              // barWidth: '12px',
              itemStyle: {
                normal: {
                  color: '#088bed',
                }
              },
              data: claimNumList,
              barGap: '20%',
              barWidth: '25%',
            }, {
              name: "报警",
              type: "line",
              yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
              smooth: false, //平滑曲线显示

              symbol: "circle", //标记的图形为实心圆
              symbolSize: 8, //标记的大小
              itemStyle: {
                normal: {
                  color: '#ffa43a',
                  borderColor: 'rgba(255, 234, 0, 0.5)', //圆点透明 边框
                  borderWidth: 5
                },

              },
              lineStyle: {
                color: "#ffa43a"
              },

              data: sosNumList
            }
          ]
        }
        this.chart.setOption(options)
      },
    },
  }
</script>
