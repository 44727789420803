<template>
  <div class="admin-project-myproject-container">
    <el-dialog :close-on-click-modal="false" append-to-body title="详情" :visible.sync="dioDetails" width="1500px"
      top="10vh" @close="closeDetails">
      <div style="height: 65vh; overflow-y: scroll" class="scrollElement">
        <el-row>
          <el-col :span="11">
            <el-descriptions :column="3" border class="descriptionsType_ctzl" v-if="dioDetailsInfo">
              <el-descriptions-item label="电梯名称" :span="4">
                {{ dioDetailsInfo.name }}
              </el-descriptions-item>
              <el-descriptions-item label="运行开始时间">
                {{ dioDetailsInfo.startTime }}
              </el-descriptions-item>
              <el-descriptions-item label="运行结束时间">
                {{ dioDetailsInfo.endTime }}
              </el-descriptions-item>
              <el-descriptions-item label="运行时长(秒)">
                {{ dioDetailsInfo.runningTime }}
              </el-descriptions-item>
              <el-descriptions-item label="运行方向">
                {{
                  dioDetailsInfo.runningDirection == 0
                    ? "上行"
                    : dioDetailsInfo.runningDirection == 1
                    ? "下行"
                    : "静止"
                }}
              </el-descriptions-item>
              <el-descriptions-item label="开始楼层">
                {{ dioDetailsInfo.startFloor }}
              </el-descriptions-item>
              <el-descriptions-item label="结束楼层">
                {{ dioDetailsInfo.endFloor }}
              </el-descriptions-item>
              <el-descriptions-item label="运行距离(m)">
                {{ dioDetailsInfo.distance }}
              </el-descriptions-item>
              <el-descriptions-item label="乘梯人数">
                {{ dioDetailsInfo.heads }}
              </el-descriptions-item>
              <el-descriptions-item label="最大速度(m/s)">
                {{ dioDetailsInfo.maxSpeed }}
              </el-descriptions-item>
              <el-descriptions-item label="最大加速度">
                {{ dioDetailsInfo.maxAddSpeed }}
              </el-descriptions-item>
              <el-descriptions-item label="创建时间">
                {{ dioDetailsInfo.createTime }}
              </el-descriptions-item>
              <el-descriptions-item label="更新时间">
                {{ dioDetailsInfo.updateTime }}
              </el-descriptions-item>
              <el-descriptions-item label="报警类型" :span="4">
                <template v-if="dioDetailsInfo.sosTypeName.length > 0">
                  <span v-for="(item, index) in dioDetailsInfo.sosTypeName" :key='index'>
                    <el-button type="text" @click="openSOS(item)" style="margin-right:5px">{{item.sosName}}</el-button>
                  </span>
                </template>
                <span v-else> - </span>
              </el-descriptions-item>
              <el-descriptions-item label="日志文件" :span="4">
                <el-button size="small" type="primary" @click="downLoadTxt(dioDetailsInfo.url)">下载文件
                </el-button>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
          <el-col :span="13">
            <div class="rightBox">
              <div class="echartTypeBox">
                <el-button size="small" :type="chartsType == 'oldV' ? 'primary' : 'info'" :loading="btnLoading"
                  @click="changeEchart('oldV')">速度</el-button>
                <el-button size="small" :type="chartsType == 'aspeed' ? 'primary' : 'info'" :loading="btnLoading"
                  @click="changeEchart('aspeed')">加速度</el-button>
                <el-button size="small" :type="chartsType == 'height' ? 'primary' : 'info'" :loading="btnLoading"
                  @click="changeEchart('height')">高度</el-button>
                <el-button size="small" :type="chartsType == 'x' ? 'primary' : 'info'" :loading="btnLoading"
                  @click="changeEchart('x')">x轴</el-button>
                <el-button size="small" :type="chartsType == 'y' ? 'primary' : 'info'" :loading="btnLoading"
                  @click="changeEchart('y')">y轴</el-button>
                <el-button size="small" :type="chartsType == 'z' ? 'primary' : 'info'" :loading="btnLoading"
                  @click="changeEchart('z')">z轴</el-button>

                <el-dropdown>
                  <!-- {{isShow}} -->
                  <el-tooltip class="item" effect="dark" v-if="isShow" content="完成播放" placement="top-start">
                    <img src="@/assets/finish.png" @click="complete()" style="width: 40px;height: 40px;">
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" v-else content="重新播放" placement="top-start">
                    <img src="@/assets/playr.png" @click="getEchart(chartsType,undefined,5)"
                      style="width: 40px;height: 40px;">
                  </el-tooltip>
                  <!-- <el-button type="text" @click="complete()" class="dropdownRideBtn">
                      一键完成
                    </el-button> -->
                  <!--  <el-button type="primary" size="small">
                    更多功能<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="dropdownRide">
                    <el-dropdown-item>
                      <el-button type="text" @click="complete()" class="dropdownRideBtn">
                        一键完成
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button type="text" @click="getEchart(chartsType)" class="dropdownRideBtn">
                        重新画图
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu> -->
                </el-dropdown>
                <!-- <el-button
                  size="small"
                  type="primary"
                  @click="complete()"
                  >一键画图
                </el-button> -->
              </div>
              <div class="echartTypeBox">
                <div class="lowShow">
                  <div v-for="(item, index) in lowInfo" :class="
                      item.index == initLow ? 'activeLowBox' : 'lowInfoBox'
                    " :style="{ height: uploadStyle }" :key="index">
                    <div>
                      {{ item.name }}
                    </div>
                  </div>
                  <!-- <div class="activeLowBox" :style="{height: uploadStyle}"></div> -->
                </div>
                <div class="echartShow">
                  <RideQualityChart :key="key" :sosLineData="txtJson" :playTime="drawingTime" :dataName="dataName" />
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="closeDetails">关 闭</el-button>
      </span>
    </el-dialog>
    <SosInfoDetail v-if="dioSosDetails" :detailsItem="sosDetailsItem" :dioDetailsItem="dioSosDetailsItem"
      @closeSosDetails="closeSosDetails" />
  </div>
</template>

<script>
  import RideQualityChart from "./rideQualityChart.vue";
  import SosInfoDetail from './sosInfoDetail.vue';
  export default {
    components: {
      RideQualityChart,
      SosInfoDetail
    },
    props: {
      dioDetailsInfo: Object,
    },
    data() {
      return {
        isShow: true,
        dioDetails: true,
        allFloor: 0,
        firstFloor: 0,
        endFloor: 0,
        firstTime: 0,
        endTime: 0,
        // 默认图表数据
        txtJson: {
          data: [],
          time: [],
          floor: [],
        },
        dataName: "oldV", // 默认图表类型
        playTime: 5000, // 默认动画总时长
        key: 0,
        lowInfo: null,
        initLow: null,
        againGetLow: true,
        lowNum: 0,
        chartsType: 'oldV',
        btnLoading: true,

        // sos
        sosDetailsItem: {
          seleId: 1,
          seleArr: [{
              value: 1,
              label: '报警消息',
            },
            {
              value: 2,
              label: '运行参数',
            },
            {
              value: 3,
              label: '处置流程',
            },
            {
              value: 4,
              label: '报警通知'
            }
          ],
        },
        dioSosDetailsItem: null,
        dioSosDetails: false,
        drawingTime: 0
      };
    },
    computed: {
      uploadStyle() {
        return this.lowInfo && this.lowInfo.length > 0 ?
          405 / this.lowInfo.length + "px" :
          "0px";
      },
    },
    created() {},
    mounted() {
      this.getEchart("oldV");
    },
    methods: {
      //下载文件
      downLoadTxt(filePath) {
        // window.open(filePath, "_black");
        const link = document.createElement('a')
        const url = filePath
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob)
          link.download = ''
          document.body.appendChild(link)
          link.click()
        })
      },
      openSOS(item) {
        this.$http
          .post('/api/ele/web/sos/getInfo', {
            id: item.id,
            sosTime: item.time ? item.time : ''
          })
          .then((res) => {
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl ? qs.videoUrl.split('?') : ''
                qs.videoUrl = url[0]
                qs.videoName = url[1] ? url[1].split('&')[0].split('=')[1] : ''
              })

              res.data.data.sosTypeName = item.sosName
              res.data.data.sosStatusName = item.sosName
              res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
              res.data.data.speedarr = res.data.data.speedRecord.split(',')

              this.sosDetailsItem.seleId = 1
              this.dioSosDetailsItem = res.data.data
              this.dioSosDetails = true

            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      closeSosDetails() {
        this.dioSosDetails = false
      },

      complete() {
        this.isShow = false
        this.btnLoading = false
        this.key++
        this.getEchart(this.chartsType, 1)
      },


      getEchart(type, time, num) {
        if (num == 5) {
          this.isShow = true
          this.btnLoading = true
        }
        // this.isShow = true
        this.key++;
        // this.btnLoading = true
        this.txtJson.time = [];
        this.txtJson.data = [];
        this.txtJson.oldV = [];
        this.txtJson.outSpeed = [];
        this.lowInfo = [];
        this.againGetLow = true;
        this.dataName = type;
        // this.allFloor = this.dioDetailsInfo.floorHeight + this.dioDetailsInfo.underFloor
        const floorHeight = this.dioDetailsInfo.floorHeight - this.dioDetailsInfo.underFloor
        const underFloor = this.dioDetailsInfo.underFloor
        // const floorHeight = 3;
        // const underFloor = 2;

        this.firstFloor = this.dioDetailsInfo.eleDeviceLogVOList[0].floor;
        this.initLow = JSON.parse(JSON.stringify(this.firstFloor))
        this.endFloor =
          this.dioDetailsInfo.eleDeviceLogVOList[
            this.dioDetailsInfo.eleDeviceLogVOList.length - 1
          ].floor;
        // this.firstFloor = 2;
        // this.initLow = JSON.parse(JSON.stringify(this.firstFloor));
        // this.endFloor = 2
        this.firstTime = this.dioDetailsInfo.eleDeviceLogVOList[0].time;
        this.endTime =
          this.dioDetailsInfo.eleDeviceLogVOList[
            this.dioDetailsInfo.eleDeviceLogVOList.length - 1
          ].time;

        this.drawingTime = time ? time : this.endTime - this.firstTime
        // this.drawingTime = 3000

        for (let index = floorHeight; index > 0; index--) {
          this.lowInfo.push({
            name: `${index}楼`,
            index: index,
          });
        }
        // let underLow = 0 - underFloor
        for (let index = 1; index <= underFloor; index++) {
          this.lowInfo.push({
            name: `-${index}楼`,
            index: 0 - index,
          });
        }
        // 动画时长
        if (this.lowInfo.length > 1) {
          if (this.dioDetailsInfo.runningDirection == 0) {
            this.lowNum = this.endFloor - this.firstFloor
          }
          if (this.dioDetailsInfo.runningDirection == 1) {
            this.lowNum = this.firstFloor - this.endFloor
          }
          this.playTime = time ? time :
            Math.floor(
              (this.endTime - this.firstTime) / (this.lowNum) / 1000
            ) * 1000;
          // const playTime = 300


          if (this.lowNum != 0) {
            this.getNewLow(this.playTime);
          }
        }

        this.dioDetailsInfo.eleDeviceLogVOList.map((item) => {
          this.txtJson.time.push(this.parseTime(Number(item.time)));
          if (type == 'oldV') {
            this.txtJson.oldV.push(item.oldV);
            this.txtJson.outSpeed.push(item.outSpeed);
          } else {
            this.txtJson.data.push(item[type]);
          }
          this.txtJson.floor.push(item.floor);
        });

      },

      getNewLow(playTime) {
        // setTimeout(() => {
        if (this.dioDetailsInfo.runningDirection == 0) {
          if (this.initLow + 1 == 0) {
            this.initLow = this.initLow + 2;
          } else {
            this.initLow++
          }
          if (this.initLow == this.endFloor) {
            clearTimeout()
            this.againGetLow = false;
            this.btnLoading = false
          }
        }
        if (this.dioDetailsInfo.runningDirection == 1) {
          if (this.initLow - 1 == 0) {
            this.initLow = this.initLow - 2;
          } else {
            this.initLow--
          }
          if (this.initLow == this.endFloor) {
            clearTimeout()
            this.againGetLow = false;
            this.btnLoading = false
          }
        }
        setTimeout(() => {
          if (this.initLow == 1) {
            this.isShow = false
            // this.$forceUpdate()
          }
          if (this.againGetLow) {
            this.getNewLow(playTime);
          }

        }, playTime);
      },

      parseTime(time) {
        if (!time) return; // 空值不运行
        const date = new Date(time);
        const format = "{y}-{m}-{d} {h}:{i}:{s}";
        const formatObj = {
          y: date.getFullYear(),
          m: date.getMonth() + 1,
          d: date.getDate(),
          h: date.getHours(),
          i: date.getMinutes(),
          s: date.getSeconds(),
        };
        const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
          let value = formatObj[key];
          if (result.length > 0 && value < 10) {
            value = "0" + value;
          }
          return value || 0;
        });
        return timeStr;
      },

      changeEchart(type) {
        this.isShow = true
        this.chartsType = type
        this.getEchart(type);
      },
      closeDetails() {
        this.$emit("closeDetails");
        this.dioDetails = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .descriptionsType .el-descriptions-item__label.is-bordered-label {
    width: 100px;
  }

  .rightBox {
    margin-left: 10px;
    border-radius: 10px;
    padding: 15px 15px;

    .echartTypeBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .lowShow {
      width: 20%;
      padding: 15px 20px;

      .lowInfoBox {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #227b97;
        background: url(../../../../assets/lowInfoBox.png) no-repeat;
        background-size: 100% 100%;
        color: #00f6ff !important;
      }

      .activeLowBox {
        background: url(../../../../assets/activeLowBox.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        color: #fff;
      }
    }

    .echartShow {
      width: 80%;
    }
  }
</style>
