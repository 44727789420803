<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	let inTimeObj;
	let one = true;
	export default {
		name: "App",
		created() {
			if (window.location.hostname == "zxtlw.zyzntech.com") {
				this.$store.state.isZx = true;
			}


			let token = sessionStorage.getItem("AdminToken");
			console.log("重连时候的token值：", token);
			if (token != undefined) {
				this.recon(token);
			}
			const that = this;
			// 长连接重连
			let onlineFun = function() {
				console.log("网络恢复了");
				token = sessionStorage.getItem("AdminToken");
				that.recon(token);
			};
			let offlineFun = function() {
				that.socketApi.stopHeartBeat();
				// 安全帽
				that.socketCapsApi.stopCapsHeartBeat();
				that.socketCarportApi.stopCarportHeartBeat();
				sessionStorage.setItem("me_id", "{ismesh:false}");
			};
			window.removeEventListener("online", onlineFun, false);
			window.removeEventListener("offline", offlineFun, false);
			window.addEventListener("online", onlineFun, false);
			window.addEventListener("offline", offlineFun, false);

			clearInterval(inTimeObj);
			// 非网络情况下导致长连接死掉的重连
			inTimeObj = setInterval(() => {
				token = sessionStorage.getItem("AdminToken");
				console.log("非网络情况下 我重连了giaogiao");
				// console.log('token123', token)
				if (
					(sessionStorage.getItem("me_id") == undefined ||
						sessionStorage.getItem("cap_id") == undefined) &&
					token != undefined &&
					this.socketApi.websock.readyState != "1" &&
					this.socketApi.websock.readyState != "0" &&
					one &&
					this.$route.path != "/login"
				) {
					console.log("非网络情况下 我重连了giaoligiao1231231");
					one = false;
					that.recon(token);
				}
			}, 30000);
		},
		mounted() {
			let obj = sessionStorage.getItem("me_id");
			let loadFlashTimer = setInterval(() => {
				obj = sessionStorage.getItem("me_id");
				if (this.$route.path != "/login" && this.$route.path != "/loginWait") {
					if (
						!this.$store.state.userAgentStatus &&
						obj != null &&
						obj != undefined
					) {
						clearInterval(loadFlashTimer);
						let objInfo = JSON.parse(obj).sip_info;
						let localPhone = objInfo.sip_id;
						let me_uri_joint = localPhone + "@" + objInfo.sip_host;
						let ws_uri = objInfo.wss_url;
						let pwd = objInfo.sip_pwd;
						// 进行号码登录
						// 配置参数
						let config = {
							uri: me_uri_joint,
							transportOptions: {
								wsServers: [ws_uri],
								connectionTimeout: 30,
							},
							authorizationUser: localPhone,
							password: pwd,
							sessionDescriptionHandlerFactoryOptions: {
								peerConnectionOptions: {
									rtcConfiguration: {
										iceServers: [{
												urls: objInfo.stun_host,
											},
											{
												urls: objInfo.turn_host,
												username: objInfo.turn_user,
												credential: objInfo.turn_pwd,
											},
										],
									},
								},
							},
						};
						//创建user agent
						this.$store.state.userAgent = new SIP.UA(config);
						//注册成功
						this.$store.state.userAgent.on("registered", () => {
							console.log("registered ok");
							this.$store.state.userAgentStatus = true;
						});
						//注册失败
						this.$store.state.userAgent.on(
							"registrationFailed",
							(response, cause) => {
								// this.$message({
								//   type: 'error',
								//   message: this.$t('common.registerFail'),
								// })
								this.$store.state.userAgentStatus = false;
							}
						);
					}
				}
			}, 1000);
		},
		methods: {
			recon(token) {
				if (!this.socketApi.isheart) {
					if (token == "" || token == undefined) {
						clearInterval(sosTime);
						this.socketApi.stopHeartBeat();
						// this.socketCapsApi.stopCapsHeartBeat();
						// 	this.socketCarportApi.stopCarportHeartBeat();
						this.$message({
							type: "error",
							message: "token检测错误!",
						});
						sessionStorage.clear();
						this.$router.push("/login");
						this.axios.post("/api/system/web/user/loginOut").then((res) => {});
					}

					let agentData = {
						act: "ma_login",
						token: token,
					};
					this.socketApi.initWebSocket();
					let ontime = setInterval(() => {
						if (this.socketApi.websock.readyState != "0") {
							if (
								!this.socketApi.isheart &&
								this.socketApi.websock.readyState != "1"
							) {
								this.socketApi.initWebSocket();
								return;
							}
							clearInterval(ontime);
							if ( this.socketApi.websock.readyState == "1") {
								this.socketApi.sendSock(agentData, (e) => {
									if (e.cmd == "ma_login") {
										one = true;
										sessionStorage.setItem("me_id", e.data);
									}
								});
							}
						}

					}, 3000);
				}
				// 安全帽
				if (!this.socketCapsApi.isheart) {
					if (token == "" || token == undefined) {
						clearInterval(sosTime);
						this.socketCapsApi.stopCapsHeartBeat();
						this.$message({
							type: "error",
							message: "token检测错误!",
						});
						sessionStorage.clear();
					}
					let agentData = {
						act: "ma_login",
						token: token,
					};
					this.socketCapsApi.initCapsSocket();
					let ontime_cpas = setInterval(() => {

						if(this.socketCapsApi.websockCaps){
							if (this.socketCapsApi.websockCaps.readyState != "0") {
								if (
									!this.socketCapsApi.isheart &&
									this.socketCapsApi.websockCaps.readyState != "1"
								) {
									this.socketCapsApi.initCapsSocket();
									return;
								}
								clearInterval(ontime_cpas);
								this.socketCapsApi.sendCapsSock(agentData, (e) => {
									if (e.cmd == "ma_login") {
										one = true;
										sessionStorage.setItem("cap_id", e.data);
									}
								});
							}
						}
					}, 1000);
				}

				// 电梯棚
				if (!this.socketCarportApi.isheart) {
					if (token == "" || token == undefined) {
						clearInterval(sosTime);
						this.socketCarportApi.stopHeartBeat();
						this.$message({
							type: "error",
							message: "token检测错误!",
						});
						sessionStorage.clear();
						// this.$router.push('/login')
						// this.axios.post('/api/system/web/user/loginOut').then((res) => {})
					}
					let agentData = {
						act: "ma_login",
						token: token,
					};
					this.socketCarportApi.initCarportSocket();
					let ontime_cpas = setInterval(() => {
						
						if(this.socketCapsApi.websock){

							if (this.socketCarportApi.websock.readyState != "0") {
								if (
									!this.socketCarportApi.isheart &&
									this.socketCarportApi.websock.readyState != "1"
								) {
									this.socketCarportApi.initCarportSocket();
									return;
								}
								clearInterval(ontime_cpas);
								this.socketCarportApi.sendCarportSock(agentData, (e) => {
									if (e.cmd == "ma_login") {
										one = true;
										sessionStorage.setItem("me_car_id", e.data);
									}
								});
							}
						}
					}, 1000);
				}

			},
		},
	};
</script>
