<template>
  <div ref="topbox">
    <!-- sosTypeList -->
    <!-- 报警 -->
    <audio id="alarmA" :src="require('@/assets/alarm.mp3')" loop="true" />
    <div class="sosCall">
      <template v-for="(item, index) in sosTypeList">
        <div class="sosCallBox" v-if="item.showBoxType != 1" :key="index">
          <div v-if="item.cmd == 'push_sos'" class="newsosBox">
            <div style="position: relative;">
              <div class="colorBox" @click="closeSOS(item, 'sos')">
                <i class="el-icon-close"></i>
              </div>
              <el-image fit="contain"
                :src="item.showBoxType == 3 ? require(`@/assets/sosTitBg1.png`) : require(`@/assets/sosTitBg2.png`)"
                style="width:100%" />
              <div class="sosMsgHeader" style="color: #fff">
                <div class="sosTitle">
                  <p style="font-size:22px;font-weight: bolder">{{ item.sosName }}</p>
                  <p style="font-size:16px;">{{ item.closetime }}S</p>
                </div>
              </div>
            </div>
            <div class="sosMsgContent" style="padding: 0 20px;">
              <div class="sosMsg" style="display:flex;align-items: flex-start;">
                <p style="width:27%;">电梯名称：</p>
                <p style="width:72%" :title="item.name" class="overName">{{ item.name }} </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">报警时间：</p>
                <p style="width:72%">{{ item.time }} </p>
              </div>
              <div class="sosMsg" v-if="item.code">
                <p style="width:27%">报警楼层：</p>
                <!-- {{item}} -->
                <p style="width:72%">{{ item.floor }} </p>
              </div>
            </div>
            <div class="sosMsgBottom">
              <el-button type="primary" size="small" v-if="allowSos.includes(item.type)&&item.code != ''
              " @click="answerSOS(item)" style="width:100px">接听</el-button>
              <el-button type="info" size="small" @click="closeSOS(item, 'sos')" :class="
              allowSos.includes(item.type)&&item.code == ''? 'offdialog': 'offdialogs'">关闭弹窗</el-button>
            </div>
          </div>
          <div v-else-if="item.cmd == 'carport_push_sos'" class="newsosCapsBox">
            <!-- 车棚 -->
            <div style="position: relative;">
              <div class="colorBox" @click="closeSOS(item, 'sos')">
                <i class="el-icon-close"></i>
              </div>
              <el-image fit="contain" :src="require(`@/assets/sosTitBg1.png`)" style="width:100%" />
              <div class="sosMsgHeader" style="color: #fff">
                <div class="sosTitle">
                  <p style="font-size:22px;font-weight: bolder">{{ item.sosName }}</p>
                  <p style="font-size:16px;">{{ item.closetime }}S</p>
                </div>
              </div>
            </div>
            <div class="sosMsgContent" style="padding: 10px 20px;">
              <div class="sosMsg" style="display:flex;align-items: flex-start;">
                <p style="width:27%;">电动车棚：</p>
                <p style="width:72%" :title="item.name" class="overName">{{ item.name }} </p>
              </div>
              <div class="sosMsg" style="margin: 10px 0">
                <p style="width:27%">报警时间：</p>
                <p style="width:72%">{{ item.time }} </p>
              </div>
              <div class="sosMsg">
                <p style="max-width:30%">报警摄像头：</p>
                <!-- {{item}} -->
                <p style="width:69%">{{ item.deviceName }} </p>
              </div>
            </div>
            <div class="sosMsgBottom">
              <el-button type="primary" size="small" v-if="item.code != '' && item.type != 35"
                @click="answerCarSOS(item)" style="width:100px; margin-right: 35px">接听</el-button>
              <el-button type="info" size="small" @click="closeSOS(item, 'sos')"
                :class="item.code == '' && item.type != 35 ? 'offdialog': 'offdialogs'">关闭弹窗</el-button>
            </div>
          </div>
          <div v-else class="newsosCapsBox">
            <!-- 安全帽 -->
            <div style="position: relative;">
              <div class="colorBox" @click="closeSOS(item, 'caps')">
                <i class="el-icon-close"></i>
              </div>
              <el-image fit="contain" :src="require(`@/assets/sosTitBg2.png`)" style="width:100%" />
              <div class="sosMsgHeader" style="color: #fff">
                <div class="sosTitle">
                  <p style="font-size:22px;font-weight: bolder">{{ item.sosName }}</p>
                  <p style="font-size:16px;">{{ item.closetime }}S</p>
                </div>
              </div>
            </div>
            <div class="sosMsgContent" style="padding: 0 20px;">
              <div class="sosMsg" style="display:flex">
                <p style="width:27%">报警设备：</p>
                <p style="width:72%">安全帽 </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">人员名称：</p>
                <p style="width:72%">{{ item.userName }} </p>
              </div>
              <div class="sosMsg">
                <p style="width:27%">报警时间：</p>
                <p style="width:72%">{{ item.sosTime }} </p>
              </div>
              <div class="sosMsg" style="display:flex">
                <p style="width:27%">报警位置：</p>
                <p style="width:72%">{{ item.address }} </p>
              </div>
            </div>
            <div class="sosMsgBottom">
              <el-button type="primary" size="small" @click="answerSOSCaps(item)">接听</el-button>
              <el-button type="info" size="small" @click="closeSOS(item, 'caps')"
                style="margin-left: 70px">关闭弹窗</el-button>
            </div>
          </div>
        </div>
      </template>
      <!-- 电梯 -->
      <el-dialog v-if="visible" :visible.sync="visible" :close-on-click-modal="false" :show-close="showClose"
        width="1050px" top="8vh" :modal="false" @close="bclose">
        <div style="padding: 0 20px" class="scrollElement">
          <EditionC1 v-if="!isOldJk" ref="editionC1" :jkData="jkData" :sosId="sosId" :alarmTime="alarmTime"
            @bclose="bclose" :videoStatusId="videoStatusId" :id="device_id" :ids="ids"
            :dioDetailsItem="dioDetailsItem" />
          <EditionC2 v-else ref="editionC2" :jkData="jkData" :sosId="sosId" :alarmTime="alarmTime" @bclose="bclose"
            :isHaveVideo="isHaveVideo" :id="device_id" :percentage="percentage" @changePercentage="changePercentage" :videoStatusId="videoStatusId"
            :ids="ids" :dioDetailsItem="dioDetailsItem" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>

      <!-- 车棚 -->
      <el-dialog v-if="carVisible" :visible.sync="carVisible" :close-on-click-modal="false" :show-close="showClose"
        width="950px" top="8vh" :modal="false" @close="bCarClose">
        <div style="padding: 0 20px" class="scrollElement">
          <SosCarDialog :deviceCode="dioDetailsItem.code" :sosId="dioDetailsItem.sosId" :bikeId="dioDetailsItem.bikeId"
            :sosDialog="true" @closed="bCarClose" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>


      <!-- 安全帽 -->
      <el-dialog v-if="visibleCaps" :visible.sync="visibleCaps" :close-on-click-modal="false" :show-close="showClose"
        width="950px" top="8vh" :modal="false" @close="bcloseCaps">
        <div style="padding: 0 20px" class="scrollElement">
          <CapszlvScheme v-if="capsData" :jkData="capsData" @bcloseCaps="bcloseCaps" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>
    <NoticeDialog v-if="noticeVisiblie" :noticeInfo="noticeInfo" @closeDialog="closeDialog"/>
    <!-- <el-dialog
			v-if="isPwdDio"
			:visible.sync="isPwdDio"
			width="500px"
			top="20vh"
			:close-on-press-escape="!tipPwdShow"
			:close-on-click-modal="!tipPwdShow"
			:show-close="!tipPwdShow"> -->
    <el-dialog v-if="isPwdDio" :visible.sync="isPwdDio" width="700px" top="20vh" :title="Pwdtit" append-to-body >
      <div style="display:flex;flex-direction: column;align-items: center;">
        <p v-if="tipPwdShow&&Pwdtit == '修改密码'" class="tipPwd">检测到您的密码过于简单，请重新设置新密码</p>
        <p v-if="Pwdtit != '修改密码'" style="display:flex;align-items: center;color: lightgrey;">
          <i class='el-icon-warning-outline' style="font-size:30px;color: coral;"></i>
          您的登录密码长时间未修改，为保证您的账户安全，请进行修改。
        </p><!-- 长期要 -->
        <div style="height: 240px;padding-right: 80px;">
          <el-form label-position="right" label-width="120px" ref="contracts" :model="contractForm" :rules="formRules">
            <el-row style="margin: 10px 0">
              <el-col :span="24">
                <el-form-item label="旧密码:" prop="password">
                  <el-input placeholder="请输入" v-model="contractForm.password" style="width:100%">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin: 10px 0">
              <el-col :span="24">
                <el-form-item label="新密码:" prop="newPassword">
                  <el-input placeholder="请输入" v-model="contractForm.newPassword" style="width:100%">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin: 10px 0">
              <el-col :span="24">
                <el-form-item label="确认密码:" prop="obNewPassword">
                  <el-input placeholder="请输入" v-model="contractForm.obNewPassword" style="width:100%">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-checkbox v-model="Pwdchecked" v-show="Pwdtit != '修改密码'" @change="PwdchangeCheck">今日不再提示</el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="small" type="primary" @click="setPwd">确 定</el-button>
        <el-button v-if="!tipPwdShow&&Pwdtit == '修改密码'" size="small" @click="isPwdDio = false">取
          消</el-button>
      </div>
    </el-dialog>

    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="auto">
        <div @click="isCartes.i = false">
          <!-- 导航菜单 -->
          <el-menu :default-active="this.$route.matched[1].meta.lab" class="el-menu-vertical-demo"
            :collapse="isCollapse" text-color="#bfcbd9" unique-opened>
            <router-link v-if="bigScreenType == 1" to="">
              <el-menu-item @click="openNewPage('/newBigmianban')" index="0" style="text-align: left; width: 100%">
                <!-- <i class="el-icon-s-home"></i> -->
                <el-image :src="require('@/assets/' + seleThemeStyle + '数据监控.png')" fit="cover"
                  style="width:20px; margin-right: 10px;vertical-align: text-top;" />
                <span slot="title">数据监控</span>
              </el-menu-item>
            </router-link>
            <router-link v-if="bigScreenType == 3" to="">
              <el-menu-item @click="openNewPage('/maintenanceBigmianban')" index="0"
                style="text-align: left; width: 100%">
                <!-- <i class="el-icon-s-home"></i> -->
                <el-image :src="require('@/assets/' + seleThemeStyle + '数据监控.png')" fit="cover"
                  style="width:20px; margin-right: 10px;vertical-align: text-top;" />
                <span slot="title">数据监控</span>
              </el-menu-item>
            </router-link>
            <router-link v-if="bigScreenType == 2" to="">
              <el-menu-item @click="openNewPage('/operate')" index="0" style="text-align: left; width: 100%">
                <!-- <i class="el-icon-s-home"></i> -->
                <el-image :src="require('@/assets/' + seleThemeStyle + '数据监控.png')" fit="cover"
                  style="width:20px; margin-right: 10px;vertical-align: text-top;" />
                <span slot="title">数据监控</span>
              </el-menu-item>
            </router-link>
            <el-submenu :index="item.id" style="text-align: left; width: 100%" v-for="(item, index) in elList"
              :key="index">
              <template slot="title">
                <div style="width: 100%;height: 100%;" @click="openPage(item)">
                  <!-- <i class="el-icon-s-marketing"></i> -->
                  <el-image v-if="item.name" :src="getImg(item.name)" fit="cover"
                    style="width:20px; margin-right: 10px;vertical-align: text-top;" />
                  <span>{{ item.name }}</span>
                </div>
              </template>
              <router-link v-for="(items, indexs) in elList[index].children" :key="indexs" :to="items.routePath">
                <el-menu-item @click="pushList(items.name, items.routePath, items.id)"
                  style="text-align: left; width: 100%" :index="items.id">
                  <span>{{ items.name }}</span>
                </el-menu-item>
              </router-link>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <!-- 头部 -->
        <el-header>
          <div class="headerRec" @click="isCartes.i = false">
            <div class="headerRecTop">
              <div class="hTop01">
                <span class="hTop01Tit">
                  <span v-if="platformName&&!$store.state.isZx && windowHost">
                    <span>{{platformName}}</span>
                  </span>
                  <span v-else>
                    <span v-if="$store.state.isZx">中信</span>数字化电梯物联网<span v-if="!$store.state.isZx">安全</span>监管系统</span>
                </span>
              </div>
              <div class="hTop02">
                <el-button @click="openDownload" type="text">下载APP</el-button>
                <el-button @click="goCheck" type="text" v-if="!$store.state.isWBJB && showLook">
                  <div style="display:flex;align-items: center;">
                    <i class="el-icon-video-camera" style="font-size:25px;"></i>
                    <span>查看教程</span>
                  </div>
                </el-button>
                <el-button @click="gotoHome"
                  v-if="!$store.state.isWBJB && this.adminInfo.resource.find(item => item.name == '首页')"
                  type="text">返回首页</el-button>

                <!-- 头像 -->
                <el-dropdown style="margin-right: 50px; margin-left: 20px">
                  <span class="el-ds">
                    <el-avatar size="medium" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png">
                    </el-avatar>
                    <span>{{ adminInfo.name }}</span>
                    <i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div style="margin-right: 10px;display: flex;align-items: center;">
                      <span :style="{'color':seleTheme?'black':'white'}" style=" #606266 !important">
                        <el-dropdown-item> 简洁模式: </el-dropdown-item></span>
                      <el-switch v-model="seleTheme" @change="seleThemeChange" active-color="#13ce66"
                        inactive-color="#73cae1">
                      </el-switch>
                    </div>
                    <div style="width: 100%; height: 100%" @click="updPwd()">
                      <el-dropdown-item> 修改密码 </el-dropdown-item>
                    </div>
                    <div style="width: 100%; height: 100%" @click="quit()">
                      <el-dropdown-item> 退出登录 </el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="headerRecBottom">
              <!-- 自定义标签页 -->
              <tabControl :isCarte="isCartes" :routeDate="rdata" :routerName="routeName" @goTag="goTag"></tabControl>
            </div>
          </div>
        </el-header>

        <el-main class="rtgg2" ref="rtgg">
          <div @click="isCartes.i = false" id="das" ref="pageBox">
            <router-view @click="isCartes.i = false" />

            <el-backtop target=".el-main" :visibility-height="20" :bottom="100" :right="40" style="z-index: 99">
              <div class="el-icon-s-home eecon"></div>
            </el-backtop>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- {{route.path}} -->

    <!-- <div v-show="showParticle" id="particles-js" class="particles"></div> -->
    <bzStovk v-if="tutorailVisiblie" @closeCheck="tutorailVisiblie = false" :subForm="subForm" />
    <AppDownloadDialog v-if="downloadVisiblie" @close="closeDownloadDialog" />
  </div>
</template>

<script>
  import screenfull from 'screenfull' //引入控制全屏的插件
  import tabControl from '../../components/tabControl.vue'
  import EditionC1 from '../../components/editionC1'
  import EditionC2 from '../../components/editionC2'
  import SosCarDialog from '@/components/sosCarDialog.vue';
  import CapszlvScheme from '../../components/cap/zlvSchemeCaps.vue'
  import bzStovk from '../xxgl/bzzx/components/bzStock.vue'
  import {
    formatDate
  } from "@/util";
  import ThemeCom from '../../components/ThemeCom'
  import NoticeDialog from './noticeDialog.vue';
  import AppDownloadDialog from './AppDownloadDialog.vue';



  let sosTime
  export default {
    components: {
      // adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      tabControl,
      ThemeCom,
      EditionC1,
      EditionC2,
      CapszlvScheme,
      NoticeDialog,
      SosCarDialog,
      bzStovk,
      AppDownloadDialog
    },
    watch: {
      $route: function(to, from) {},
      Pwdchecked: {
        handler: function(val, oldVal) {
          console.log(val, oldVal)
          // this.PwdchangeCheck()
          // this.theme = val;
        },
        immediate: true,
      },
    },
    data() {
      var validateNewPwd = (rule, value, callback) => {
        let operation =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%&^*()_——\-+=<>;？?:""{}|「」,.\\/'\[\]·！￥（）《》？：“”【】、；‘’，。]).{8,16}$/
        // inpYz(rule, value, callback, true, operation, '密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号')
        if (!value) {
          callback(new Error('请输入新密码'))
        } else if (!operation.test(value)) {
          callback(new Error('密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号'))
        } else {
          if (value == this.contractForm.password) {
            callback(new Error('新密码不能与旧密码相同'))
          }
        }
        callback()
      }
      var validateObPwd = (rule, value, callback) => {
        if (!value) {
          callback(new Error('确认密码不能为空'))
        } else {
          if (value !== this.contractForm.newPassword) {
            callback(new Error('确认密码与新密码不同'))
          }
        }
        callback()
      }
      return {
        tutorailVisiblie: false,
        seleTheme: false,
        isOldJk: '',
        //
        adminInfo: null,
        isCartes: {
          i: false,
        },
        isCollapse: false,
        color: '#00F6FF ',
        isfull: false,
        elList: [],
        tpList: [],
        boxTpList: [],
        tpCapsList: [],

        rdata: {
          i: 1,
          tags: [],
        },
        sosTypeList: [],
        jkData: null, // 球机推拉流
        capsData: null, // 安全帽信息
        visible: false, // 平台监控弹框
        visibleCaps: false, // 安全帽监控弹框
        sosId: '',
        videoStatusId: '',
        ids: '',
        alarmTime: '',
        showClose: false,
        bigScreenType: '',
        dioDetailsItem: {},
        showLook: false, //教程按钮
        subForm: {},
        // 修改密码
        isPwdDio: false,
        Pwdtit: '',
        Pwdchecked: false,
        formRules: {
          password: [{
            required: true,
            message: '请输入旧密码',
            trigger: 'blur'
          }],
          newPassword: [{
            required: true,
            validator: validateNewPwd,
            trigger: 'blur',
          }],
          obNewPassword: [{
            required: true,
            validator: validateObPwd,
            trigger: 'blur'
          }]
        },
        contractForm: {},
        image: require('@/assets/sos.png'),

        percentage: 0,
        device_id: '',

        platformName: JSON.parse(sessionStorage.getItem('adminInfo')).platformName,
        logoUrl: JSON.parse(sessionStorage.getItem('adminInfo')).logoUrl,
        windowHost: window.location.hostname != 'zxtlw.zyzntech.com',
        // showParticle: this.$route.path == '/dashboard', // 粒子效果
        tipPwdShow: false,

        noticeVisiblie: false,
        noticeInfo: {},
        tpCarList: [],
        routeName: null,


        //车棚
        carVisible: false,

        newCode: '',
        seleThemeStyle: 'primary/',
        downloadVisiblie: false,

        isHaveVideo: null,
        allowSos: [0,1,2,3,333,4,5,7,10,11,12,13,14,19,20,21,95,96,98,999]

      }
    },

    methods: {
      getImg(type) {
        let imgFiles = ''
        if (this.seleThemeStyle == 'simple/') {
          imgFiles = require.context('@/assets/simple/', true, /.png$/)
        } else {
          imgFiles = require.context('@/assets/primary/', true, /.png$/)
        }
        let url = require('@/assets/' + this.seleThemeStyle + '档案管理.png')
        imgFiles.keys().map(item => {
          if (item.includes(type)) {
            url = imgFiles(item)
          }
        })
        return url
      },
      seleThemeChange() {
        // this.$store.state.StaSeleTheme = this.seleTheme
        this.$store.commit('changeTheme', this.seleTheme)
        var head = document.getElementById('changecss');
        if (this.seleTheme) {
          head.href = "./static/style/ThemesTwo.css"
          this.seleThemeStyle = 'simple/'
        } else {
          head.href = "./static/style/ThemesOne.css"
          this.seleThemeStyle = 'primary/'
        }
        localStorage.setItem('seleTheme', this.seleTheme)
      },
      //跳转广告平台
      toAD() {
        let adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
        let httpUrl = window.location.hostname == ('zxtlw.zyzntech.com' || 'tzb.runde.pro') ?
          "https://ads.runde.pro/R3Login" : "http://ads-test.runde.pro:30002/R3Login"
        let username = adminInfo.name
        let time = Math.floor(new Date().getTime() / 1000 + 10000000)
        httpUrl = `${httpUrl}?username=${username}&time=${time}`
        window.open(httpUrl, "_blank");
      },
      //今日不再提示
      PwdchangeCheck() {
        console.log('今日不再提示')
        let adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
        const PwdcheckedInfo = JSON.parse(localStorage.getItem('Pwdchecked')) ?? []
        const time = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime()
        // -(24*60*60*1000)
        if (PwdcheckedInfo && PwdcheckedInfo.length > 0 && PwdcheckedInfo.find(item => item.userName ==
            adminInfo.userName)) {
          PwdcheckedInfo.find(item => item.userName == adminInfo.userName).Pwdchecked = this.Pwdchecked
          PwdcheckedInfo.find(item => item.userName == adminInfo.userName).time = time
        } else {
          PwdcheckedInfo.push({
            Pwdchecked: this.Pwdchecked,
            time,
            userName: adminInfo.userName
          })
        }
        localStorage.setItem('Pwdchecked', JSON.stringify(PwdcheckedInfo))
      },
      setPwd() {
        this.$refs.contracts.validate(async (valid) => {
          if (valid) {
            if (this.contractForm.newPassword == this.contractForm.obNewPassword) {
              let data = {
                id: this.adminInfo.id,
                password: this.contractForm.password,
                newPassword: this.contractForm.newPassword
              }
              this.axios.post('/api/system/web/user/modifyPassword', data).then((res) => {
                if (res.data.success) {
                  this.isPwdDio = false
                  this.$message.success(res.data.msg)
                  this.loginOut()
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else {
              this.$message.error('两次密码输入不一致，请确认新密码！')
            }
          }
          //  else {
          //   this.$message.error('请将表单填写完整！')
          // }
        })
      },
      updPwd() {
        this.isPwdDio = true
        this.Pwdtit = '修改密码'
        this.contractForm = {}
      },
      gotoHome() {
        this.pushList('首页', '/dashboard', 1)
        this.$router.push('/dashboard')
      },
      goCheck() {
        this.tutorailVisiblie = true
      },

      openDownload() {
        this.downloadVisiblie = true
      },
      closeDownloadDialog() {
        this.downloadVisiblie = false
      },

      openPage(data) {
        if (data.name == '数据统计' && this.$route.path != "/sjtjPage" && !JSON.parse(sessionStorage.getItem("isWBJB"))) {
          this.pushList('数据统计', '/sjtjPage', 99)
          this.$router.push('/sjtjPage')
        }
      },


      //当选择颜色主题时触发
      colorChange(color) {
        this.color = color
      },
      isCartesN() {
        this.isCartes = false
      },

      goTag(data) {
        this.changePage()
        // this.showParticle = data.name == '首页'
      },
      pushList(lab, rou, ind) {
        let paths = ''
        const router = JSON.parse(sessionStorage.getItem('adminInfo')).resource
        router.map(item => {
          if (item.children) {
            item.children.map(iitem => {
              if (iitem.name == lab) {
                this.showLook = iitem.isExist
                this.subForm = {
                  name: iitem.title,
                  content: iitem.content
                }
              }
            })
          } else {
            if (item.name == lab) {
              this.showLook = item.isExist
              this.subForm = {
                name: item.title,
                content: item.content
              }
            }
          }
        })
        // this.showParticle = rou == '/dashboard'
        let isPush = true
        for (let i = 0; i < this.rdata.tags.length; i++) {
          if (this.rdata.tags[i].i == ind) {
            isPush = false
          }
        }
        this.rdata.i = ind
        if (isPush) {
          let listArr = {
            name: lab,
            i: ind,
            rot: rou,
          }
          this.rdata.tags.push(listArr)

        }

      },
      openNewPage(path) {
        window.open(`${window.location.origin}/#${path}`, "_blank");
      },
      setimg() {
        // 旋转加动画效果
        this.isCollapse = !this.isCollapse
        event.srcElement.style.transition = '0.5s'
        if (this.isCollapse) {
          event.srcElement.style.transform = 'rotate(180deg)'
        } else {
          event.srcElement.style.transform = 'rotate(0deg)'
        }
      },
      full() {
        screenfull.toggle()
        if (screenfull.toggle()) {
          this.isfull = !this.isfull
        } else {
          this.$message({
            message: '浏览器不能全屏',
            type: 'warning',
          })
        }
      },

      quit() {
        this.$confirm('是否退出?', '提示', {
            confirmButtonText: '退出',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            this.loginOut()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消退出',

            })
          })
      },

      loginOut() {
        this.axios.post('/api/system/web/user/loginOut').then((res) => {
          if (res.data.success) {
            clearInterval(sosTime)
            // this.socketApi.onlineDevice = []
            this.socketApi.stopHeartBeat()
            this.socketCapsApi.stopCapsHeartBeat()
            if (this.socketCarportApi) {
              this.socketCarportApi.stopCarportHeartBeat()
            }
            sessionStorage.clear()
            this.$message({
              type: 'success',
              message: '退出成功!',
            })
            // document.cookie = "AdminToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            this.$router.push('/login')
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg,
            })
          }
        })
      },
      getType(type) {
        let obj = '未知报警'
        this.tpList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },
      getBoxType(type) {
        let obj = '未知报警'
        this.boxTpList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },
      getCarType(type) {
        let obj = '未知报警'
        this.tpCarList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },
      getCapsType(type) {
        let obj = '未知报警'
        this.tpCapsList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
            return obj
          }
        })
        return obj
      },

      changePercentage(data) {
        this.percentage = data
      },
      getStreamStatus(data) {
        this.device_id = data.device_id
        this.$http
          .get(`api/ele/web/getStreamStatus?id=${this.device_id}`)
          .then((res) => {
            if (res.data.success) {
              this.percentage = res.data.data ? 75 : 50
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      // 接听
      answerSOS(data) {
        console.log('首页')
        this.visible = false
        let webrtcList = JSON.parse(sessionStorage.getItem('webrtcCall')) ? JSON.parse(sessionStorage.getItem(
          'webrtcCall')) : []
        if (webrtcList.find(item => item == data.code)) {
          this.$bus.$emit("answerWebrtc", data.code)
          this.visible = false
          setTimeout(() => {
            this.bopen(data)
          }, 500)
          // const agentData = {
          //   act: 'ma_stop_webrtc_call',
          //   sos_room_id: data.code,
          // }
          // this.socketApi.sendSock(agentData, (e) => {
          //   webrtcList = webrtcList.filter(item => item == data.code)
          //   sessionStorage.setItem('webrtcCall', JSON.stringify(webrtcList))
          //   setTimeout(() => {
          //     this.bopen(data)
          //   }, 500)
          // })
        } else {
          this.bopen(data)
        }

      },
      bopen(data) {
        this.dioDetailsItem = data
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )

        document.getElementById('alarmA').pause()
        this.sosId = data.sosId
        this.videoStatusId = data.videoStatusId
        this.alarmTime = data.alarmTime
        setTimeout(() => {
          this.showClose = true
        }, 2000)
        // 2000
        const agentData = {
          act: 'ca_start_rtsp',
          device_id: data.code,
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.status) {
            const datas = {
              current: 1,
              size: 10,
              name: data.name
            }
            this.$http.post('/api/ele/web/elevatorInfo/runningList', datas).then((res) => {
              if (res.data.code == 200) {
                this.ids = res.data.data.records ? res.data.data.records[0].id : ''
                this.visible = true
                this.newCode = data.code
              }
            })
            if (e.hb) {
              this.percentage = 25
            }
            if (e.cmd === 'ca_start_rtsp') {
              if (JSON.parse(e.data).isStream == 0) {
                this.isHaveVideo = false
                this.isOldJk = true
              }
              if (JSON.parse(e.data).isStream == 1) {
                this.isHaveVideo = true
              }
              if (data.appVer.indexOf('C2') > -1 || data.appVer.indexOf('C3') > -1) {
                this.percentage = 50
                this.getStreamStatus(JSON.parse(e.data))
                this.isOldJk = true
              } else {
                this.isOldJk = false
              }
              const data1 = JSON.parse(e.data)
              data1.sip_id = data.sipId
              this.jkData = data1
            }
          } else {
            this.$message.error(e.msg)
            let agentData = {
              act: 'ma_stop_webrtc_call',
              sos_room_id: data.code,
            }
            this.socketApi.sendSock(agentData, (e) => {})
            sessionStorage.setItem('webrtcCall', false)
            this.visible = false
          }
        })
      },

      bclose() {
        // sessionStorage.setItem('webrtcCall', false)
        let newWebrtcList = JSON.parse(sessionStorage.getItem('webrtcCall'))
        newWebrtcList = newWebrtcList.length > 0 ? newWebrtcList.filter(item => item != this.newCode) : []
        sessionStorage.setItem('webrtcCall', JSON.stringify(newWebrtcList))
        this.visible = false
      },
      //车棚接听
      answerCarSOS(data) {
        this.carVisible = true

        this.dioDetailsItem = data
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        document.getElementById('alarmA').pause()
        this.sosId = data.sosId
        this.videoStatusId = data.videoStatusId
        this.alarmTime = data.alarmTime
        setTimeout(() => {
          this.showClose = true
        }, 2000)
        // const agentData = {
        //   act: 'ca_start_rtsp',
        //   device_id: data.code,
        // }
        // this.socketCarportApi.sendCarportSock(agentData, (e) => {
        //   if (e.status) {
        //     this.carVisible = true
        //     if (e.cmd === 'ca_start_rtsp') {
        //         this.jkData = data1
        //     }
        //   } else {
        //     this.$message.error(e.msg)
        //     let agentData = {
        //       act: 'ma_stop_webrtc_call',
        //       sos_room_id: data.code,
        //     }
        //     this.socketCarportApi.sendCarportSock(agentData, (e) => {})
        //     this.carVisible = false
        //   }
        // })
      },

      bCarClose() {
        this.carVisible = false
      },


      // 安全帽接听
      answerSOSCaps(data) {
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        document.getElementById('alarmA').pause()
        this.sosId = data.sosId
        this.alarmTime = data.time
        this.visibleCaps = true
        setTimeout(() => {
          this.showClose = true
        }, 2000)
        const agentData = {
          act: 'ma_start_rtsp',
          code: data.code
        }
        this.socketCapsApi.sendCapsSock(agentData, (e) => {
          if (e.status) {
            if (e.cmd == 'ma_start_rtsp' && e.data) {
              let data1 = JSON.parse(e.data)
              this.capsData = data1
            }
          } else {
            this.$message.error(e.msg)
          }
        })
      },
      bcloseCaps() {
        this.visibleCaps = false
      },
      // 拒接
      closeSOS(data, type) {
        // if (type == 'sos' ) {
        this.sosTypeList = this.sosTypeList.filter(
          (item) => item.sosId !== data.sosId
        )
        // } else {
        //   this.sosTypeList = this.sosTypeList.filter(
        //     (item) => item.capId !== data.capId
        //   )
        // }
        if (this.sosTypeList.length === 0) {
          document.getElementById('alarmA').pause()
        }
      },
      getSosConfigObj(data) {
        const sosConfig = JSON.parse(sessionStorage.getItem("sosConfig"))
        let arr = sosConfig.filter(item => Number(item.sosType) == Number(data.type))
        return arr[0]
      },
      // 车棚
      getCarSosConfigObj(data) {
        // if (sessionStorage.getItem("bikeSosConfig") && sessionStorage.getItem("bikeSosConfig") != 'undefined') {
        const bikeSosConfig = JSON.parse(sessionStorage.getItem("bikeSosConfig"))
        let arr = bikeSosConfig.filter(item => Number(item.sosType) == Number(data.type))
        return arr[0]
        // } else {
        //   this.$message.error('请到电动车棚管理 -> 车棚管理设置, 设置报警信息')
        //   return
        // }
      },

      // 首页公告关闭
      closeDialog() {
        this.noticeVisiblie = false
      },
      getTree(istrue) { //获取菜单列表(一级菜单)
        const Arr = []
        let data = {
          disabled: istrue,
        }
        this.$http
          .get('/api/system/web/resource/tree', data)
          .then((res) => {
            res.data.data.children.forEach((item) => {
              Arr.push(item)
            })

          })
      },
      changePage() {
        const router = JSON.parse(sessionStorage.getItem('adminInfo')).resource
        const lab = this.$route.name

        // setTimeout(()=>{
        //   location.reload()
        // },1000)
        const routerUrl = router[0].children ? router[0].children[0].path : router[0].path
        this.routeName = {
          name: router[0].children ? router[0].children[0].name : router[0].name,
          i: router[0].children ? router[0].children[0].id : router[0].id,
          rot: routerUrl
        }

        router.map(item => {
          if (item.children) {
            item.children.map(iitem => {
              if (iitem.name == lab) {
                this.showLook = iitem.isExist
                this.subForm = {
                  name: iitem.title,
                  content: iitem.content
                }
              }
            })
          } else {
            if (item.name == lab) {
              this.showLook = item.isExist
              this.subForm = {
                name: item.title,
                content: item.content
              }
            }
          }
        })
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.changePage()
        // openLz()
        let ht = this.$refs.topbox.offsetHeight
        if (ht > 1000) {
          this.$store.state.tabHeight = this.$refs.topbox.offsetHeight * 0.7
        } else if (ht > 900) {
          this.$store.state.tabHeight = this.$refs.topbox.offsetHeight * 0.65
        } else if (ht > 700) {
          this.$store.state.tabHeight = this.$refs.topbox.offsetHeight * 0.45
        } else {
          this.$store.state.tabHeight = 300
        }

        if (!this.$store.state.isZx) {
          document.title = window.location.hostname != 'zxtlw.zyzntech.com' ? this.platformName : (this
            .$store.state.isZx ? '中信数字化电梯物联网监管系统' : '数字化电梯物联网安全监管系统')
          var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
          link.type = "image/x-icon";
          link.rel = "shortcut icon";
          link.href = this.logoUrl
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        if (!JSON.parse(sessionStorage.getItem('adminInfo')).weakPassword) {
          this.tipPwdShow = true
          this.updPwd()
        }
      })
    },
    created() {
      this.$store.state.isWBJB = JSON.parse(sessionStorage.getItem("isWBJB"))
      this.getTree()
      // this.seleTheme = localStorage.getItem('seleTheme')

      const screenData = JSON.parse(sessionStorage.getItem('adminInfo'))
      this.bigScreenType = screenData.bigScreenType

      this.$http
        .post('/api/system/web/dict/dictionary?codes=BUTTON_TYPE')
        .then((res) => {
          if (res.data.success) {
            sessionStorage.setItem(
              'BUTTON_TYPE',
              JSON.stringify(res.data.data.BUTTON_TYPE)
            )
          }
        })

      this.$http
        .post(
          "/api/system/web/dict/dictionary?codes=SOS_TYPE,CAP_SOS_TYPE,CARPORT_SOS_TYPE,HIGH_SOS_PARAM,LOW_SOS_PARAM")
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item) => {
              if (screenData.userName != 'admin') {
                return item.value != '21' && item.value != '35' && item.value != '22'
              } else {
                return item
              }
            })
            this.tpList = Arr
            this.boxTpList = [...res.data.data.HIGH_SOS_PARAM, ...res.data.data.LOW_SOS_PARAM]
            this.tpCapsList = res.data.data.CAP_SOS_TYPE
            this.tpCarList = res.data.data.CARPORT_SOS_TYPE
            sosTime = setInterval(() => {
              // this.sosTypeList
              this.sosTypeList.forEach((qs, ind) => {
                qs.closetime -= 1
                if (qs.closetime <= 0) {
                  this.sosTypeList.splice(ind, 1)
                }
              })

              this.socketApi.sosPrompt.forEach((qs) => {
                if (this.sosTypeList.length > 9) {
                  this.sosTypeList.splice(0, 1)
                }
                qs.closetime = 30
                qs.time = formatDate(qs.time, "yyyy-MM-dd HH:mm:ss")
                qs.sosTime = formatDate(qs.sosTime, "yyyy-MM-dd HH:mm:ss")
                qs.alarmTime = qs.sosTime
                qs.typeShow = true
                if (qs.type === -1) {
                  qs.sosName = `${qs.terminalSosName}报警`
                } else {
                  if (qs.sosDeviceSign && qs.sosDeviceSign == 1) {
                    qs.sosName = this.getBoxType(qs.type)
                  } else {
                    qs.sosName = this.getType(qs.type)
                  }
                }
                let sosConfigType = []
                sosConfigType = JSON.parse(sessionStorage.getItem('sosConfig'))
                sosConfigType.map(item => {
                  if (item.sosType == qs.type) {
                    qs.showBoxType = item.sosLevel
                  }
                })
                let ispush = this.getSosConfigObj(qs)
                if (ispush.push == 1) {
                  this.sosTypeList.push(qs)
                }
              })
              this.socketCapsApi.sosCapsPrompt.forEach((qs) => {
                if (this.sosTypeList.length > 9) {
                  this.sosTypeList.splice(0, 1)
                }
                qs.closetime = 30
                qs.typeShow = true
                qs.sosName = this.getCapsType(qs.sosType)
                qs.showBoxType = 3

                this.sosTypeList.push(qs)
              })

              // 车棚
              this.socketCarportApi.sosCarportPrompt.forEach((qs) => {
                if (this.sosTypeList.length > 9) {
                  this.sosTypeList.splice(0, 1)
                }
                qs.closetime = 30
                qs.time = formatDate(qs.time, "yyyy-MM-dd HH:mm:ss")
                qs.typeShow = true
                qs.sosName = this.getCarType(qs.type)
                this.sosTypeList.push(qs)
              })





              this.sosTypeList = this.sosTypeList.filter(item => {
                if (item.cmd.indexOf('cap') > -1) {
                  return item
                } else if (item.cmd == 'carport_push_sos') {
                  let data = this.getCarSosConfigObj(item)
                  return data && data.push == 1
                } else {
                  let data = this.getSosConfigObj(item)
                  return data && data.push == 1
                }
              })
              let voiceS = this.sosTypeList.filter(item => {
                let data = this.getSosConfigObj(item)
                let data1 = ''
                if (item.cmd == 'carport_push_sos') {
                  data1 = this.getCarSosConfigObj(item)
                  return (data1 && data1.voice == 1)
                } else {
                  return (data && data.voice == 1)
                }
              })
              if (
                this.sosTypeList.length > 0 &&
                !this.visible &&
                !this.$global.openJK &&
                voiceS.length > 0
              ) {
                setTimeout(() => {
                  document.getElementById('alarmA').loop = true
                  document.getElementById('alarmA').play()
                }, 100)
              } else {
                document.getElementById('alarmA').pause()
              }

              this.socketApi.clearSos()
              this.socketCarportApi.clearCarportSos()
              this.socketCapsApi.clearCapsSos()
            }, 1000)
          }
        })

      this.adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
      this.elList = this.adminInfo.resource.filter(item => item.name != '数据监控' && item.name != '首页')
      let obj = {
        name: this.$route.matched[1].name,
        i: this.$route.matched[1].meta.lab,
        rot: this.$route.matched[1].path,
      }
      this.rdata.tags.push(obj)
      this.rdata.i = obj.i


      this.$http.get('/api/system/web/announcement/getPopub?type=LOGIN_NOTICE')
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length > 0) {
              const noticeInfo = JSON.parse(localStorage.getItem('isShowNotice')) ?? []
              if (noticeInfo.length > 0 && noticeInfo.find(item => item.userName == this.adminInfo.userName)) {
                let userShowNotice = noticeInfo.find(item => item.userName == this.adminInfo.userName)
                if (userShowNotice.checked && new Date().getTime() < userShowNotice.time) {
                  this.noticeVisiblie = false
                } else {
                  this.noticeVisiblie = true
                }
              } else {
                this.noticeVisiblie = true
              }
              this.noticeInfo = res.data.data[0]
            }
            this.Pwdtit = JSON.parse(sessionStorage.getItem('adminInfo')).isChangePassword ? '密码长时间未修改' : '修改密码'
            const TimeTrue = JSON.parse(localStorage.getItem('Pwdchecked'))
            if (this.Pwdtit == '密码长时间未修改') {
              setTimeout(()=>{
                this.isPwdDio = TimeTrue.length > 0 ? !TimeTrue[TimeTrue.length - 1].Pwdchecked : true
              },10)
            } else {
              this.isPwdDio = false
            }

          }
        })
    },
    destroyed() {
      clearInterval(sosTime)
    },
  }
</script>

<style lang="scss" scoped>
  .tipPwd {
    margin: 0 auto 30px;
    text-align: center;
    color: #F00;
    font-size: 16px;
  }

  .particles {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .sosCallBox {
    width: 365px;
    min-height: 315px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 99999;

    .newsosBox,
    .newsosCapsBox {
      .colorBox {
        position: absolute;
        top: 10px;
        z-index: 999;
        right: 10px;
        color: #fff;
      }

      .sosMsgHeader {
        display: flex;
        // position: relative;
        z-index: 999;
        justify-content: space-between;
        padding: 10px;
        color: rgb(255, 255, 255);
        position: absolute;
        top: 10px;
        right: 25px;

        .sosTitle {
          width: 215px;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .sosMsgContent {
        .sosMsg {
          margin: 10px 0;
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }
        }
      }

      .sosMsgBottom {
        padding: 0 20px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .offdialog {
        width: 100px;
      }

      .offdialogs {
        width: 100px;
        // margin-left: 70px
      }
    }

    .newsosCapsBox {
      .sosMsgContent {
        .sosMsg {
          margin: 5px 0
        }
      }

      .sosMsgBottom {
        margin-top: 15px;
      }

      // .sosMsgBottom{
      // 	margin: 10px 0px 15px;
      // 	display: flex;
      // 	flex-direction: column;
      // 	justify-content: space-between;
      // 	min-height: 200px;
      // 	.sosMsg {
      // 		font-size: 15px;
      // 		margin-top: 10px;
      // 		display: flex;
      // 		width: 100%;
      // 		overflow: hidden;
      // 		text-overflow: ellipsis;
      // 		display: -webkit-box;
      // 		-webkit-line-clamp: 4;
      // 		-webkit-box-orient: vertical;
      // 		word-wrap: break-word;
      // 	}
      // }
    }
  }

  .sosCallBox span {
    font-size: 18px;
    font-weight: bold;
  }

  .sosBox {
    width: 100%;

    .sosMsgBottom {
      margin: 10px 0px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 200px;

      .sosMsg {
        font-size: 16px;
        margin-top: 15px;
        display: flex;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .sosCapsBox {
    .sosMsgBottom {
      margin: 10px 0px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 200px;

      .sosMsg {
        font-size: 15px;
        margin-top: 10px;
        display: flex;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .sosCall {
    position: absolute;
    top: 0;
    left: 0;
    // z-index: 99999;
    /* width: 100%; */
    /* overflow: auto; */
    display: flex;
    flex-wrap: wrap;
  }

  .overName {
    max-height: 61px;
    // line-height: 60px;
    overflow: hidden; // 溢出的内容隐藏
    text-overflow: ellipsis; // 溢出的部分用省略号...显示
    -webkit-line-clamp: 3; // 行数
    display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
    -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
  }



  .rtgg2 {
    height: calc(100vh - 100px);
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    /* overflow: hidden; */
  }

  .el-main {
    padding: 20px 20px 15px;
  }

  /* 全屏样式 */
  a {
    text-decoration: none;
  }

  /* 容器 */
  .hTop01 {
    width: 50%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    z-index: 100;
  }

  .hTop02 {
    width: 50%;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    z-index: 100;
    position: relative;
  }

  /* 颜色选择器样式 */
  .el-color-dropdown__link-btn {
    display: none !important;
    color: red !important;
  }
</style>

<style scoped>
  ::-webkit-scrollbar {
    width: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  .item>>>.el-input__inner {
    width: 160px !important;
    height: 28px !important;
    float: left;
    border: 1px solid black !important;
  }
</style>
