<template>
  <div>
    <div class="dtBox1" v-if="deviceCode">
      <div class="supervisoryBox">
        <template v-if="dioData.DeviceData && !dioData.DeviceData.isOnline">
          <template v-if="new Date().getTime() - dioData.lastLogoutTime < 24 * 3600 * 1000">
            <!-- 24小时在线 -->
            <div class="instabilityStatus">
              <img :src="require('@/assets/eleStatus.png')" style="width: 200px;z-index: 99; object-fit: contain;" />
              <span style="font-size: 18px; font-weight: bolder; margin-top: 20px;">当前设备信号不稳定，暂时无法查看实时监控</span>
            </div>
          </template>
          <template v-else>
            <div
              style="width: 100%;height:100%;margin:auto;background:#000;display:flex;align-items: center;justify-content: center;">
              <el-image fit="contain" :src="require('@/assets/notOnline.png')" style="width: 25%;" />
              <div style="color:#fff;width: 55%;margin-left: 5%;font-size:16px">
                <h4>离线时间：{{dioData.lastLogoutTime ? new Date(parseInt(dioData.lastLogoutTime)).toLocaleString() : ''}}
                </h4>
                <span>1、 登录App或后台网页，查看球机离线前的最后状态：</br>
                  A、若显示低电量，建议安排现场检查电源情况；</br>
                  B、若信号很弱或重启后又离线的，建议现场实测信号情况以及球机天线两头有没有拧紧，并进行重启观察；</br>
                  2、登录网页后台“电梯运行监测”列表，检查球机是否有具体“激活时间”，若未曾激活上线过，建议现场重启后观察；</br>
                  3、登录app或后台网页，查看电梯资料AI摄像头ID和设备ID号码是否一致；</br>
                  4、如以上均无法解决，可在App内发起"帮助与反馈"，或者寻求管理员帮助。</span>
              </div>
            </div>
          </template>
        </template>
        <acContainer v-else :eleInfo="eleInfo" :dioData="dioData" :isHaveVideo="isHaveVideo" :isLadder="isLadder"
          :url='url' @update="update" />

      </div>
      <div v-if="isSimple" :class="!lowIsDatail ? 'wid5' : 'wid15'">
        <div v-if="!lowIsDatail" @click="showDetail">
          <h4 style=" margin: 0;">顶层：{{lowTop}}</h4>
          <div style="height: 405px;padding:10px 0">
            <template v-if="lowInfo && lowInfo.length > 0">
              <div v-for="(item, index) in lowInfo" class="lowInfo" :style="{height: uploadStyle}" :key="index">
                <div class="lowBg" :class="lowNow == item.floor ? 'newLowBg' : ''">
                </div>
                <div style="width:40px;" v-if="lowNow == item.floor">
                  {{item.name ? item.name : `${item.floor}`}}
                </div>
                <div style="width:40px;" v-else></div>
              </div>
            </template>
            <div v-else class="lowInfo" style="height: 100%; justify-content: flex-start;">
              <div class="lowBg">
              </div>
            </div>
          </div>
          <h4 style="margin: 0;">底层：{{lowBottom}}</h4>
        </div>
        <div v-else class="lowInfoBox">
          <div class="lowClose">
            <el-button type="text" icon="el-icon-close" @click="showDetail" />
          </div>
          <div class="scrollElement" style="height: 420px;overflow-y: scroll;padding:10px 5px">
            <template v-if="lowInfo && lowInfo.length > 0">
              <div style="height: 30px;display: flex;">
                <div style="width:60px;">层高(m)</div>
                <div style="width:80px;margin: 0 5px;">楼层(别名)</div>
                <div style="width:60px;">海拔(m)</div>
              </div>
              <div v-for="(item, index) in lowInfo" class="lowInfo" style="height: 30px;align-items: flex-start;"
                :key="index">
                <div style="width:60px;margin-top: -10px;text-align: center;">
                  {{ index != 0 ? item.heightMap : ''}}
                </div>
                <div class="lowBg infoBg" :class="lowNow == item.floor ? 'newLowBg' : ''">
                  {{`${item.floor}`}}{{item.name ? `(${item.name})` : ''}}
                </div>
                <div style="width:60px;margin-top: 10px;text-align: center;">{{item.height}}</div>
              </div>
            </template>
            <template v-else>
              <div style="height: 30px;display: flex;">
                <div style="width:60px;">层高(m)</div>
                <div style="width:80px;margin: 0 5px;">楼层(别名)</div>
                <div style="width:60px;">海拔m</div>
              </div>
              <div class="lowInfo" style="height: 90%; justify-content: space-around;">
                <div class="lowBg">
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- {{floorVisible}}--{{floorchangeVisible}} -->
      <div v-if="isSimple" class="lift-sequence" :class="!lowIsDatail ? 'wid40' : 'wid30'">
        <!-- {{floorId}} -->
        <FloorPage v-if="floorVisible" @close='closeInfo' :elevatorId="elevatorId" :deviceCode="eleInfo.deviceCode"
          :floorId="floorId" />
        <div v-if="!floorVisible&&!floorchangeVisible">
          <div class="floor-check" v-if="adminInfo.userName == 'admin' && this.dioData.checkFloor == true">
            <span>有新的楼层学习记录，点击（查看）进行校验</span>
            <el-button type="text" style="margin-top: -9px;" @click="checkInfo">查看</el-button>
          </div>
          <div class="lift-sequenceTop">
            <h3 style="margin: 0; width: 75%">电梯名称: {{dioData.name}} </h3>
            <div>
              <el-image v-if="dioData.DeviceData && dioData.DeviceData.isOnline !== ''" :fit="'cover'"
                :title="dioData.DeviceData && dioData.DeviceData.isOnline ? '摄像头在线' : '摄像头离线'"
                :src="dioData.DeviceData && dioData.DeviceData.isOnline ? require('@/assets/monitor/qiuji1.png') : require('@/assets/monitor/qiuji0.png')"
                style="width:25px;height:25px" />
              <el-image v-if="dioData.terminalData && dioData.terminalData.isOnline !== ''" :fit="'cover'"
                :title="dioData.terminalData && dioData.terminalData.isOnline ? '数字终端在线' : '数字终端离线'"
                :src="dioData.terminalData && dioData.terminalData.isOnline ?require('@/assets/monitor/hezi1.png') : require('@/assets/monitor/hezi0.png')"
                style="width:25px;height:25px" />
            </div>
          </div>
          <div>
            <el-row style="margin-top:15px">
              <el-col :span="8" class="parameter">
                <div style="position: relative;">
                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <h4 style="position: absolute;top: 36px;font-size: 18px;width: 100%;margin: 0;cursor: pointer;"
                    @click="changeFloor(dioData)">
                    {{ dioData.DeviceData ? (
                      dioData.DeviceData.textFloorAlias ? (
                      JSON.parse(dioData.DeviceData.textFloorAlias).data.length > 0 ? (
                        JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor) ?
                        (JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor).name !== '' ?
                          JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor).name : dioData.DeviceData.floor
                        ) : '无')
                      : '无' ) : (
                      dioData.DeviceData.floor ? dioData.DeviceData.floor : '-')
                    ): '' }}
                  </h4>
                </div>
                <h4 class="sequenceTitle">当前楼层</h4>
                <!-- <h4>供电模式</h4> -->
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <h4 style="position: absolute;top: 22px;font-size: 18px;width: 100%;margin: 0;">
                    {{dioData.DeviceData | showTitle(dioData.DeviceData.liftSpeed, 'speed') | showType('m/s')}}<br />m/s
                  </h4>
                </div>
                <h4 class="sequenceTitle">运行速度</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <div style="position: absolute;top: 24px;width: 100%;">
                    <el-image v-if="dioData.DeviceData && dioData.DeviceData.liftSpeed == 0" fit="contain"
                      :src="require(`@/assets/monitor/static.png`)" style="width:45px;height:45px;" />
                    <el-image v-if="dioData.DeviceData && dioData.DeviceData.liftSpeed > 0" fit="contain"
                      :src="require(`@/assets/monitor/up.png`)" style="width:45px;height:45px;" />
                    <el-image v-if="dioData.DeviceData && dioData.DeviceData.liftSpeed < 0" fit="contain"
                      :src="require(`@/assets/monitor/down.png`)" style="width:45px;height:45px;" />
                  </div>
                </div>
                <h4 class="sequenceTitle">运行方向</h4>
              </el-col>
            </el-row>
            <el-row>
              <!-- 供电模式,运行次数,运行里程 -->
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <h4 style="position: absolute;top: 22px;font-size: 18px;width: 100%;margin: 0;">
                    {{dioData.DeviceData | showTitle(dioData.DeviceData.runningNum) | showType('number')}}<br />次
                  </h4>
                </div>
                <h4 class="sequenceTitle">
                  运行次数
                  <el-tooltip class="item" effect="dark"
                    :content="`统计起始时间：${ getSysremTime(dioData.DeviceData.statisticalStartTime)}`" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">

                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <h4 style="position: absolute;top: 22px;font-size: 18px;width: 100%;margin: 0;">
                    {{dioData.DeviceData.liftDistance || dioData.DeviceData.liftDistance === 0 ?
                      (dioData.DeviceData.liftDistance > 1000 ?
                        `${(dioData.DeviceData.liftDistance / 1000).toFixed(2)}` :
                      `${dioData.DeviceData.liftDistance}`) : '-'
                    }}<br />
                    {{dioData.DeviceData.liftDistance || dioData.DeviceData.liftDistance === 0 ?
                      (dioData.DeviceData.liftDistance > 1000 ? 'km' : 'm') : ''
                    }}
                  </h4>
                </div>
                <h4 class="sequenceTitle">
                  运行里程
                  <el-tooltip class="item" effect="dark"
                    :content="`统计起始时间：${getSysremTime(dioData.DeviceData.statisticalStartTime)}`" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">

                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <div style="position: absolute;top: 22px;width: 100%;">
                    <el-image fit="contain"
                      :src="dioData.DeviceData && dioData.DeviceData.heads != 0 ? require(`@/assets/monitor/people.png`) : require(`@/assets/monitor/nopeople.png`)"
                      style="width:45px;height:45px;" />
                  </div>
                </div>
                <h4 class="sequenceTitle">是否有人</h4>
              </el-col>
            </el-row>
            <el-row>
              <!-- 门状态,折弯次数,震动频率 -->
              <el-col :span="8" class="parameter">
                <div style="position: relative">

                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <h4 style="position: absolute;top: 30px;font-size: 18px;width: 100%;margin: 0;">
                    <span v-if="dioData.DeviceData.bendNum">{{dioData.DeviceData.bendNum}}<br />次</span>
                    <span v-else>-</span>
                  </h4>
                </div>
                <h4 class="sequenceTitle">
                  折弯次数
                  <el-tooltip class="item" effect="dark"
                    :content="`统计起始时间：${getSysremTime(dioData.DeviceData.statisticalStartTime)}`" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />
                  <h4 style="position: absolute;top: 22px;font-size: 18px;width: 100%;margin: 0;">
                    {{shake}}<br />hz
                  </h4>
                  <!-- </span> -->
                </div>
                <h4 class="sequenceTitle">震动频率</h4>
              </el-col>
              <el-col :span="8" class="parameter">
                <div style="position: relative">
                  <el-image :src="require('@/assets/round.png')" fit="fill"
                    :style="{'width': lowIsDatail ? '120px' : '140px' }" style="height:90px" />

                  <div style="position: absolute;top: 22px;width: 100%;">
                    <el-image fit="contain"
                      :src="dioData.DeviceData && dioData.DeviceData.doorOpenStatus === 1 ? require(`@/assets/monitor/open.png`) : require(`@/assets/monitor/close.png`)"
                      style="width:45px;height:45px;" />
                  </div>
                </div>
                <h4 class="sequenceTitle">门状态</h4>
              </el-col>

            </el-row>
          </div>
        </div>
        <div v-if="floorchangeVisible" style="width:480px;background: rgb(61, 119, 150);padding: 0px 5px;">
          <div style="display:flex;justify-content: space-between;align-items: center;">
            <span style="color:#fff">修改当前楼层</span>
            <el-button type="text" @click="back" style="font-size: 16px">关闭</el-button>
          </div>
          <div style="height: 45vh;color: white; ">
            <div style="display: flex;align-items: center;">
              <i class="el-icon-warning-outline" style="font-size: 30px;color: coral;" />
              <span style="margin-left: 5px;">将电梯当前所在楼层修改为您设置的楼层，并更新楼层记录的数据。尽量在电梯停稳时操作。</span>
            </div>
            <div style="height: 40px;line-height: 40px;">
              当前楼层：<span style="font-size: 18px;">{{dioData.DeviceData.floor}}</span>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              修改后楼层：
              <el-select style="width: 60%;margin-right: 5px;" v-model="floorselect">
                <el-option v-for="(item,index) in floorList" :key="index" :value='item.floorName'
                  :label="item.floorName"></el-option>
              </el-select>
              <el-button type="primary" @click="submit()">提交</el-button>
            </div>
            <div>
              楼层记录：（修改成功后,自动更新）
              <div class="dataList">
                <el-table :data="floorList" ref="floorList" border style="margin-top: 10px;color: white;"
                  max-height="255px">
                  <el-table-column prop="floorName" align="center" label="楼层(别名)">
                    <template slot-scope="scope">
                      <span>{{ scope.row.floorName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="heightAltitude" label="海拔高度" min-width="150px" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.heightAltitude}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="height" label="楼层高度" min-width="150px" align="center">
                    <template slot-scope="scope">
                      <span>{{ scope.row.height }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <!-- <el-progress type="circle" stroke-linecap="butt" :percentage="100" stroke-width="8"></el-progress>
        <el-progress type="circle" :percentage="50" stroke-width="8" status="exception" define-back-color="#20a0ff"></el-progress> -->

      </div>
      <div v-if="!isSimple && !floorchangeVisible" class="lift-sequence" :class="!lowIsDatail ? 'wid40' : 'wid30'">
        <div class="lift-sequenceTop">
          <h3 style="margin: 0; width: 80%">电梯名称: {{dioData.name}}</h3>
          <div>
            <el-image v-if="dioData.DeviceData && dioData.DeviceData.isOnline !== ''" :fit="'cover'"
              :title="dioData.DeviceData && dioData.DeviceData.isOnline ? '摄像头在线' : '摄像头离线'"
              :src="dioData.DeviceData && dioData.DeviceData.isOnline ? require('@/assets/monitor/qiuji1.png') : require('@/assets/monitor/qiuji0.png')"
              style="width:25px;height:25px;" />
            <el-image v-if="dioData.terminalData && dioData.terminalData.isOnline !== ''" :fit="'cover'"
              :title="dioData.terminalData && dioData.terminalData.isOnline ? '数字终端在线' : '数字终端离线'"
              :src="dioData.terminalData && dioData.terminalData.isOnline ?require('@/assets/monitor/hezi1.png') : require('@/assets/monitor/hezi0.png')"
              style="width:25px;height:25px" />
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div class="lift-sequence" style="width: 100%;">
        <div class="lift-sequenceTop" style="width: 100%;justify-content: flex-start;">
          <h3 style="margin: 0;">电梯名称: {{dioData.name}}</h3>
          <div>
            <el-image v-if="dioData.terminalData && dioData.terminalData.isOnline !== ''" :fit="'cover'"
              :title="dioData.terminalData && dioData.terminalData.isOnline ? '数字终端在线' : '数字终端离线'"
              :src="dioData.terminalData && dioData.terminalData.isOnline ?require('@/assets/monitor/hezi1.png') : require('@/assets/monitor/hezi0.png')"
              style="width:25px;height:25px" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="dtBox5" v-if="countLow.length > 0"> -->
    <div class="dtBox5" v-if="adminInfo.userName == 'admin' && isSimple">
      <div class="hourPerson">
        <div class="titleHeader">
          <h3 class="changepointer">{{checkDayStr}}乘梯人数（{{eleInfo.deviceCode}}）</h3>
          <div>
            <template v-if="!isShowDay">
              <el-button type="text" :class="[daycolor ? 'daysColor' : '' , 'btnColor']"
                @click="changeData('day', 'person')">今日</el-button>
              <el-button type="text" :class="[!daycolor ? 'daysColor' : '' , 'btnColor']"
                @click="changeData('month', 'person')">近30天</el-button>
            </template>
            <el-button v-else type="text" @click="checkDay('', false)">返回</el-button>
          </div>
        </div>
        <div style=" display: flex; justify-content: flex-start;" :style="{'flex-wrap' : daycolor ? 'nowrap' : 'wrap'}">
          <div v-for="(item, index) in hourArr" :key="item" style="text-align: center;"
            :class="daycolor ? 'dayBox' : 'monthBox'" @click="checkDay(item, true)">
            <div class="countBox" :class="countBoxBg(personArr[index])">
              {{personArr && personArr.length > 0 ? (personArr[index] ? personArr[index] : 0) : 0}}
            </div>
            <div>{{daycolor ? item : item.split('年')[1]}}</div>
          </div>
        </div>
      </div>
      <div class="hourPerson" v-if="countLowOld && countLowOld.length > 0">
        <div class="titleHeader">
          <h3 class="changepointer">{{checkMonthStr}}启停楼层统计</h3>
          <div>
            <el-button type="text" :class="[monthcolor ? 'daysColor' : '' , 'btnColor']"
              @click="changeData('day', 'count')">今日</el-button>
            <el-button type="text" :class="[!monthcolor ? 'daysColor' : '' , 'btnColor']"
              @click="changeData('month', 'count')">近30天</el-button>
          </div>
        </div>
        <div style="display: flex; justify-content: start; align-items: center;">
          <div v-for="(item, index) in countLow" :key="index" :style="{width: countStyle}">
            <div class="countBox columnFlex" :class="countBoxBg(countArr[index])">
              <div style="margin-bottom:5px">
                {{item.name ? item.name : ( countLowOld.length > 0 ? countLowOld[index].floor : '')}}楼
              </div>
              <div>{{countArr[index]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dtBox2" v-if="eleInfo.deviceCode" style="margin-top: 20px;">
      <div class="titleHeader">
        <h3 class="changepointer">AI智能终端（{{eleInfo.deviceCode}}）</h3>
        <el-button type="text" @click="query">查询流量</el-button>
      </div>
      <el-descriptions :column="4" border class="descriptionsType">
        <el-descriptions-item label="供电模式">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.charging) | showType('charging')}}
        </el-descriptions-item>
        <el-descriptions-item label="信号强度">
          <el-image
            v-if="dioData.DeviceData && (new Date().getTime() - dioData.lastLogoutTime < 24 * 3600 * 1000) && !dioData.DeviceData.isOnline"
            fit="contain" :src="require(`@/assets/monitor/L0.png`)" style="width:25px;height:25px" />
          <el-image
            v-else-if="dioData.DeviceData && dioData.DeviceData.netStrenth && dioData.DeviceData.netStrenth.indexOf('L') > -1"
            fit="contain" :src="require(`@/assets/monitor/${dioData.DeviceData.netStrenth}.png`)"
            style="width:25px;height:25px" />
          <span v-else>-</span>
        </el-descriptions-item>

        <el-descriptions-item label="IP地址">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.ethIp)}}
        </el-descriptions-item>
        <el-descriptions-item label="SIM卡号">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.SIM)}}
        </el-descriptions-item>
        <el-descriptions-item label="系统时间" v-if="adminInfo.userName == 'admin'">
          {{ dioData.DeviceData ? (dioData.systemReportTime ? getSysremTime(dioData.systemReportTime) : '-') : '' }}

        </el-descriptions-item>
        <el-descriptions-item label="心跳时间" v-if="adminInfo.userName == 'admin'">
          {{ dioData.DeviceData ?  ((new Date().getTime() - dioData.lastLogoutTime < 24 * 3600 * 1000) && !dioData.DeviceData.isOnline ?
					nowTime : (dioData.DeviceData.reportTime ? dioData.DeviceData.reportTime : '-')
				) : ''}}
        </el-descriptions-item>
        <el-descriptions-item label="4G模块ID">
          {{dioData.DeviceData.imei4g}}
        </el-descriptions-item>
        <el-descriptions-item label="SD卡剩余容量">
          {{ dioData.DeviceData && dioData.activeTime ? (dioData.DeviceData.isSdMounted == 1 ? `${dioData.DeviceData.sdAvailableSize}M` : ( dioData.DeviceData.isSdMounted == '' ? '-': 'SD卡已损坏')) : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="本月流量">
          {{ dioData.DeviceData.monthSimNum || Number(dioData.DeviceData.monthSimNum) == 0 ? (Number(dioData.DeviceData.monthSimNum) >= 0 ? `${dioData.DeviceData.monthSimNum} M` : '获取数据失败') : '-'}}
        </el-descriptions-item>
        <el-descriptions-item v-if="isSimple" label="服务屏类型">
          {{dioData.DeviceData.hdmiPlugStatus ? (dioData.DeviceData.hdmiPType == 1 ? '双屏' : (dioData.DeviceData.hdmiPType == 0 ? '单屏' : '无' )) : '未接入'}}
        </el-descriptions-item>
        <el-descriptions-item v-if="adminInfo.userName == 'admin'" label="识别状态">
          <el-select v-model="eleInfo.recognitionState" class="eleDetailSelect" @change="changeState" clearable>
            <el-option :key="1" :value="0" label="无"></el-option>
            <el-option :key="2" :value="1" label="人体识别不准"></el-option>
            <el-option :key="3" :value="2" label="开门识别不准"></el-option>
            <el-option :key="4" :value="3" label="电动车识别不准"></el-option>
          </el-select>
          <!-- {{ eleInfo.recognitionState == 1 ? '人体识别不准' : (eleInfo.recognitionState == 2 ? '开门识别不准' : '电动车识别不准' )}} -->
        </el-descriptions-item>
        <el-descriptions-item v-if="isSimple" label="服务屏视角">
          <el-select v-model="eleInfo.screenAngle" class="eleDetailSelect" @change="changeScreen" clearable>
            <el-option :key="1" :value="0" label="未知"></el-option>
            <el-option :key="2" :value="1" label="清晰"></el-option>
            <el-option :key="3" :value="2" label="模糊"></el-option>
            <el-option :key="4" :value="3" label="视角外"></el-option>
            <el-option :key="5" :value="4" label="黑屏"></el-option>
            <el-option :key="6" :value="5" label="花屏"></el-option>
            <el-option :key="7" :value="6" label="其它"></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="电池健康" v-if="adminInfo.userName == 'admin' || adminInfo.userName == 'wxl'">
          {{ dioData.DeviceData && dioData.DeviceData.batteryHealth ? `${(dioData.DeviceData.batteryHealth * 100).toFixed(0) / 100}` : '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="最近视频时间" v-if="adminInfo.userName == 'admin'">
          {{ eleInfo.videoTime ? eleInfo.videoTime : '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="最近视频状态" v-if="adminInfo.userName == 'admin'">
          {{ eleInfo.videoStatus ? (eleInfo.videoStatus == 0? '空' :(eleInfo.videoStatus == 1? '成功' : '失败'))  : '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="球机温度" v-if="adminInfo.userName == 'admin'">
          {{ dioData.DeviceData&&dioData.DeviceData.tempData2? dioData.DeviceData.tempData2+'°C' : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="Hdmi状态" v-if="adminInfo.userName == 'admin'">
          {{ dioData.DeviceData? (dioData.DeviceData.hdmiPlugStatus ? '正常' : '不正常') : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="视频心跳时间" v-if="adminInfo.userName == 'admin'">
          {{ dioData.DeviceData? $formatDate(dioData.DeviceData.hfTime*1000,'yyyy-MM-dd HH:mm:ss')  : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="设备系统版本">
          <el-tooltip v-if="dioData.caVer" effect="dark"
            :content="`${dioData.caVer ? dioData.caVer :'-'} / ${dioData.appVer ? dioData.appVer :'-'}` "
            placement="top">
            <div class="titlTips"
              :style="dioData.caVer.includes('R3D')&&adminInfo.userName == 'admin' ? 'cursor: pointer;' : ''"
              @click="clearTcard(dioData.caVer)">{{dioData | showTitle(dioData.caVer)}} /
              {{dioData | showTitle(dioData.appVer)}}
            </div>
          </el-tooltip>
          <div v-else>{{dioData | showTitle(dioData.caVer)}} / {{dioData | showTitle(dioData.appVer)}}</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="dtBox2" v-if="eleInfo.signalCode" style="margin-top: 20px;">
      <div class="titleHeader">
        <h3 class="changepointer">信号检测终端（{{eleInfo.signalCode}}）</h3>
      </div>
      <el-descriptions :column="4" border class="descriptionsType">
        <el-descriptions-item label="数据更新时间">
          {{dioData.signalData ? $formatDate(dioData.signalData.createTime,'yyyy-MM-dd HH:mm:ss') : '-'}}
        </el-descriptions-item>
        <el-descriptions-item label="移动信号强度">
          <div style="height:35px;line-height: 35px;"
            v-if="dioData.signalcontent && dioData.signalcontent.strength4G && dioData.signalcontent.strength4G[0] != -999">
            <el-image :src="require(`@/assets/signalicon/sin${protImg(dioData.signalcontent.strength4G[0])}.png`)"
              style="width:30px;height:30px;margin-right:20px" />
          </div>
          <span
            v-if="dioData.signalcontent&&dioData.signalcontent.strength4G&&dioData.signalcontent.strength4G[0] == -999"
            style="display:block;height:35px;line-height: 35px;">无信号</span>
          <span v-if="!dioData || dioData.signalcontent&&dioData.signalcontent.length < 1"
            style="display:block;height:35px;line-height: 35px;">暂无数据</span>
        </el-descriptions-item>
        <el-descriptions-item label="电信型号强度">
          <div style="height:35px;line-height: 35px;"
            v-if="dioData.signalcontent && dioData.signalcontent.strength4G && dioData.signalcontent.strength4G[1] != -999">
            <el-image :src="require(`@/assets/signalicon/sin${protImg(dioData.signalcontent.strength4G[1])}.png`)"
              style="width:30px;height:30px;margin-right:20px" />
          </div>
          <span
            v-if="dioData&&dioData.signalcontent&&dioData.signalcontent.strength4G&&dioData.signalcontent.strength4G[1] == -999"
            style="display:block;height:35px;line-height: 35px;">无信号</span>
          <span v-if="!dioData || dioData.signalcontent&&dioData.signalcontent.length < 1"
            style="display:block;height:35px;line-height: 35px;">暂无数据</span>
        </el-descriptions-item>
        <el-descriptions-item label="联通信号强度">
          <div style="height:35px;line-height: 35px;"
            v-if="dioData.signalcontent && dioData.signalcontent.strength4G && dioData.signalcontent.strength4G[2] != -999">
            <el-image :src="require(`@/assets/signalicon/sin${protImg(dioData.signalcontent.strength4G[2])}.png`)"
              style="width:30px;height:30px;margin-right:20px" />
          </div>
          <span
            v-if="dioData.signalcontent&&dioData.signalcontent.strength4G&&dioData.signalcontent.strength4G[2] == -999"
            style="display:block;height:35px;line-height: 35px;">无信号</span>
          <span v-if="!dioData || dioData.signalcontent&&dioData.signalcontent.length < 1"
            style="display:block;height:35px;line-height: 35px;">暂无数据</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="dtBox3" v-if="eleInfo.terminalId && isSimple">
      <h3 class="changepointer">电梯数字终端（{{eleInfo.terminalId}}）</h3>
      <el-descriptions class="descriptionsType" :column="5" border>
        <el-descriptions-item label="电梯状态" v-if="adminInfo.userName == 'admin' || adminInfo.userName == 'CQJC1'">
          {{ dioData.terminalData?.sosStatus == 0 ? '正常' : (dioData.terminalData?.sosStatus == 1 ? '故障' : '-') }}
        </el-descriptions-item>
        <el-descriptions-item label="抱闸线圈" v-if="sosSwitch.brakeCoilSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_103) }}
        </el-descriptions-item>
        <el-descriptions-item label="门锁回路" v-if="sosSwitch.doorLockSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_102) }}
        </el-descriptions-item>
        <el-descriptions-item label="安全回路" v-if="sosSwitch.safetyCircuitSosSwitch">
          <template v-if="dioData.terminalData.sos_104">
            {{ getStatus(dioData.terminalData , dioData.terminalData.sos_104) }}
          </template>
          <template v-else>
            {{ getStatus(dioData.terminalData , dioData.terminalData.sos_110) }}
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="厅门回路" v-if="sosSwitch.hallDoorCircuitSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_101) }}
        </el-descriptions-item>
        <el-descriptions-item label="关门极限" v-if="sosSwitch.closeTheDoorLimitSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_108) }}
        </el-descriptions-item>
        <el-descriptions-item label="开门极限" v-if="sosSwitch.openTheDoorLimitSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_107) }}
        </el-descriptions-item>
        <el-descriptions-item label="意外移动" v-if="sosSwitch.surpriseMoveSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_109) }}
        </el-descriptions-item>
        <el-descriptions-item label="蹲底" v-if="sosSwitch.crouchBottomSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_106) }}
        </el-descriptions-item>
        <el-descriptions-item label="冲顶" v-if="sosSwitch.diveHeaderSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_105) }}
        </el-descriptions-item>
        <el-descriptions-item label="上行减速" v-if="sosSwitch.upsideToSlowDownSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_113) }}
        </el-descriptions-item>
        <el-descriptions-item label="下行减速" v-if="sosSwitch.downwardDecelerationSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_114) }}
        </el-descriptions-item>
        <el-descriptions-item label="锁梯" v-if="sosSwitch.lockTheLadderSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_112) }}
        </el-descriptions-item>
        <el-descriptions-item label="检修" v-if="sosSwitch.maintenanceSosSwitch">
          {{ getStatus(dioData.terminalData , dioData.terminalData.sos_111) }}
        </el-descriptions-item>
        <el-descriptions-item label="心跳时间">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminal_time : dioData.terminalTime }}
        </el-descriptions-item>
        <el-descriptions-item label="版本">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminalVersion : dioData.terminalVersion }}
        </el-descriptions-item>
        <el-descriptions-item label="ICCID">
          {{ dioData.terminalData && dioData.terminalData.isOnline  ? dioData.terminalData.terminalIcd : dioData.terminalIcd }}
        </el-descriptions-item>
        <el-descriptions-item label="机房温度">
          {{ dioData.terminalData&&dioData.terminalData.temp && dioData.terminalData.isOnline  ? `${dioData.terminalData.temp}℃` : '-' }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="dtBox4" style="margin-bottom:15px">
      <h3 class="changepointer">电梯数据</h3>
      <el-descriptions class="descriptionsType" :column="3" border>
        <el-descriptions-item v-if="isSimple" label="维保单位"> {{dioData | showTitle(dioData.maintainUnitName)}}
        </el-descriptions-item>
        <el-descriptions-item v-if="isSimple" label="使用单位">{{dioData | showTitle(dioData.propertyUnitName)}}
        </el-descriptions-item>
        <el-descriptions-item v-if="isSimple" label="制造单位">{{dioData | showTitle(dioData.manufacturingUnitName)}}
        </el-descriptions-item>
        <el-descriptions-item label="电梯注册代码">{{dioData | showTitle(dioData.code)}}</el-descriptions-item>
        <el-descriptions-item v-if="isSimple"
          label="电梯品牌">{{dioData | showTitle(dioData.brandName)}}</el-descriptions-item>
        <!-- <el-descriptions-item label="物联网设备系统版本">
          <el-tooltip v-if="dioData.caVer" effect="dark"
            :content="`${dioData.caVer ? dioData.caVer :'-'} / ${dioData.appVer ? dioData.appVer :'-'}` "
            placement="top">
            <div class="titlTips">{{dioData | showTitle(dioData.caVer)}} /
              {{dioData | showTitle(dioData.appVer)}}
            </div>
          </el-tooltip>
          <div v-else>{{dioData | showTitle(dioData.caVer)}} / {{dioData | showTitle(dioData.appVer)}}</div>
        </el-descriptions-item> -->
        <el-descriptions-item label="安装位置">
          <el-tooltip effect="dark" :content="dioData.address ? dioData.address :'-'" placement="top">
            <div class="titlTips">
              {{dioData | showTitle(dioData.address)}}
            </div>
          </el-tooltip>
        </el-descriptions-item>
      </el-descriptions>
      </el-descriptions>
    </div>

    <!-- 图表 -->
    <el-dialog title="流量统计" v-if="visible" :visible.sync="visible" append-to-body :close-on-click-modal="false"
      width="960px" top="8vh" @close="dialogClose">
      <div style="height: 60vh;">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline videoSearch">
            <el-form-item>
              <span slot="label">
                <span class="lab-span">统计日期</span>
              </span>
              <el-date-picker v-model="searchForm.startTime" type="month" style="width:400px;margin-right:15px" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="searchBtn" @click="searchList">查看</el-button>
            </el-form-item>
          </el-form>
          <h3 style="margin:0 0 22px">{{year}}年{{month}}月累计流量：{{simNum}}MB</h3>
        </div>
        <el-table ref="dataList" v-loading="tableLoading" :data="dataList" height="50vh" border stripe fit>
          <el-table-column label="序号" type="index" width="40px" align="center" />
          <el-table-column prop="deviceCode" label="设备号" align="center" />
          <el-table-column prop="sim" label="SIM卡号" align="center" />
          <el-table-column prop="simDataNum" label="当日使用流量(M)" align="center" />
          <el-table-column label="统计时间" align="center">
            <template slot-scope="scope">
              {{ getTime(scope.row.startTime) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <Pagination v-if="total > 0" :total="total" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="turnPage" />
      </div>
    </el-dialog>
    <el-dialog title="清空T卡" v-if="TcardShow" :visible.sync="TcardShow" append-to-body :close-on-click-modal="false"
      width="650px" top="20vh" @close="TcardClose">
      <div style="height: 28vh;display: flex;justify-content: center;align-items: center;">
        是否清空该设备的T卡数据？
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="TcardSure">确定</el-button>
        <el-button @click="TcardClose">取消</el-button>
      </div>
    </el-dialog>
    <BatteryDialog v-if="isShowBattery && (adminInfo.userName == 'admin' || adminInfo.userName == 'wxl')"
      :code="eleInfo.deviceCode" />
  </div>
</template>

<script>
  // require('../../../../../public/static/gauge.min.js')
  import acContainer from './acContainer/index.vue' // 电梯监控 eleMonitoring
  import Pagination from '@/components/Pagination'
  import FloorPage from './floorPage.vue' //学习楼层数据
  import BatteryDialog from './batteryDialog.vue';
  import {
    formatDate
  } from '@/util'


  // let getDeTime
  export default {
    props: ['deviceCode', 'elevatorId', 'eleInfo', 'DetailsInfo'],
    components: {
      acContainer,
      Pagination,
      FloorPage,
      BatteryDialog,
    },
    computed: {
      uploadStyle() {
        return this.lowInfo && this.lowInfo.length > 0 ? (405 / this.lowInfo.length) + 'px' : '0px';
      },
      countStyle() {
        return this.lowInfo && this.lowInfo.length > 0 ? ((1300 / this.lowInfo.length) + 'px') : '100%';
      },
      urlStyle() {
        return this.imageBg;
      },
    },
    data() {
      return {
        //拖拽
        dragging: false,
        offset: {
          x: 0,
          y: 0
        },
        style: {
          position: 'absolute',
          top: '0',
          left: '0'
        },

        floorchangeVisible: false,
        floorList: [],
        floorselect: '',
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isSimple: false,
        dioData: {
          DeviceData: {
            isOnline: true,
          },
          terminalData: {
            isOnline: true,
          },
        },
        sosSwitch: {
          safetyCircuitSosSwitch: true, // 安全回路
          maintenanceSosSwitch: true, // 检修
          lockTheLadderSosSwitch: true, // 锁梯
          doorLookLoopSosSwitch: true, // 轿门回路
          hallDoorCircuitSosSwitch: true, // 厅门回路
          brakeCoilSosSwitch: true, // 抱闸线圈
          surpriseMoveSosSwitch: true, // 意外移动
          limitASosSwitch: true, // 上限位
          lowerLimitPositionSosSwitch: true, // 下限位
        },
        visible: false,
        // 表格参数
        searchForm: {
          startTime: '',
        },
        dataList: [],
        formInline: {
          current: 1,
          size: 10,
        },
        initTime: [],
        total: 0,

        dMileage: null, // 运行里程
        dFlow: null, // 本月流量
        dDoor: null, // 开关门
        dMode: null, // 模式
        dMove: null, // 移动
        dPeople: null, // 有无人
        dSpeed: null, // 运行速度
        dioDatadMileage: null, // 初始运行里程
        dioDatadFlow: null, // 初始流量
        nowlow: null, // 初始当前楼层
        endlow: 0, // 初始当前楼层
        initLow: 0,
        url: null, //图片
        lowBottom: null, //初始底层
        lowTop: null, // 初始顶层
        lowInfo: null, // 楼层信息
        lowNow: null, // 当前楼层名
        lowIsDatail: false, // 初始总楼层
        imageBg: require('@/assets/round.png'),
        signal: 0, // 盒子信号

        month: '',
        year: '',
        simNum: '',

        shake: 0, // 震动频率

        getDeTime: null,
        timeOutType: null,
        statusTitle: [], // 盒子报警状态
        floorVisible: false,
        showDio: true,
        nowTime: '',

        //时间数组
        hourArr: [],
        personArr: [],
        countArr: [],
        countLow: null,
        countLowOld: [],
        daycolor: true,
        monthcolor: true,
        isShowDay: false,
        checkDayStr: '近30天',
        checkMonthStr: '今日',

        isShowBattery: false,
        isLadder: null,
        TcardShow: false,
        count: 0,
        tableLoading: true,

        isHaveVideo: true,

        initEleInfo: {}
      }
    },
    filters: {
      showTitle(data, target, type) {
        let url = ''
        if (data) {
          url = '-'
          if (target || target == 0) {
            if (type == 'speed') {
              url = Number(target).toFixed(2)
            } else {
              url = target
            }
          }
        }
        return url
      },

      showType(data, target) {
        let url = '-'
        if (data !== '-') {
          switch (target) {
            case 'SDMB':
              url = data ? `${data}(M)` : 'SD卡已损坏'
              break
            case 'MB':
              url = `${data}(M)`
              break
            case 'm/s':
              url = `${data}`
              break
            case 'm':
              url = `${data} m`
              break
            case 'number':
              url = `${data}`
              break
            case 'heads':
              url = data > 0 ? '有人' : '无人'
              break
            case 'liftSpeed':
              url =
                Number(data) > 0 ? '上行' : Number(data) === 0 ? '静止' : '下行'
              break
            case 'charging':
              url = data > 0 ? '电池供电' : data == 0 ? 'DC供电' : '-'
              break
            default:
              url = '无'
              break
          }
        }
        return url
      },
    },
    created() {
      this.getEleInfo(this.elevatorId)
      this.timeOutType = setTimeout(() => {
        if (this.showDio) {
          this.getFacility()
          this.getDeTime = setInterval(() => {
            this.getFacility()
          }, 2000)
        }
      }, 1000)
    },
    mounted() {
      this.searchForm.startTime = new Date(
        new Date(new Date().toLocaleDateString()).getTime()
      )
      this.isSimple = !(this.adminInfo.roleId == 99)
      this.changeData('day', 'person')
      this.changeData('day', 'count')
      this.getNotice('公告类型', ['DEVICE_OPER_STATUS'])
    },
    beforeDestroy() {
      // getDeTime = null
      this.showDio = false
      clearTimeout(this.timeOutType)
      clearInterval(this.getDeTime)
    },
    destroyed() {
      console.log('电梯监控详情销毁')
      clearTimeout(this.timeOutType)
      clearInterval(this.getDeTime)
    },
    watch: {
      videoTime: {
        deep: true,
        handler(newRoom, oldRoom) {
          if (oldRoom.title && newRoom.title) {
            this.isChange = true
          }
        },
      },
    },
    methods: {
      changeFloor(data) {
        if (data.floorId) {
          this.floorchangeVisible = this.adminInfo.userName == 'admin' ? true : false
          const altitude = data ? JSON.parse(data.DeviceData.textFloorHeight).data : []
          const floor = data ? JSON.parse(data.DeviceData.textHeightMap).data : []
          // const floor = data ? JSON.parse(data.DeviceData.textHeightMap).data : JSON.parse(this.dioData.DeviceData
          //   .textHeightMap).data
          altitude.forEach((item) => {
            floor.forEach((ids) => {
              if (item.floor == ids.floor) {
                this.floorList.push({
                  floorName: item.floor,
                  heightAltitude: item.height,
                  height: ids.height,
                  altitudeType: false,
                  heightType: false,
                  nameType: false,
                })
              }
            })
          })
        } else {
          this.$message.warning('该电梯学习楼层未完成，请先学习楼层')
        }
      },
      back() {
        this.floorchangeVisible = false
      },
      submit() {
        if (this.floorselect) {
          if (this.socketApi.isheart) {
            let agentData = {
              act: 'set_floor',
              type: 'server_push_set_floor',
              device_id: this.dioData.deviceCode,
              floor: this.floorselect,
            }
            this.socketApi.sendSock(agentData, (e) => {
              if (e.cmd === 'floor' && e.status) {
                this.floorselect = ''
                this.floorList = []
                const altitude = this.DetailsInfo ? JSON.parse(this.DetailsInfo.DeviceData.textFloorHeight).data :
                  JSON.parse(this.dioData
                    .DeviceData
                    .textHeightMap).data
                const floor = this.DetailsInfo ? JSON.parse(this.DetailsInfo.DeviceData.textHeightMap).data : JSON
                  .parse(this.dioData
                    .DeviceData
                    .textHeightMap).data
                altitude.forEach((item) => {
                  floor.forEach((ids) => {
                    if (item.floor == ids.floor) {
                      this.floorList.push({
                        floorName: item.floor,
                        heightAltitude: item.height,
                        height: ids.height,
                        altitudeType: false,
                        heightType: false,
                        nameType: false,
                      })
                    }
                  })
                })
                this.$message.success(e.msg)
                // this.changeFloor()
              } else {
                this.$message.error(e.msg)
              }
            })
          }
        } else {
          this.$message.warning('请选择楼层')
        }
      },
      getEleInfo(id){
        this.$http.get('/api/ele/web/elevatorInfo/getMaEleDetail', {params: { id }})
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                }
              } = res
              this.initEleInfo = data
              this.updateMonitor(this.initEleInfo)
            }
        })
      },
      protImg(src) {
        let imgindex = 1
        if (src >= -85) {
          imgindex = 1
        } else if (src >= -95 && src < -85) {
          imgindex = 2
        } else if (src >= -105 && src < -95) {
          imgindex = 3
        } else if (src >= -115 && src < -105) {
          imgindex = 4
        } else if (src <= -115) {
          imgindex = 5
        }
        return imgindex
      },
      clearTcard(data) { //清空T卡
        console.log(data.includes('R3D'), '清空Tcard')
        if (data.includes('R3D') && this.adminInfo.userName == "admin") {
          this.TcardShow = true
        }
      },
      TcardClose() { //取消清空T卡
        this.TcardShow = false
      },
      TcardSure() { //确认清空T卡
        this.$http.post(`/api/ele/web/elevatorInfo/resetSystem?device_id=${this.eleInfo.deviceCode}`).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.TcardShow = false
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      update(time, type) {
        this.eleInfo.videoTime = this.getSysremTime(time)
        this.eleInfo.videoStatus = type
        this.$forceUpdate()
      },
      checkInfo() {
        this.floorVisible = true
      },
      closeInfo() {
        this.floorVisible = false
      },
      getStatus(data, target) {
        let str = '-'
        if (data.isOnline) {
          if (this.statusTitle.find(item => item.value == target)) {
            str = this.statusTitle.find(item => item.value == target).label
          }
        }
        return str
      },
      getSysremTime(time) {
        return formatDate(new Date(time), 'yyyy-MM-dd HH:mm:ss')
      },
      // 服务模式
      changeType(val) {
        let name = '无'
        if (val && val == 0) {
          name = '单屏'
        } else if (val && val == 1) {
          name = '双屏'
        }
        return name
      },
      getmodel(data) {
        let str = '-'
        if (data.st && data.jx) {
          if (
            this.sos_switch_status.maintenance_sos_switch &&
            !this.sos_switch_status.lock_the_ladder_sos_switch
          ) {
            if (data.jx == 1) {
              str = '检修'
            } else {
              str = '运行中'
            }
          }
          if (
            !this.sos_switch_status.maintenance_sos_switch &&
            this.sos_switch_status.lock_the_ladder_sos_switch
          ) {
            if (data.st == 1) {
              str = '锁梯'
            } else {
              str = '运行中'
            }
          }
          if (
            this.sos_switch_status.maintenance_sos_switch &&
            this.sos_switch_status.lock_the_ladder_sos_switch
          ) {
            if (data.st == 1) {
              if (data.jx == 1) {
                str = '锁梯'
              } else {
                str = '锁梯'
              }
            } else {
              if (data.jx == 1) {
                str = '检修中'
              } else {
                str = '运行中'
              }
            }
          }
        }
        return str
      },

      // 获取数据
      getFacility() {
        this.count++
        this.nowTime = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
        if (this.socketApi.isheart) {
          let agentData = {
            act: 'ma_get_ele_detail',
            id: this.elevatorId,
          }
          this.socketApi.sendSock(agentData, (e) => {
            if (e.cmd === 'ma_get_ele_detail' && e.status) {
              if (e.data) {
                e.data = data.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2')
                this.updateMonitor(JSON.parse(e.data))
              } else {
                this.getEleInfo(this.elevatorId)
              }
              this.dioData.signalData = this.dioData.signalData ? JSON.parse(this.dioData.signalData) : ''
              this.dioData.signalcontent = this.dioData.signalData.content ? JSON.parse(this.dioData.signalData
                .content) : []
              // console.log(this.dioData, this.dioData.signalcontent, 577)
            } else {
              this.updateMonitor(this.initEleInfo)
            }
          })
        } else {
          this.updateMonitor(this.initEleInfo)
        }
      },
      updateMonitor(data) {
        this.dioData = data
        if (this.count >= 3) {
          this.isLadder = this.dioData.DeviceData && this.dioData.DeviceData.zuTiStatus ? this.dioData
            .DeviceData.zuTiStatus : -1
        }
        this.isHaveVideo = this.dioData.DeviceData && this.dioData.DeviceData.cameraOk ? this.dioData
          .DeviceData.cameraOk != 4 : true
        sessionStorage.setItem('isLadder', this.isLadder)
        this.floorId = this.dioData.floorId
        // this.$set(this, 'dioData', JSON.parse(e.data))
        this.shake = this.dioData.DeviceData && this.dioData.DeviceData.isOnline && this
          .dioData.DeviceData.liftSpeed && this.dioData.DeviceData
          .liftSpeed != 0 ? Math.random().toFixed(2) : 0
        this.sosSwitch = this.dioData.sosSwitchstatus && this.dioData.sosSwitchstatus !=
          '' ? JSON.parse(this.dioData.sosSwitchstatus) : {}
        // 楼层信息
        this.lowInfo = this.dioData.DeviceData && this.dioData.DeviceData
          .textFloorHeight && this.dioData.DeviceData.textFloorHeight !== '' ? JSON
          .parse(
            this.dioData.DeviceData.textFloorHeight).data.reverse() : []
        this.countLowOld = this.lowInfo.length > 0 ? JSON.parse(JSON.stringify(this.lowInfo))
          .reverse() : []
        // 楼层海拔
        let lowInfoHeightMap = this.dioData.DeviceData && this.dioData.DeviceData
          .textHeightMap && this.dioData.DeviceData.textHeightMap !== '' ? JSON.parse(
            this.dioData.DeviceData.textHeightMap).data.reverse() : []
        // 楼层别名
        let lowInfoFloorAlias = this.dioData.DeviceData && this.dioData.DeviceData
          .textFloorAlias && this.dioData.DeviceData.textFloorAlias !== '' ? JSON.parse(
            this.dioData.DeviceData.textFloorAlias).data.reverse() : []
        this.countLow = lowInfoFloorAlias.length > 0 ? JSON.parse(JSON.stringify(lowInfoFloorAlias))
          .reverse() : JSON.parse(JSON.stringify(this.lowInfo))
        // 楼层信
        if (this.lowInfo && this.lowInfo.length > 0) {
          this.lowInfo.map((item, index) => {
            item.heightMap = lowInfoHeightMap.length > 0 && lowInfoHeightMap[
              index] ? lowInfoHeightMap[index].height : ''
            item.name = lowInfoFloorAlias.length > 0 && lowInfoFloorAlias[
              index] ? lowInfoFloorAlias[index].name : ''
          })
        }
        // 当前楼层
        this.lowNow = this.dioData.DeviceData && this.dioData.DeviceData.floor ? this
          .dioData.DeviceData.floor : '-'
        if (this.dioData.DeviceData && this.dioData.DeviceData.isOnline) {
          let lowInfo = this.dioData.DeviceData && this.dioData.DeviceData
            .textFloorHeight !== '' ? JSON.parse(
              this.dioData.DeviceData.textFloorHeight).data : []
          this.lowBottom = lowInfo.length > 0 ? lowInfo[0].floor : 0
          this.lowTop = lowInfoFloorAlias.length > 0 ? (lowInfoFloorAlias[0].name != '' ?
            lowInfoFloorAlias[0].name : lowInfoFloorAlias[0].floor) : (lowInfo
            .length > 0 ? lowInfo[lowInfo.length - 1].floor : 0)
        }
        if (this.dioData.DeviceData) {
          if (
            this.dioDatadMileage !== this.dioData.DeviceData.liftDistance
          ) {
            this.dioDatadMileage = this.dioData.DeviceData.liftDistance
          }
        }
        if (this.dioData.terminalData && this.dioData.terminalData.signal) {
          const num = this.dioData.terminalData.signal.split('dbm')
          if (Math.abs(num[0]) < 88) {
            this.signal = 4
          } else if (Math.abs(num[0]) < 98) {
            this.signal = 3
          } else if (Math.abs(num[0]) < 108) {
            this.signal = 2
          } else if (Math.abs(num[0]) < 118) {
            this.signal = 1
          } else {
            this.signal = 0
          }
        }
        let isShowBatteryList = JSON.parse(sessionStorage.getItem('isShowBatteryList'))
        if (this.dioData.DeviceData && this.dioData.DeviceData.batteryHealth && this.dioData.DeviceData
          .batteryHealth <= 40) {
          const isShow = isShowBatteryList && isShowBatteryList.find(item => item.code == this.eleInfo
            .deviceCode) && isShowBatteryList.find(item => item.code == this.eleInfo.deviceCode).checked
          if (!isShow) {
            this.isShowBattery = true
          }
        }
      },
      // 切换楼层信息
      showDetail() {
        if (this.lowInfo && this.lowInfo.length > 0) {
          this.lowIsDatail = !this.lowIsDatail
        }
      },
      // 转换时间
      getTime(data) {
        if (data) {
          var date = new Date(data)
          var YY = date.getFullYear() + '-'
          var MM =
            (date.getMonth() + 1 < 10 ?
              '0' + (date.getMonth() + 1) :
              date.getMonth() + 1) + '-'
          var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
          var hh =
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
          var mm =
            (date.getMinutes() < 10 ?
              '0' + date.getMinutes() :
              date.getMinutes()) + ':'
          var ss =
            date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
          return YY + MM + DD + ' ' + hh + mm + ss
        }
        return '-'
      },
      // 服务模式
      getmodel(data) {
        let str = '-'
        if (data.st && data.jx) {
          if (data.st === 1) {
            str = '锁梯'
          } else {
            if (data.jx === 1) {
              str = '检修中'
            } else {
              str = '运行中'
            }
          }
        }
        return str
      },
      // 打开流量弹框
      query() {
        this.visible = true
        this.searchList()
      },
      // 翻页
      turnPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.searchList()
      },
      //修改识别
      changeState(status) {
        const data = {
          id: this.elevatorId,
          recognitionState: status
        }
        this.$http
          .post('/api/ele/web/elevatorInfo/updateRecognitionState', data)
          .then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg)
            }
          })
      },
      //修改服务屏视角
      changeScreen(status) {
        const data = {
          id: this.elevatorId,
          screenAngle: status
        }
        this.$http
          .post('/api/ele/web/elevatorInfo/updateScreenAngle', data)
          .then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg)
            }
          })
      },

      // 乘梯人数，统计
      changeData(type, category, date) {
        let newTime = date ? date.replace('年', '-').replace('月', '-').replace('日', '') : ''
        let newDate = newTime ? new Date(newTime) : new Date()
        let day = type == 'day' ? 0 : 29
        if (category == 'person') {
          this.checkDayStr = type == 'day' ? (date ? `${date.split('年')[1]}每小时` : '今日') : '近30天'
          this.hourArr = []
          if (type == 'day') {
            this.hourArr = ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10',
              '10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21',
              '21-22', '22-23', '23-00'
            ]
          } else {
            this.isShowDay = false
            let nowTime = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00").getTime()
            for (let index = day; index > -1; index--) {
              this.hourArr.push(formatDate(new Date(nowTime - index * 24 * 3600 * 1000), "yyyy年MM月dd日"))
            }
          }
        } else {
          this.checkMonthStr = type == 'day' ? '今日' : '近30天'
        }
        this.getTakeEle(day, category, newDate, date)
      },
      checkDay(day, type){
        if (this.isShowDay && day) return
        this.isShowDay = type
        this.checkDayStr = day ? day : '近30天'
        if (day) {
          this.changeData('day', 'person', day)
        } else {
          this.changeData('month', 'person')
        }
      },
      getTakeEle(day, type, date, time) {
        // 今天0点
        let start = new Date(formatDate(date, "yyyy-MM-dd") + " 00:00:00").getTime()
        let end = new Date(formatDate(date ? date : new Date(), "yyyy-MM-dd") + " 23:59:59").getTime()
        let startTime = ''
        let endTime = ''
        let endDay = type == 'count' ? 0 : (day == 29 ? 0 : day)

        startTime = formatDate(new Date(start - day * 24 * 3600 * 1000), "yyyy-MM-dd HH:mm:ss")
        endTime = formatDate(new Date(end - endDay * 24 * 3600 * 1000), "yyyy-MM-dd HH:mm:ss")
        if (type == 'person') {
          this.daycolor = this.checkDayStr != '近30天'
          this.personArr = []
        } else {
          this.monthcolor = day == 0
          this.countArr = []
        }
        if (day > 0 && type == 'person') {
          this.$http.get(
              `/api/ele/web/runningNum/getMonthData?eleId=${this.elevatorId}&startTime=${startTime}&endTime=${endTime}`)
            .then((res) => {
              if (res.data.success) {
                const {
                  data: {
                    data
                  }
                } = res
                if (type == 'person') {
                  Object.keys(data.dailyTotalHead).sort().map(item => {
                    this.personArr.push(data.dailyTotalHead[item]);
                  })
                  // } else {
                  //   Object.keys(data.dailyTotalFloor).sort((a, b) => a - b).map(item => {
                  //     this.countArr.push(data.dailyTotalFloor[item]);
                  //   })
                }
              }
            })
        } else {
          this.$http
            .get(
              `/api/ele/web/runningNum/getHourData?eleId=${this.elevatorId}&startTime=${startTime}&endTime=${endTime}&type=${day == 29 ? 30 : day}`
            )
            .then((res) => {
              if (res.data.success) {
                const {
                  data: {
                    data
                  }
                } = res
                if (type == 'person') {
                  Object.keys(data.hourHeadJSON).sort().map(item => {
                    this.personArr.push(data.hourHeadJSON[item]);
                  })
                } else {
                  Object.keys(data.floorJSON).sort((a, b) => a - b).map(item => {
                    this.countArr.push(data.floorJSON[item]);
                  })
                }
              }
            })
        }
      },
      countBoxBg(num) {
        let className = ''
        if (num < 50) {
          className = 'countBox1'
        } else if (num < 100) {
          className = 'countBox2'
        } else if (num < 200) {
          className = 'countBox3'
        } else if (num < 300) {
          className = 'countBox4'
        } else if (num > 300) {
          className = 'countBox5'
        } else {
          className = 'countBox1'
        }
        return className
      },


      // 搜索流量
      searchList() {
        if (!this.searchForm.startTime) {
          this.$message.error('请选择查询日期')
          return
        }
        this.year = new Date(this.searchForm.startTime).getFullYear()
        this.month = new Date(this.searchForm.startTime).getMonth() + 1
        const data = JSON.parse(JSON.stringify(this.formInline))
        data.eleId = this.elevatorId
        data.startTime = this.getTime(this.searchForm.startTime)
        this.tableLoading = true
        this.$http
          .post(
            `/api/ele/web/simData/getSimDataLogPage?current=${this.formInline.current}&size=${this.formInline.size}`,
            data
          )
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.simNum = data.numTotal
              this.dataList = data.record.records
              this.total = data.record.total
              this.url = data.record.firstFrameUrl
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      // 关闭流量弹框
      dialogClose() {
        this.searchForm.value = new Date(
          new Date(new Date().toLocaleDateString()).getTime() -
          7 * 24 * 3600 * 1000
        )
        this.visible = false
      },

      // 获取字典
      getNotice(type, code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                }
              } = res
              this.statusTitle = data.DEVICE_OPER_STATUS
            } else {
              this.$message.error(res.data.msg)
            }
          })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .supervisoryBox {
    width: 55%;
    height: 100%;
    margin-right: 1%;
    // background-color: #9acd32;
  }

  .lift-sequence {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .floor-check {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      background-color: brown;
      color: white;
      width: 86%;
      height: 20px;
      padding: 5px 20px;
      margin: 0px 0px 10px 14px;
    }

    .lift-sequenceTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 94%;
      margin: 0 auto;

      .el-image {
        margin-left: 15px;
      }
    }

    // .parameter {
    //   text-align: center;
    //   color: #fff;
    // }

    .sequenceTitle {
      margin: 9px 0;
    }

    // background-color: yellowgreen;
  }

  .dtBox1 {
    width: 100%;
    height: 465px;
    display: flex;

    .titlTips {
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .dtBox5 {
    .hourPerson {
      margin-top: 15px;


      .countBox {
        border: solid #149adf 1px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
      }


      .btnColor {
        color: #149adf !important;
        cursor: pointer;
      }

      .daysColor {
        color: #06eef7 !important;
        cursor: pointer;
      }

      .dayBox {
        width: 80px;
        margin-bottom: 0px;
      }

      .monthBox {
        width: 85px;
        margin-bottom: 10px;
      }

      .countBox1 {
        background: #fff;
        color: #000;
      }

      .countBox2 {
        background: rgb(19, 118, 211);
        color: #000;
      }

      .countBox3 {
        background: rgb(103, 132, 226);
        color: #000;
      }

      .countBox4 {
        background: rgb(68, 101, 189);
        color: #000;
      }

      .countBox5 {
        background: rgb(11, 51, 230);
        color: #000;
      }

      .columnFlex {
        flex-direction: column;
        height: 60px;
      }
    }
  }

  .titleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .dtBox2 {
  //   /* width: 100%;
  // 		height: 130px;
  // 		background-color: yellow; */
  // }

  // .dtBox3 {
  //   /* width: 100%;
  // 		height: 260px;
  // 		background-color: red; */
  // }

  // .dtBox4 {
  //   /* width: 100%;
  // 		height: 130px;
  // 		background-color: yellow; */
  // }
  .wid5 {
    width: 7%;
  }

  .wid15 {
    width: 15%;
  }

  .wid30 {
    width: 30%;
  }

  .wid40 {
    width: 40%;
  }

  .lowClose {
    padding: 5px;
    text-align: right;

    button {
      padding: 0;
    }
  }

  .lowInfo {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-left: 15px;
    width: 70px;

    .lowBg {
      width: 20px;
      height: 100%;
      border: 1px solid #fff;
      margin-right: 10px;
      background: #1c5b8f
    }

    .newLowBg {
      background: #e6a23c;
    }
  }



  .lowInfo {
    align-items: flex-start;
    margin-left: 0;
    width: 100%;
  }

  .infoBg {
    width: 80px !important;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .instabilityStatus {
    background: #000;
    position: relative;
    flex-direction: column;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /deep/.eleDetailSelect {
    margin: -12px -6px;

    input {
      border: none !important;
      background-color: #041b5700 !important;
    }
  }

  // /deep/.el-descriptions-item__label.is-bordered-label {
  //   background: #1282a5 !important;
  //   text-align: center;
  //   width: 120px;
  //   font-size: 14px !important;
  //   text-align: center !important;
  // }

  /deep/.el-descriptions-item__content {
    width: 210px;
  }

  /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 12px 6px;
  }
</style>
